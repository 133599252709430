import React from "react";

const getMenuState = () => {
  return localStorage.getItem("menu") === "true" ? true : false;
};

export default function useMenuInfo() {
  const [menuExpanded, setMenuExpanded] = React.useState<boolean>(getMenuState());

  const setMenuState = React.useCallback((isExpanded: boolean) => {
    localStorage.setItem("menu", isExpanded.toString());
    setMenuExpanded(getMenuState());
  }, []);

  React.useEffect(() => {
    setMenuState(menuExpanded);
  }, [setMenuState, menuExpanded]);

  return { menuExpanded, setMenuState };
}
