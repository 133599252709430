import { CallListStatus, CampaignStatus } from "../../enums/contactlist";
import { TtsField } from "../messages";

export interface CallListFile {
  callListId: number;
  campaignId: number;
  callListStatusName: string;
  originalFileName: string;
  campaignName: string;
  created: Date;
  phoneCount: number;
  accountCount: number;
  availableCount: number;
  calledCount: number;
  notCalledCount: number;
  dncCount: number;
  callListStatusId: CallListStatus;
  fieldName: string;
  fieldColumn?: number;
  originalCallListId: number;
  originalCallListName: string;
}

export interface Campaign {
  campaignId: number;
  name: string;
  clientId: number;
  campaignStatusId: CampaignStatus;
  campaignStatusName: string;
  campaignType: number;
  campaignTypeName: string;
  updated: string | undefined;
  startTime: Date;
  stopTime: Date;
  startAutomatically: boolean;
  stopAutomatically: boolean;
  callerIdNumber: string;
  callerIdName: string;
  campaignStarted: boolean | undefined;
  callerIdTypeId: number;
  startTimeSpan: string;
  stopTimeSpan: string;
  startWeekdays: string;
  stopWeekdays: string;
  rciBucketId: number;
}

export interface CampaignLinkback extends Campaign {
  callRatio: number;
  linkbackNumber: string;
  agentCount: number;
  customerMessageId: number;
  machineMessageId: number;
  agentMessageId: number;
}

export interface CampaignBroadcast extends Campaign {
  callsPerMinute: number;
  customerMessageId: number;
  machineMessageId: number;
}

export class CallListRenewRequest {
  newCalllistName: string;
  calllistId: number;
  calllistName: string;
  campaignId: number | undefined;
  filterOptionNotCalled: boolean;
  filterOptionCalledAnswered: boolean;
  filterOptionCalledNotAnswered: boolean;

  constructor() {
    this.newCalllistName = "";
    this.calllistId = 0;
    this.calllistName = "";
    this.campaignId = undefined;
    this.filterOptionNotCalled = false;
    this.filterOptionCalledAnswered = false;
    this.filterOptionCalledNotAnswered = false;
  }
}
export interface CallListMapItem {
  accountField: TtsField;
  selectedCallListField: Array<CallListFile>;
}
