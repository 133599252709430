type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface StyledButtonProps extends ButtonProps {
  additionalClassNames?: string;
}

function CancelButton(props: StyledButtonProps) {
  return (
    <button
      className={
        `py-1 w-full rounded-full bg-white
                 text-center font-sans text-primaryPurple
                 border border-transparentPurple 
                 cursor-pointer
                 transition-colors  
                 shadow-button ` + (props.additionalClassNames ? props.additionalClassNames : "")
      }
      {...props}
    >
      {props.children}
    </button>
  );
}

export default CancelButton;
