import * as React from "react";
import { campaignColor } from "./utils";

export type CampaignListItemProps = {
  campaignStatusName: string;
  campaignTypeName: string;
  campaignCompletionPercentage: number;
  name: string;
  selected: boolean;
  campaignId: number;
  onClick: (arg: number) => void;
};

export const CampaignListItem: React.FC<CampaignListItemProps> = (props) => {
  function handleClick() {
    props.onClick(props.campaignId);
  }

  const percentageColor = campaignColor(props.campaignStatusName);

  return (
    <li key={props.campaignId} className="relative select-none" onClick={handleClick}>
      {props.selected ? (
        <div
          className="absolute z-0 top-0 w-3 h-full "
          style={{
            backgroundColor: percentageColor,
          }}
        />
      ) : null}
      <div
        className="block h-[88px] cursor-pointer list-none p-3 ml-3 border-b border-spaceGray"
        style={{
          zIndex: -1,
        }}
      >
        <div className="block mb-2 h-8 font-bold text-primaryDarkGrey text-lg overflow-hidden whitespace-nowrap text-ellipsis w-[310px]">
          <span className={props.selected ? "p-3" : "p-0"}>{props.name}</span>
        </div>
        <div className={props.selected ? "justify-between px-3 flex h-10" : "justify-between px-0 flex h-10"}>
          <span>{props.campaignStatusName}</span>
          <span>{props.campaignTypeName}</span>
          <span className="font-bold" style={{ color: percentageColor }}>
            {props.campaignCompletionPercentage}%
          </span>
        </div>
      </div>

      {props.selected ? (
        <div
          className="absolute z-0 top-0 w-3 h-full"
          style={{
            left: "96.9%",
            backgroundColor: percentageColor,
          }}
        />
      ) : null}
    </li>
  );
};
