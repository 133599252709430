import loadingIcon from "../../images/loading-icon.svg";

export function LoaderSpinner(props: { tableLength: number | 1 }) {
  return (
    <tr>
      <td colSpan={props.tableLength}>
        <div className="m-auto fill-darkPurple relative top-1/2">
          <img alt="" className="animate-spin w-16 h-16 block mx-auto" src={loadingIcon} />
          <span className="text-primaryPurple text-xl text-center block">Loading ...</span>
        </div>
      </td>
    </tr>
  );
}
