function AnimatedButton(props: { text: String }) {
  return (
    <div
      style={{
        backgroundSize: "200%",
        backgroundImage: "linear-gradient(to right, transparent 50%, rgba(28, 190, 131, 1) 50%)",
      }}
      className="flex pl-5 bg-primaryPurple rounded-full shadow-animatedbt animate-button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="-4 -4 30 30">
        <title>check</title>
        <g id="eclipse">
          <polyline
            style={{ fill: "none" }}
            className="w-1/12 h-1/12 stroke-white animate-cls"
            points="5 12 8.5 15.5 16 8"
          />
          <circle
            style={{ fill: "none" }}
            className="w-1/12 h-1/12 stroke-white m-48 animate-check"
            cx="10.5"
            cy="10.5"
            r="9.5"
          />
        </g>
      </svg>
      <div className="text-white">{props.text}</div>
    </div>
  );
}

export default AnimatedButton;
