import * as React from "react";
import { CDRRow, fetchCDR, startExportJob } from "../../api/cdr";
import { ColumnFilter, ColumnID, DullData } from "../../interfaces/reports";
import { Filters } from "./filters";
import { Window } from "../../shared/window";
import * as ColumnData from "./column_data";
import { Cell, ColumnName, ColumnsRow, Row, Table, TitleRow } from "../../shared/table";
import { ReactComponent as SearchIcon } from "../../images/search_black_24dp.svg";
import { ReactComponent as SettingsIcon } from "../../images/settings_black_24dp.svg";
import { LoaderSpinner } from "../../shared/loaderspinner";
import { fetchCampaignsList } from "../../api/dialercampaign";

export const initialColumnVisibility = new Map<ColumnID, boolean>([
  ["answerTime", true],
  ["billedDuration", true],
  ["callTypeName", true],
  ["cost", true],
  ["duration", true],
  ["finishTime", true],
  ["firstName", true],
  ["lastName", true],
  ["minuteCost", true],
  ["phoneNumber", true],
  ["startTime", true],
  ["callerId", false],
  ["callerIdTypeName", false],
  ["campaignName", false],
  ["city", false],
  ["calllistName", false],
  ["dateOfBirth", false],
  ["externalId", false],
  ["middleName", false],
  ["ssn", false],
  ["state", false],
  ["zipCode", false],
]);

export const CDRPage = (props: { clientId: number }) => {
  const [rowData, setRowData] = React.useState<CDRRow[]>([]);
  const [activeFilters, setActiveFilters] = React.useState<ColumnFilter[]>([]);
  const [showFiltersEditor, setShowFiltersEditor] = React.useState<boolean>(false);
  const [showColumnsSelector, setShowColumnsSelector] = React.useState<boolean>(false);
  const [displayedColumns, setDisplayedColumns] = React.useState<Map<ColumnID, boolean>>(initialColumnVisibility);
  const [resultCount, setResultCount] = React.useState<number>(25);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(false);
  const [dullData, setDullData] = React.useState<DullData>(new DullData());



  async function loadDullData() {
    let dull : DullData = new DullData();
    const data = await fetchCampaignsList(props.clientId);
    data.map(c => 
      dull.campaigns.set(c.campaignId, c.name)
    );
    ColumnData.CallDispositionData.map(c => dull.callDisposition.set(c.id, c.name));
    ColumnData.TransferedData.map(c => dull.transferred.set(c.id, c.name));
    setDullData(dull);
  };

  React.useEffect(() => {
    setShowLoadingSpinner(true);
    retrieveCDR(activeFilters, resultCount, props.clientId);
    loadDullData();
  }, [activeFilters, resultCount, props.clientId]);

  async function retrieveCDR(filters: ColumnFilter[], count: number, clientId: number) {
    const report = await fetchCDR(filters, count, clientId);
    setTotalCount(report.total);
    setRowData(report.rows);
    setShowLoadingSpinner(false);
  }

  const maxCols = 22;
  // Translate rows in the backend CDR data to table rows
  let rows = rowData
    .map((rowData) => {
      let result = new Array<string | undefined>(maxCols).fill(undefined);
      for (const key in rowData) {
        const col: ColumnID = key as ColumnID;
        if (displayedColumns.get(col)) {
          const pos = ColumnData.internalColumnPositions.get(col);
          if (pos === undefined) {
            throw new Error("The position of a column was undefined!");
          }
          if (key !== undefined && key.includes("Time")) {
            const cellTxt = rowData[key as keyof CDRRow].toString();
            if (cellTxt.toLowerCase().includes("invalid date"))
              result[pos] = ""
            else
              result[pos] = rowData[key as keyof CDRRow].toString();
          }
          else
            result[pos] = rowData[key as keyof CDRRow].toString();
        }
      }
      return result
        .filter((item) => item !== undefined)
        .map((item) => {
          return <Cell>{item}</Cell>;
        });
    })
    .map((item) => <Row>{item}</Row>);

  // Order header elements
  let headerNames = Array<string | undefined>(maxCols).fill(undefined);
  Array.from(ColumnData.columns.values()).forEach((item) => {
    if (displayedColumns.get(item.id)) {
      const pos = ColumnData.internalColumnPositions.get(item.id);
      if (pos === undefined) {
        throw new Error("The position of a column was undefined!");
      }
      headerNames[pos] = item.displayName;
    }
  });

  const headerElements = headerNames
    .filter((elem) => elem !== undefined)
    .map((elem) => <ColumnName colSpan={1}>{elem}</ColumnName>);

  function changeColumnVisibility(columnId: ColumnID) {
    const isDisplayed = displayedColumns.get(columnId);
    setDisplayedColumns(new Map(displayedColumns.set(columnId, !isDisplayed)));
  }

  const possibleResultCounts = [10, 25, 50, 100, 250, 500];

  return (
    <div className="h-full flex flex-col p-10">
      <div className="flex flex-row gap-4 items-center mb-5 self-end">
        <div>
          <span className="p-3">How many results would you like to show</span>
          <span>
            <select value={resultCount} onChange={(e) => setResultCount(parseInt(e.target.value))}>
              {possibleResultCounts.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </span>
          <span className="p-3">
            {resultCount < totalCount ? resultCount : totalCount} of {totalCount} results
          </span>
        </div>
        <div className="flex flex-row gap-2">
          <div
            className="group select-none text-mediumGray2 bg-white px-2 py-1 rounded-full shadow-window hover:bg-primaryPurple hover:cursor-pointer transition-colors"
            onClick={() => startExportJob(activeFilters, resultCount, props.clientId)}
          >
            <p className="group-hover:text-white transition-colors">Export</p>
          </div>
          <div
            className="group relative bg-white p-1 rounded-full shadow-window hover:bg-primaryPurple hover:cursor-pointer transition-colors"
            onClick={() => setShowFiltersEditor(!showFiltersEditor)}
          >
            <SearchIcon className="fill-mediumGray2 group-hover:fill-white transition-colors" />
            {activeFilters.length !== 0 && (
              <div className="absolute top-[-8px] left-[20px] inline-flex rounded-full bg-primaryPurple w-[18px] h-[18px]">
                <label className="text-white text-xs m-auto select-none">{activeFilters.length}</label>
              </div>
            )}
          </div>
          <div
            className="group bg-white p-1 rounded-full shadow-window hover:bg-primaryPurple hover:cursor-pointer transition-colors"
            onClick={() => setShowColumnsSelector(!showColumnsSelector)}
          >
            <SettingsIcon className="fill-mediumGray2 group-hover:fill-white transition-colors" />
          </div>
        </div>
      </div>
      <div className="flex flex-row h-full pb-4 overflow-auto gap-3">
        <div className="flex-auto overflow-hidden">
          <Table>
            <TitleRow colSpan={headerElements.length}>CDR Preview</TitleRow>
            <ColumnsRow>{headerElements}</ColumnsRow>
            <tbody>{!showLoadingSpinner? rows : <LoaderSpinner tableLength={headerElements.length} />}</tbody>
          </Table>
        </div>
        <div className="h-full flex gap-3">
          {showFiltersEditor && <Filters activeFilters={activeFilters} onFiltersChange={setActiveFilters} dullData={dullData}/>}
          {showColumnsSelector && (
            <Window title="Columns">
              <ul style={{ listStyle: "none" }}>
                {Array.from(ColumnData.columns.values()).filter((item) => item.id !== "campaigns").map((item) => {
                  return (
                    <li onClick={() => changeColumnVisibility(item.id)}>
                      <div>
                        <input
                          type="checkbox"
                          className="mr-1"
                          checked={displayedColumns.get(item.id)}
                          name={item.displayName}
                          key={item.displayName}
                        />
                        {item.displayName}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Window>
          )}
        </div>
      </div>
    </div>
  );
};
