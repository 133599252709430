import * as React from "react";
import ConfirmButton from "../../shared/buttons/confirm";
import { UsageReportfilter } from "../../interfaces/reports";
import { fetchUsageReport, UsageReportData } from "../../api/usagereport";
import { Cell, ColumnName, ColumnsRow, Row, Table } from "../../shared/table";
import moment from "moment";
import { CheckboxInput } from "../../shared/input";
import { start } from "repl";

export const UsagePage = (props: { clientId: number }) => {

    const [filter, setFilter] = React.useState<UsageReportfilter>({
        startDay: new Date().toISOString().split("T")[0],
        endDay: new Date().toISOString().split("T")[0],
        callLists: [],
    });
    const [report, setReport] = React.useState<UsageReportData[] | undefined>(undefined);
    const [selectedAll, setSelectedAll] = React.useState<boolean>(false);
    const [reportRan, setReportRan] = React.useState<boolean>(false);
    const [beginMinDate] = React.useState<string>("2020-01-01");
    const [beginMaxDate] = React.useState<string>("2050-01-01");
    const [endMinDate, setEndMinDate] = React.useState<string>("2020-01-01");
    const [endMaxDate, setEndMaxDate] = React.useState<string>("2050-01-01");

    const buttonReportEnabled = !reportRan && report?.filter(item => item.selected).length !== 0;
    const buttonExportEnabled = reportRan && report?.filter(item => item.selected).length !== 0;

    React.useEffect(() => {
        async function retrieveReports() {
            const reports = await fetchUsageReport(filter, props.clientId);
            setReport(reports);
        }

        setNewMaxEndDate(filter.startDay);
        setNewMinEndDate(filter.startDay);
        
        retrieveReports();
    }, [props.clientId]);


    function setNewMaxEndDate(startDate: string) {
        const newMax = calculateNewMaxEndDate(startDate);
        setEndMaxDate(newMax);
    }

    function setNewMinEndDate(startDay: string) {
        const newMin = calculateNewMinEndDate(startDay);
        setEndMinDate(newMin);
    }

    function calculateNewMaxEndDate(startDate: string) : string {
        const newEndDay = moment(startDate).add(31, 'days').format('YYYY-MM-DD');
        return newEndDay;
    }

    function calculateNewMinEndDate(startDay: string) : string {
        const newMinEndDay = moment(startDay).format('YYYY-MM-DD');
        return newMinEndDay;
    }

    function getDiffInMonths (startDay: string, endDay: string) {
        const start = new Date(startDay);
        const end = new Date(endDay);
        const diffInMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
        return diffInMonths;
    }

    const updateStartDay = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const diffInMonths = getDiffInMonths(e.target.value, filter.endDay);
        setReportRan(false);
        setSelectedAll(false);
        setNewMaxEndDate(e.target.value);
        setNewMinEndDate(e.target.value);
        if (diffInMonths >= 1) {
            const updatedFilter = { ...filter, startDay: e.target.value, endDay: calculateNewMaxEndDate(e.target.value), callLists: []};
            setFilter(updatedFilter);
            const data = await fetchUsageReport(updatedFilter, props.clientId);
            setReport(data)
        }
        else {
            const updatedFilter = { ...filter, startDay: e.target.value };
            setFilter(updatedFilter);
            const data = await fetchUsageReport(updatedFilter, props.clientId);
            setReport(data)
        } 
    };

    const updateEndDay = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setReportRan(false);
        setSelectedAll(false);
        const updatedFilter = { ...filter, endDay: e.target.value, callLists: []};
        setFilter(updatedFilter);
        const data = await fetchUsageReport(updatedFilter, props.clientId);
        setReport(data)
    };

    const handleRunClick = async () => {
        const selectedCallListIds = report?.filter(item => item.selected).map(item => item.callListId) || [];
        const updatedFilter = { ...filter, callLists: selectedCallListIds };
        setFilter(updatedFilter);
        const data = await fetchUsageReport(updatedFilter, props.clientId);
        setReport(data);
        setReportRan(true);
        setSelectedAll(false);
    };

    const handleExportClick = async () => {
        const csvData  = convertToCSV();
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'usage_report.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedReport = report?.map(checkbox => ({ ...checkbox, selected: !selectedAll }));
        setReport(updatedReport);
        setSelectedAll(!selectedAll);
    };

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
       const updatedReport = report?.map(clRow =>
            clRow.callListId === parseInt(e.target.id) ? { ...clRow, selected: !clRow.selected } : clRow
          );
          setReport(updatedReport);
    };

    const convertToCSV = () => {
        const header = 'Call List, Campaign, Created, Calls, Billed Duration, Rate, Cost\n';
        const rows = report?.map(obj => {
                                            if (obj.selected)
                                               return `${obj.callListName}, ${obj.campaignName}, ${moment(obj.created).format("YYYY-MM-DD")}, ${obj.calls}, ${obj.billedDuration/60}, ${obj.rate.toFixed(4)}, ${obj.cost.toFixed(2)} `
                                        }) || [];
        return `${header}${rows.filter(row => row !== undefined).join('\n')}`;
      };

    function reportRow(report: UsageReportData, id: number) {
        return (
          <Row key={id}>
            <Cell><CheckboxInput id={report.callListId.toString()} value={report.callListId} sliderlabel={report.callListName} roundedslider={false} checked={report.selected} onChange={handleCheck}/></Cell>
            <Cell>{report.campaignName}</Cell>
            <Cell>{moment(report.created).format("YYYY-MM-DD")}</Cell>
          </Row>
        );
    }

    function reportRowFull(report: UsageReportData, id: number) {
        return (
          <Row key={id}>
            <Cell><CheckboxInput id={report.callListId.toString()} value={report.callListId} sliderlabel={report.callListName} roundedslider={false} checked={report.selected} onChange={handleCheck}/></Cell>
            <Cell>{report.campaignName}</Cell>
            <Cell>{moment(report.created).format("YYYY-MM-DD")}</Cell>
            <Cell>{report.calls}</Cell>
            <Cell>{report.billedDuration / 60}</Cell>
            <Cell>${report.rate.toFixed(4)}</Cell>
            <Cell>${report.cost.toFixed(2)}</Cell>
          </Row>
        );
    }

    return (
        <div className="h-full flex flex-col p-10 overflow-y-auto"> 
            <div id="data-filter" className="flex">

                <div className="mr-6">
                    <label htmlFor="beginDate" className="mr-4">Begin Date:</label>
                    <input type="date" id="beginDate" className="border-gray border-1" value={filter?.startDay} onChange={updateStartDay} min={beginMinDate}  max={beginMaxDate}/>
                </div>

                <div className="">
                    <label htmlFor="endDate" className="mr-4">End Date:</label>
                    <input type="date" id="endDate" className="border-gray border-1" value={filter?.endDay} onChange={updateEndDay}  min={endMinDate}  max={endMaxDate} />
                </div>

                <div className="ml-auto mb-4 flex">
                    <div className="mr-4">
                        <ConfirmButton onClick={() => {handleRunClick()}} disabled={!buttonReportEnabled}>
                            Run Report
                        </ConfirmButton>
                    </div>
                    <ConfirmButton onClick={() => {handleExportClick()}} disabled={!buttonExportEnabled}>
                        Export
                    </ConfirmButton>
                </div>
            </div> 
            {typeof report !== 'undefined' && report.length !== 0 && !reportRan &&(
            <div id="data-table">
                <Table>
                    <thead>
                        <ColumnsRow>
                            <ColumnName>
                                <CheckboxInput id={"selectAllCheckBox"} value={"selectAllCheckBox"} checked={selectedAll} sliderlabel={"Call List"} roundedslider={false} onChange={handleCheckAll} />
                            </ColumnName>
                            <ColumnName>Campaign</ColumnName>
                            <ColumnName>Created</ColumnName>
                        </ColumnsRow>
                    </thead>
                    <tbody>{report.map(reportRow)}</tbody>
                </Table>
            </div>)}

            {typeof report !== 'undefined' && reportRan &&(
            <div id="data-table">
                <Table>
                    <thead>
                        <ColumnsRow>
                            <ColumnName>
                                <CheckboxInput id={"selectAllCheckBoxFull"} value={"selectAllCheckBoxFull"} checked={selectedAll} sliderlabel={"Call List"} roundedslider={false} onChange={handleCheckAll} />
                            </ColumnName>
                            <ColumnName>Campaign</ColumnName>
                            <ColumnName>Created</ColumnName>
                            <ColumnName>Calls</ColumnName>
                            <ColumnName>Billed Duration</ColumnName>
                            <ColumnName>Rate</ColumnName>
                            <ColumnName>Cost</ColumnName>
                        </ColumnsRow>
                    </thead>
                    <tbody>{report.map(reportRowFull)}</tbody>
                </Table>
            </div>)}
        </div>
    );
};