export function validEmail(email: string) {
  // This regex was taken from the following site: https://emailregex.com/
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validSimpleString(s: string) {
  return /^[\w\d\s]+$/.test(s);
}

export function validName(s: string) {
  return /^(\w|\d)[\w\d\s]*$/.test(s);
}

export function validAlphanumericIdentifier(s: string) {
  return /^[\w\d]+$/.test(s);
}
