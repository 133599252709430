import * as React from "react";
import { deletePhoneNumber, DoNotCall, DoNotCallType, downloadCSVList, fetchDoNotCallRestrictions, postDoNotCallList, postNewAreaCode, postNewPhoneNumber, postNewState } from "../api/donotcall";
import { Cell, ColumnName, ColumnsRow, Row, Table, TitleRow } from "../shared/table/index";
import ConfirmButton from "../shared/buttons/confirm";
import searchIcon from "../images/search-icon-purple.svg";
import trashIcon from "../images/trash_fill_icon.svg";
import { ReactComponent as AlertIcon } from "../images/alert-badge-icon.svg";
import { ReactComponent as SmallDownloadIcon } from "../images/file_download_black_24dp.svg";
import { Modal } from "../shared/modal";
import CancelButton from "../shared/buttons/cancel";
import { dynamicPhoneMask, phoneFormatted, phoneMask } from "../utils/PhoneUtils";
import { FileInput, SelectInput } from "../shared/input";
import { fetchStates, State } from "../api/state";
import { AreaCode, fetchAreaCodes } from "../api/areacode";

export const isPhoneRepeated = (phoneNumber: string, listRestrictions: Array<DoNotCall>): boolean => {
  return !!listRestrictions.filter(
    (phones) => phones.phoneRestriction.toString() === phoneFormatted(phoneNumber, true).toString()
  ).length;
};

const DoNotCallListPhoneNumberItem = (props: {
  restrictedItem: DoNotCall;
  onDeleteAction: any;
}) => {
  let [phoneNumber, setPhoneNumber] = React.useState<DoNotCall | null>(null);
  let [isSelected, setNumberIsSelected] = React.useState<Boolean>(false);

  //========================
  // Input handler functions
  //========================
  const handleRemovePhoneNumberClick = () => {
    setPhoneNumber(props.restrictedItem);
    props.onDeleteAction(phoneNumber);
  };

  const handleSelectPhoneNumber = () => {
    setPhoneNumber(props.restrictedItem);
    setNumberIsSelected(true);
  };

  const handleUnselectPhoneNumber = () => {
    setNumberIsSelected(false);
  };

  //============
  // UI Elements
  //============
  return (
    <Row
      key={props.restrictedItem.id}
      onMouseOver={handleSelectPhoneNumber}
      onMouseLeave={handleUnselectPhoneNumber}
      onClick={handleSelectPhoneNumber}
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Cell>{phoneMask(phoneFormatted(props.restrictedItem.phoneRestriction, false), true)}</Cell>
      <Cell>
        {isSelected && (
          <div className="wrapper cursor-pointer">
            <img alt="" src={trashIcon} onClick={handleRemovePhoneNumberClick} />
          </div>
        )}
      </Cell>
    </Row>
  );
};

const DoNotCallListStateItem = (props: {
  restrictedItem: DoNotCall;
  onDeleteAction: any;
  stateList: State[];
}) => {
  let [state, setState] = React.useState<DoNotCall | null>(null);
  let [isSelected, setStateIsSelected] = React.useState<Boolean>(false);

  //========================
  // Input handler functions
  //========================
  const handleRemoveStateClick = () => {
    setState(props.restrictedItem);
    props.onDeleteAction(state);
  };

  const handleSelectState = () => {
    setState(props.restrictedItem);
    setStateIsSelected(true);
  };

  const handleUnselectState = () => {
    setStateIsSelected(false);
  };


  //========================
  // Format Input functions
  //========================
  const stateFormatted = (stateId: number) => {
    let state = props.stateList.find((state) => state.stateId === stateId);
    return state?.name;
  };

  //============
  // UI Elements
  //============
  return (
    <Row
      key={props.restrictedItem.id}
      onMouseOver={handleSelectState}
      onMouseLeave={handleUnselectState}
      onClick={handleSelectState}
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Cell>{stateFormatted(props.restrictedItem.stateRestriction)}</Cell>
      <Cell>
        {isSelected && (
          <div className="wrapper cursor-pointer">
            <img alt="" src={trashIcon} onClick={handleRemoveStateClick} />
          </div>
        )}
      </Cell>
    </Row>
  );
};

const DoNotCallListAreaCodeItem = (props: {
  restrictedItem: DoNotCall;
  onDeleteAction: any;
  areaCodeList: AreaCode[];
}) => {
  let [areaCode, setAreaCode] = React.useState<DoNotCall | null>(null);
  let [isSelected, setAreaCodeIsSelected] = React.useState<Boolean>(false);

  //========================
  // Input handler functions
  //========================
  const handleRemoveAreaCodeClick = () => {
    setAreaCode(props.restrictedItem);
    props.onDeleteAction(areaCode);
  };

  const handleSelectAreaCode = () => {
    setAreaCode(props.restrictedItem);
    setAreaCodeIsSelected(true);
  };

  const handleUnselectAreaCode = () => {
    setAreaCodeIsSelected(false);
  };


  //============
  // UI Elements
  //============
  return (
    <Row
      key={props.restrictedItem.id}
      onMouseOver={handleSelectAreaCode}
      onMouseLeave={handleUnselectAreaCode}
      onClick={handleSelectAreaCode}
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Cell>{props.restrictedItem.areaCodeRestriction}</Cell>
      <Cell>
        {isSelected && (
          <div className="wrapper cursor-pointer">
            <img alt="" src={trashIcon} onClick={handleRemoveAreaCodeClick} />
          </div>
        )}
      </Cell>
    </Row>
  );
};

//================ TODO: Refactor this page to use the new components for each type of restriction ===================
export const DoNotCallPage = (props: { clientId: number }) => {
  //================
  // Hooks
  //================

  const [listRestrictions, setListRestrictions] = React.useState<DoNotCall[]>([]);
  const [listState, setListState] = React.useState<State[]>([]);
  const [listAreaCode, setListAreaCode] = React.useState<AreaCode[]>([]);
  const [searchedPhoneNumber, setSearchedPhoneNumber] = React.useState("");
  const [searchedState, setSearchedState] = React.useState("");
  const [searchedAreaCode, setSearchedAreaCode] = React.useState("");
  const [newPhoneNumber, setNewPhoneNumber] = React.useState<string>("");
  const [newState, setNewState] = React.useState<number>(-1);
  const [newAreaCode, setNewAreaCode] = React.useState<number>(-1);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = React.useState<DoNotCall | null>();
  const [uploadResponse, setUploadResponse] = React.useState<Boolean | null>(null);
   // Modals
  const [showProcessingModal, setShowProcessingModal] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showUploadedModal, setShowUploadedModal] = React.useState<boolean>(false);

  const [selectedFile, setSelectedFile] = React.useState<File | null | undefined>(null);
  const [selectedOption, setSelectedOption] = React.useState<DoNotCallType>(DoNotCallType.PhoneNumber);


  const handleOptionClick = (option: DoNotCallType) => {
    setSelectedOption(option);
  };

  const getStateName = (stateId: number) => {
    let state = listState.find((state) => state.stateId === stateId);
    return state?.name;
  };

  //================
  // Search based functions
  //================

  const listPhoneNumberRestrictionsFiltered =
    searchedPhoneNumber === ""
      ? listRestrictions.filter((restriction) => restriction.phoneRestriction.length > 0 && restriction.doNotCallTypeId === DoNotCallType.PhoneNumber)
      : listRestrictions.filter((restriction) =>
        phoneFormatted(restriction.phoneRestriction, false).includes(searchedPhoneNumber) && restriction.doNotCallTypeId === DoNotCallType.PhoneNumber
      );

  const listStateRestrictionsFiltered = 
    searchedState === "" 
    ? listRestrictions.filter((restriction) => restriction.stateRestriction > 0) 
    : listRestrictions.filter((restriction) => restriction.stateRestriction > 0).filter((restriction) => getStateName(restriction.stateRestriction)?.toLowerCase().includes(searchedState.toLowerCase()));

  const listAreaCodeRestrictionsFiltered = 
    searchedAreaCode === "" 
    ? listRestrictions.filter((restriction) => restriction.areaCodeRestriction > 0) 
    : listRestrictions.filter((restriction) => restriction.areaCodeRestriction > 0).filter((restriction) => restriction.areaCodeRestriction.toString().includes(searchedAreaCode.toLowerCase()));
  //================
  // Phone Validity Functions
  //================

  const isNumberValid =
    newPhoneNumber &&
    newPhoneNumber.length === 14 &&
    newPhoneNumber.substr(0, 1) !== "0" &&
    newPhoneNumber.substr(0, 1) !== "1";

  const isNumberRepeated = isPhoneRepeated(phoneMask(newPhoneNumber, false), listRestrictions);
  const enableAddition = isNumberValid && !isNumberRepeated;
  const enableAdditionBulk = selectedFile !== null;

  const isValidState = newState > 0;

  const isValidAreaCode = newAreaCode > 0;

  const messageToUser = isNumberRepeated
    ? "Phone number restriction must be unique!"
    : "By Clicking 'Add Phone' you are automatically saving the number to the do not call list.";

  const messageToUserState = !isValidState
  ? "State restriction must be unique!"
  : "By Clicking 'Add State' you are automatically saving the state to the do not call list.";

  const messageToUserAreaCode = !isValidAreaCode
  ? "Area Code restriction must be unique!"
  : "By Clicking 'Add Area Code' you are automatically saving the area code to the do not call list.";


  //====================
  // Retrieval functions
  //====================
  //
  const loadRestrictions = React.useCallback(async () => {
    let data = await fetchDoNotCallRestrictions(props.clientId);
    setListRestrictions(data);
  }, [props.clientId]);

  const loadStates = React.useCallback(async () => {
    let data = await fetchStates();
    setListState(data);
  }, [props.clientId]);

  const loadAreaCodes = React.useCallback(async () => {
    let data = await fetchAreaCodes();
    setListAreaCode(data);
  }, [props.clientId]);

  React.useEffect(() => {
    loadRestrictions();
  }, [loadRestrictions]);

  React.useEffect(() => {
    loadStates();
  }, [loadStates]);

  React.useEffect(() => {
    loadAreaCodes();
  }, [loadAreaCodes]);

  React.useEffect(() => {
    if (uploadResponse !== null) {
      setShowProcessingModal(false);
      setShowUploadedModal(true);
    }
  }, [uploadResponse]);

  const closeModalAndReturn = () => {
    setShowUploadedModal(false);
    window.location.replace("/main/donotcall");
  };


  //========================
  // UI Elements
  //========================
  const getStateOptions = () => {
    let array = [new Option("Select...", "-1")];
    listState?.forEach((item) => {
      array.push(new Option(item.name, item.stateId.toString()));
    });
    return array;
  };

  const getAreaCodeOptions = () => {
    let array = [new Option("Select...", "-1")];
    listAreaCode?.forEach((item) => {
      array.push(new Option(item.areaCode.toString(), item.areaCode.toString()));
    });
    return array;
  };


  //========================
  // Input handler functions
  //========================

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedPhoneNumber(e.target.value);
  };

  const handleSearchStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedState(e.target.value);
  };

  const handleSearchAreaCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedAreaCode(e.target.value);
  };

  const handleSelectStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!listRestrictions.some(d => d.stateRestriction === Number(e.target.value)))
      setNewState(Number(e.target.value));
    else
      setNewState(-1);
  };

  const handleSelectAreaCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!listRestrictions.some(d => d.areaCodeRestriction === Number(e.target.value)))
      setNewAreaCode(Number(e.target.value));
    else
    setNewAreaCode(-1);
  };

  const handleAddNewPhoneNumber = async () => {
    const requisitionStatus = await postNewPhoneNumber(props.clientId, phoneMask(newPhoneNumber, false));
    if (requisitionStatus) {
      setNewPhoneNumber("");
      loadRestrictions();
    }
  };

  const handleAddNewState = async () => {
    const requisitionStatus = await postNewState(props.clientId, newState);
    if (requisitionStatus) {
      setNewState(-1);
      loadRestrictions();
    }
  };


  const handleAddNewAreaCode = async () => {
    const requisitionStatus = await postNewAreaCode(props.clientId, newAreaCode);
    if (requisitionStatus) {
      setNewAreaCode(-1);
      loadRestrictions();
    }
  };

  const handleAddPhonesBulk = async () => {
    setShowProcessingModal(true);
    const formData = new FormData();
    formData.append("fileSelect", selectedFile!);
    const upload = await postDoNotCallList(props.clientId, formData);
    setUploadResponse(upload!);
   
  };

  const handleInsertNewPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    let numberInserted = e.target.value.toString().replace(/ /gm, "");
    setNewPhoneNumber(dynamicPhoneMask(numberInserted));
  };

  const selectNumberToRemove = (phoneNumber: DoNotCall) => {
    setSelectedPhoneNumber(phoneNumber);
    setShowModal(true);
  };

  const handleDeletePhoneNumber = async () => {
    if (selectedPhoneNumber) {
      const status = await deletePhoneNumber(selectedPhoneNumber, listRestrictions);
      if (status) {
        loadRestrictions();
        setShowModal(false);
      }
    }
    setSelectedPhoneNumber(null);
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setSelectedFile(files?.item(0));
  };
  //============
  // UI Elements
  //============


  const GetTypeDNC = (dnc: DoNotCall) => {
    if (!!dnc && dnc.doNotCallTypeId === DoNotCallType.PhoneNumber)
      return "Phone Number"
    else if (!!dnc && dnc.doNotCallTypeId === DoNotCallType.State)
      return "State"
    else if (!!dnc && dnc.doNotCallTypeId === DoNotCallType.AreaCode)
      return "Area Code";
    else
      return "";
  };

  const confirmDeletionModal = (
    <Modal
      title={`Delete ${GetTypeDNC(selectedPhoneNumber!)}`}
      content={
        <div className="flex flex-col justify-center items-center text-primaryDarkGrey">
          <p className="px-20 text-lg text-center">Do you want to permanently delete this {GetTypeDNC(selectedPhoneNumber!)}?</p>
        </div>
      }
      buttons={
        <div className="flex w-full justify-around mt-7 ">
          <div className="w-60">
            <CancelButton onClick={handleDeletePhoneNumber}>Delete {GetTypeDNC(selectedPhoneNumber!)}</CancelButton>
          </div>
          <div className="w-60">
            <CancelButton onClick={() => setShowModal(false)}>Cancel</CancelButton>
          </div>
        </div>
      }
      buttonClose={() => setShowModal(false)}
    />
  );

  const processingModalContainer = (
    <Modal
      title={"Upload Progress"}
      content={
        <div className="flex flex-col justify-center items-center text-primaryDarkGrey">
          <p className="px-20 text-lg text-center">Processing do not call numbers...</p>
        </div>
      }
      buttons={
        <div className="flex w-full justify-around mt-7 ">
          <div className="w-60">
            <CancelButton onClick={() => setShowProcessingModal(false)}>Ok</CancelButton>
          </div>
        </div>
      }
      buttonClose={() => setShowProcessingModal(false)}
    />
  );
  

  const uploadedModalContainer = (
    <Modal
      title={"Upload Finished!"}
      content={
        <div className="flex flex-col justify-center items-center text-primaryDarkGrey">
          <p className="px-20 text-lg text-center">Done!</p>
        </div>
      }
      buttons={
        <div className="flex w-full justify-around mt-7 ">
          <div className="w-60">
            <CancelButton onClick={() => closeModalAndReturn()}>Ok</CancelButton>
          </div>
        </div>
      }
      buttonClose={() => closeModalAndReturn()}
    />
  );

  if (listPhoneNumberRestrictionsFiltered) {
    const doNotCallPhoneNumberListItems = listPhoneNumberRestrictionsFiltered.map((item, index) => {
      return (
        <DoNotCallListPhoneNumberItem
          key={index}
          onDeleteAction={selectNumberToRemove}
          //listRestrictions={listRestrictionsFiltered}
          restrictedItem={item}
        />
      );
    });

    const doNotCallStateListItems = listStateRestrictionsFiltered.map((item, index) => {
      return (
        <DoNotCallListStateItem
          key={index}
          onDeleteAction={selectNumberToRemove}
          restrictedItem={item}
          stateList={listState}
        />
      );
    });

    const doNotCallAreaCodeListItems = listAreaCodeRestrictionsFiltered.map((item, index) => {
      return (
        <DoNotCallListAreaCodeItem
          key={index}
          onDeleteAction={selectNumberToRemove}
          restrictedItem={item}
          areaCodeList={listAreaCode}
        />
      );
    });

    return (

      <div>

        <div id="menu" className="flex justify-center space-x-7 pb-5">
          <div className="w-1/4">
            <ConfirmButton onClick={() => handleOptionClick(DoNotCallType.PhoneNumber)} disabled={selectedOption === DoNotCallType.PhoneNumber}>
              Phone Number
            </ConfirmButton>
          </div>
          <div className="w-1/4">
            <ConfirmButton onClick={() => handleOptionClick(DoNotCallType.State)} disabled={selectedOption === DoNotCallType.State}>
              State
            </ConfirmButton>
          </div>
          <div className="w-1/4">
            <ConfirmButton onClick={() => handleOptionClick(DoNotCallType.AreaCode)} disabled={selectedOption === DoNotCallType.AreaCode}>
              Area Code
            </ConfirmButton>
          </div>
        </div>

        {selectedOption === DoNotCallType.PhoneNumber && (
        <div id="phoneNumberRestriction" className="h-full flex w-full 2xl:w-2/3 py-5 px-12">
          {showModal && confirmDeletionModal}
          {showProcessingModal && processingModalContainer}
          {showUploadedModal && uploadedModalContainer}
            <div className="flex-1 flex flex-col">
              <input className=" h-10 rounded-full 
                    border border-lightGray 
                    px-8 mb-2 appearance-none 
                    focus:border-mediumGray transition-colors"
                style={{ background: `#FFFFFF url(${searchIcon}) no-repeat 10px` }}
                name="searchDoNotCallNumber"
                type="number"
                placeholder="Search for Number"
                onChange={handleSearchChange} />
              <span className="text-darkGrayTitle text-xs font-italic mb-2">Loading up to 1000 numbers. For more, use the button bellow to download the whole list.</span>
              <div className="sm:max-h-[350px] xl:max-h-[300px]">
                <Table>
                  <thead>
                    <TitleRow colSpan={2}>
                      <div className="flex">
                        <th> Do Not Call List </th>
                        <div className="text-sm cursor-pointer pl-2 pt-1" title="Export DNC List" onClick={() => downloadCSVList(props.clientId)}>
                          {React.cloneElement(<SmallDownloadIcon />, { className: "fill-mediumGray hover:fill-lightGray" })}
                        </div>
                      </div>
                    </TitleRow>
                    <ColumnsRow>
                      <ColumnName colSpan={2}> Phone Numbers</ColumnName>
                    </ColumnsRow>
                  </thead>
                  <tbody>{doNotCallPhoneNumberListItems}</tbody>
                  {!doNotCallPhoneNumberListItems.length && (
                    <tbody>
                      <Row>
                        <Cell>Couldn't find any records</Cell>
                      </Row>
                    </tbody>
                  )}
                </Table>
              </div>
            </div>

            <div>
              <div className="flex-1 flex flex-col h-fit ml-8 py-4 px-8
                              bg-white rounded-lg shadow-window
                              border border-spaceGray mb-4">
                  <span className="mb-4">Phone Number</span>
                  <div className="mb-4 flex justify-start">
                    <input
                      className="p-2 basis-5/12 border border-lightGray focus:border-mediumGray transition-colors rounded-md h-10 mr-5"
                      name="newPhoneNumber"
                      value={newPhoneNumber}
                      maxLength={14}
                      onChange={handleInsertNewPhoneNumber}
                    />
                    <div className="w-32">
                      <ConfirmButton onClick={handleAddNewPhoneNumber} disabled={!enableAddition}>
                        Add Number
                      </ConfirmButton>
                    </div>
                  </div>

                  <div className="flex">
                    <AlertIcon className="mr-1" />
                    <span className="text-sm"> {messageToUser}</span>
                  </div>
              </div>
              
              <div className="flex-1 flex flex-col h-fit ml-8 py-4 px-8
                            bg-white rounded-lg shadow-window
                            border border-spaceGray">
                  <span className="mb-4">Bulk Import</span>
                  <div className="mb-4 flex items-end">
                      <FileInput title="Upload File" placeholder="Choose file" name="fileUpload" id="fileUpload" onChange={handleFile} />
                      <div className="ml-4" >
                        <ConfirmButton onClick={handleAddPhonesBulk} disabled={!enableAdditionBulk}>
                          Add Numbers
                        </ConfirmButton>
                      </div>
                  </div>
                  
              </div>
            </div>
          </div>
        )}

        {selectedOption === DoNotCallType.State && (

          <div id="stateRestriction" className="h-full flex w-full 2xl:w-2/3 py-5 px-12">
            {showModal && confirmDeletionModal}
            <div className="flex-1 flex flex-col">
              <input className=" h-10 rounded-full 
                    border border-lightGray 
                    px-8 mb-2 appearance-none 
                    focus:border-mediumGray transition-colors"
                style={{ background: `#FFFFFF url(${searchIcon}) no-repeat 10px` }}
                name="searchDoNotCallState"
                type="text"
                onChange={handleSearchStateChange}
                placeholder="Search for State"
                />
              
              <div className="sm:max-h-[350px] xl:max-h-[300px]">
                <Table>
                  <thead>
                    <TitleRow colSpan={2}>
                      <div className="flex">
                        <th> Do Not Call List </th>
                      </div>
                    </TitleRow>
                    <ColumnsRow>
                      <ColumnName colSpan={2}> States </ColumnName>
                    </ColumnsRow>
                  </thead>
                  <tbody>{doNotCallStateListItems}</tbody>
                  {!doNotCallStateListItems.length && (
                    <tbody>
                      <Row>
                        <Cell>Couldn't find any records</Cell>
                      </Row>
                    </tbody>
                  )}
                </Table>
              </div>
            </div>

            <div>
              <div className="flex-1 flex flex-col h-fit ml-8 py-4 px-8
                              bg-white rounded-lg shadow-window
                              border border-spaceGray mb-4">
                  <span className="mb-4">State</span>
                  <div className="mb-4 flex justify-start">
                    <div className="w-64">
                      <SelectInput
                        id={'selectStateInput'}
                        onChange={handleSelectStateChange}
                        options={getStateOptions()}
                        className="p-2 border border-lightGray focus:border-mediumGray transition-colors rounded-md h-10 w-full mr-4"
                      />
                    </div>
                    <div className="w-32">
                      <ConfirmButton onClick={handleAddNewState} disabled={!isValidState}>
                        Add State
                      </ConfirmButton>
                    </div>
                  </div>

                  <div className="flex">
                    <AlertIcon className="mr-1" />
                    <span className="text-sm"> {messageToUserState}</span>
                  </div>
              </div>
            </div>


          </div>
        )}

        {selectedOption === DoNotCallType.AreaCode && (
           <div id="areaCodeRestriction" className="h-full flex w-full 2xl:w-2/3 py-5 px-12">
           {showModal && confirmDeletionModal}
           <div className="flex-1 flex flex-col">
             <input className=" h-10 rounded-full 
                   border border-lightGray 
                   px-8 mb-2 appearance-none 
                   focus:border-mediumGray transition-colors"
               style={{ background: `#FFFFFF url(${searchIcon}) no-repeat 10px` }}
               name="searchDoNotCallAreaCode"
               type="text"
               onChange={handleSearchAreaCodeChange}
               placeholder="Search for Area Code"
               />
             
             <div className="sm:max-h-[350px] xl:max-h-[300px]">
               <Table>
                 <thead>
                   <TitleRow colSpan={2}>
                     <div className="flex">
                       <th> Do Not Call List </th>
                     </div>
                   </TitleRow>
                   <ColumnsRow>
                     <ColumnName colSpan={2}> Area Codes </ColumnName>
                   </ColumnsRow>
                 </thead>
                 <tbody>{doNotCallAreaCodeListItems}</tbody>
                 {!doNotCallAreaCodeListItems.length && (
                   <tbody>
                     <Row>
                       <Cell>Couldn't find any records</Cell>
                     </Row>
                   </tbody>
                 )}
               </Table>
             </div>
           </div>

           <div>
             <div className="flex-1 flex flex-col h-fit ml-8 py-4 px-8
                             bg-white rounded-lg shadow-window
                             border border-spaceGray mb-4">
                 <span className="mb-4">Area Code</span>
                 <div className="mb-4 flex justify-start">
                   <div className="w-64">
                     <SelectInput
                       id={'selectAreaCodeInput'}
                       onChange={handleSelectAreaCodeChange}
                       options={getAreaCodeOptions()}
                       className="p-2 border border-lightGray focus:border-mediumGray transition-colors rounded-md h-10 w-full mr-4"
                     />
                   </div>
                   <div className="w-32">
                     <ConfirmButton onClick={handleAddNewAreaCode} disabled={!isValidAreaCode}>
                       Add Area Code
                     </ConfirmButton>
                   </div>
                 </div>

                 <div className="flex">
                   <AlertIcon className="mr-1" />
                   <span className="text-sm"> {messageToUserAreaCode}</span>
                 </div>
             </div>
           </div>


         </div>
        )}

      </div>
    );
  } else {
    return null;
  }
};

export default DoNotCallPage;
