import * as React from "react";
import { MessageStepType } from "../../enums/messages";
import { MessageStep, TtsField, TtsVoice } from "../../interfaces/messages";
import { CheckboxInput, SelectInput } from "../../shared/input";
import closeIcon from "../../images/close_icon.svg";
import ConfirmButton from "../../shared/buttons/confirm";

export const FieldText = (props: {
  data: MessageStep;
  fieldsData: TtsField[];
  voiceData: TtsVoice[];
  onSave: Function;
  onClose: Function;
}) => {
  const [fieldTextToSpeechSelected, setFieldTextToSpeechSelected] = React.useState<number>(0);
  const [voiceSelected, setVoiceSelected] = React.useState<number>(0);
  const [spellOptionSet, setSpellOption] = React.useState<number>(1);
  const enableSave = !!fieldTextToSpeechSelected && !!voiceSelected;

  const fillFields = React.useCallback(() => {
    if (props.data && props.fieldsData && props.fieldsData[props.data.ttsFieldId! - 1]) {
      const editedStep = props.data;
      setFieldTextToSpeechSelected(editedStep.ttsFieldId!);
      setVoiceSelected(editedStep.ttsVoiceId!);
      setSpellOption(editedStep.ttsModeId!);
    }
  }, [props.data, props.fieldsData]);

  React.useEffect(() => {
    fillFields();
  }, [fillFields]);

  const handleSave = () => {
    const customTextObj = new MessageStep();

    customTextObj.messageStepType = MessageStepType.FieldTTS;
    customTextObj.value = props.fieldsData.find(a => a.id === fieldTextToSpeechSelected)?.value!;
    customTextObj.ttsFieldId = fieldTextToSpeechSelected;
    customTextObj.ttsModeId = spellOptionSet;
    customTextObj.ttsVoiceId = voiceSelected;

    props.onSave(customTextObj);
    props.onClose(true);
    clearFields();
  };

  const clearFields = () => {
    setFieldTextToSpeechSelected(0);
    setSpellOption(1);
    setVoiceSelected(0);
  };

  const handleClose = () => {
    props.onClose(true);
  };

  const handleSpellSet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const checkedValue = isChecked ? 2 : 1;
    setSpellOption(checkedValue);
  };

  const handleSelectedVoice = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setVoiceSelected(Number(e.target.value));
  };

  const handleSelectedTextField = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldTextToSpeechSelected(Number(e.target. value));
  };

  return (
    <div
      className="items-center justify-around
            flex w-full bg-white 
            shadow-container flex-wrap opacity-100 
            h-auto py-8 px-6 mt-6"
    >
      <div className="w-1/4">
        <SelectInput
          title="Text to speech variables"
          value={fieldTextToSpeechSelected}
          onChange={handleSelectedTextField}
          options={props.fieldsData.map((field) => {
            return new Option(field.value, field.id.toString());
          })}
        ></SelectInput>
      </div>

      <div className="w-1/4">
        <SelectInput
          title="Voice"
          value={voiceSelected}
          onChange={handleSelectedVoice}
          options={props.voiceData.map((voice) => {
            return new Option(voice.name, voice.id.toString());
          })}
        ></SelectInput>
      </div>
      <div className="w-1/4">
        <CheckboxInput
          checked={spellOptionSet === 2}
          roundedslider={true}
          onChange={handleSpellSet}
          title="Spell"
          sliderlabel={spellOptionSet === 2 ? "ON" : "OFF"}
        />
      </div>
      <div className="w-1/6 mt-6">
        <ConfirmButton disabled={!enableSave} onClick={handleSave}>
          Add
        </ConfirmButton>
      </div>
      <img alt="" className="cursor-pointer relative block -top-16" src={closeIcon} onClick={handleClose} />
    </div>
  );
};
