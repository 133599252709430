import { Config } from "../config";
import { pick } from "../utils/Conversion";

export type ArchivedReport = {
  clientId: number;
  created: string;
  downloadCount: number;
  lastDownload: string;
  numberOfRecords: number;
  progress: number;
  reportArchiveId: number;
  reportStatusId: number;
  reportStatusName: string;
  reportTypeId: number;
  url: string;
};

let user = JSON.parse(localStorage.getItem("user")!);

function getFileName(filePath: string) {
  let name = filePath.substring(filePath.lastIndexOf("/") + 1);
  if (name !== "") return name;
  else {
    return filePath.substring(filePath.lastIndexOf("\\") + 1);
  }
}

export async function downloadReport(report: { clientId: number; reportArchiveId: number; url: string }) {
  const url = new URL(`${Config.platformwebapi_url}/api/reports/${report.reportArchiveId}/downloadreport`);

  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.bearerToken,
    },
    body: JSON.stringify({
      clientId: report.clientId,
      reportId: report.reportArchiveId,
      url: report.url,
    }),
  });

  if (response.ok) {
    //TODO: This is really a hideous solution. Isn't there a better way to do this, or should the backend be changed somehow to avoid this entirely?
    const wrappedBlob = new Blob([await response.blob()], { type: "text/csv" });
    const blobURL = window.URL.createObjectURL(wrappedBlob);

    let link = document.createElement("a");
    link.href = blobURL;
    link.download = getFileName(report.url);
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(blobURL);
    }, 250);
  }
}

export async function fetchArchivedReports(clientId: number, count: number) {
  const url = new URL(`${Config.platformwebapi_url}/api/reports/${clientId}/archive`);

  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.bearerToken,
    },
    body: "25",
  });

  const responseJson = await response.json();
  let reports: ArchivedReport[] = [];
  for (let v of responseJson) {
    const bucket = pick<ArchivedReport>(v, {
      clientId: Number,
      created: String,
      downloadCount: Number,
      lastDownload: String,
      numberOfRecords: Number,
      progress: Number,
      reportArchiveId: Number,
      reportStatusId: Number,
      reportStatusName: String,
      reportTypeId: Number,
      url: String,
    });

    reports.push(bucket);
  }

  return reports;
}
