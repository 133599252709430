import React from "react";
import arrowDown from "../../images/arrow-down-icon.svg";
import arrowUp from "../../images/arrow-up-icon.svg";

export const CollapsibleComponent = (props: {
  label: string;
  description: string;
  data: any;
  toggleOpened: boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsOpen(props.toggleOpened);
  }, [props.toggleOpened]);

  return (
    <div className="mb-10">
      <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer flex justify-between py-4 px-0">
        <span> {props.label}</span>
        <span> {props.description} </span>
        <div>
          <img alt="" src={isOpen ? arrowUp : arrowDown} />
        </div>
      </div>
      {isOpen && <div> {props.data} </div>}
    </div>
  );
};
