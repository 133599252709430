import { ContactListMapping } from "./gen/platformwebapi/models/ContactListMapping";
import { Config } from "../config";
import { CallListFile, CallListRenewRequest, Campaign } from "../interfaces/contactlist";
import { pick } from "../utils/Conversion";
import { CallListApi, Configuration, ContactListCreateInfo } from "./gen/platformwebapi";
const user = JSON.parse(localStorage.getItem("user")!);

export const createContactList = async (contactList: ContactListCreateInfo, abort?: AbortController) => {
  const config = new Configuration({ basePath: Config.platformwebapi_url, accessToken: user.bearerToken });
  const api = new CallListApi(config);
  return api.apiCallListGeneratePost({ contactListCreateInfo: contactList }, { signal: abort?.signal });
};

export const fetchPreviewGenerated = async (contactListMap: ContactListMapping, abort?: AbortController) => {
  const config = new Configuration({ basePath: Config.platformwebapi_url, accessToken: user.bearerToken });
  const api = new CallListApi(config);
  return api.apiCallListPreviewgeneratePost({ contactListMapping: contactListMap }, { signal: abort?.signal });
};

export const fetchCallLists = async (clientId: number) => {
  const params = new URLSearchParams();
  const url = new URL(`${Config.platformwebapi_url}/api/calllist`);
  params.append("clientId", String(clientId));

  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();

  let callListFields: CallListFile[] = [];

  for (let v of responseJson) {
    const callList = pick<CallListFile>(v, {
      callListId: Number,
      campaignId: Number,
      callListStatusName: String,
      originalFileName: String,
      campaignName: String,
      created: (d: string) => new Date(d),
      phoneCount: Number,
      accountCount: Number,
      availableCount: Number,
      calledCount: Number,
      notCalledCount: Number,
      dncCount: Number,
      callListStatusId: Number,
      fieldName: String,
      fieldColumn: (v) => {
        return v === undefined ? undefined : Number(v);
      },
      originalCallListId: Number,
      originalCallListName: String,
    });

    callListFields.push(callList);
  }

  return callListFields;
};

export const postCloseCallList = async (callList: CallListFile) => {
  const url = new URL(`${Config.platformwebapi_url}/api/calllist/${callList.callListId}/close`);
  const response = await fetch(url.toString(), {
    method: "POST",

    body: JSON.stringify(callList),

    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

// TODO: Is this really needed here? Don't we have a method of fetching campaigns elsewhere?
export const fetchCallListCampaigns = async (clientId: number) => {
  const params = new URLSearchParams();

  const url = new URL(`${Config.platformwebapi_url}/api/campaign`);

  params.append("clientId", String(clientId));

  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();

  let campaignsList: Campaign[] = [];

  for (let v of responseJson) {
    const campaigns = pick<Campaign>(v, {
      campaignId: Number,
      name: String,
      clientId: Number,
      campaignStatusId: Number,
      campaignStatusName: String,
      campaignType: Number,
      campaignTypeName: String,
      updated: String,
      startTime: (d: string) => new Date(d),
      stopTime: (d: string) => new Date(d),
      startAutomatically: Boolean,
      stopAutomatically: Boolean,
      callerIdNumber: String,
      callerIdName: String,
      campaignStarted: Boolean,
      callerIdTypeId: Number,
      startTimeSpan: String,
      stopTimeSpan: String,
      startWeekdays: String,
      stopWeekdays: String,
      rciBucketId: Number,
    });

    campaignsList.push(campaigns);
  }

  return campaignsList;
};

export const postReuseCallList = async (callListRequest: CallListRenewRequest) => {
  const url = new URL(`${Config.platformwebapi_url}/api/calllist/${callListRequest.calllistId}/renew`);

  const response = await fetch(url.toString(), {
    method: "POST",

    body: JSON.stringify(callListRequest),

    headers: {
      Authorization: "Bearer " + user.bearerToken,

      "Content-Type": "application/json",
    },
  });

  return response.ok;
};
