// This is useful when you want to use TypeScript to make a switch for discriminated unions exhaustive
export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

export function assertNonNullish<TValue>(value: TValue, message?: string): asserts value is NonNullable<TValue> {
  if (value === null || value === undefined) {
    throw Error(message ? message : "Value was null or undefined!");
  }
}

export function assertPositiveValue(value: number, message: string): asserts value is number {
  if (value < 0) {
    throw Error(message);
  }
}
