import React from "react";
import { CurrentManagerDetails } from "../managers/currentmanagerdetails";
import { Modal } from "../shared/modal";

export const UserPage = (props: { clientId: number }) => {
  const [showSavedModal, setShowSavedModal] = React.useState<boolean>(false);
  const SavedModal = (
    <Modal
      title={"Saved"}
      content={
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl text-center">The modifications were saved successfully !</p>
        </div>
      }
      buttons={
        <div className="h-full flex justify-evenly items-center">
          <button
            className="flex-1 ml-16 mr-8 px-10 py-2 
                       bg-white text-primaryPurple rounded-full 
                       shadow shadow-transparentGray"
            onClick={() => setShowSavedModal(false)}
          >
            Ok
          </button>
        </div>
      }
      buttonClose={() => setShowSavedModal(false)}
    />
  );
  return (
    <div className="h-full flex items-start py-5 px-24">
      {showSavedModal && SavedModal}
      <div className="mr-5">
        <div className="text-primaryDarkGrey font-bold text-3xl mb-3">User Profile</div>
        <div>
          <CurrentManagerDetails
            clientId={props.clientId}
            saveCallback={() => {
              setShowSavedModal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};
