import * as React from "react";
import { loadCallList } from "../../../api/upload";
import { FileInput, SelectInput, TextInput } from "../../../shared/input";
import { Cell, ColumnName, ColumnsRow, Row, Table, TitleRow } from "../../../shared/table";
import { PreviewCallListResponse } from "../map";

interface FileDelimiter {
  label: string;
  value: number;
  delimiter: string;
}

enum FileDelimiterType {
  COMMA = 0,
  SEMICOLON = 1,
  PIPE = 2,
  TAB = 3,
  OTHER = 4,
}

const listSeparators: FileDelimiter[] = [
  {
    value: FileDelimiterType.COMMA,
    label: "Comma",
    delimiter: ",",
  },
  {
    value: FileDelimiterType.SEMICOLON,
    label: "Semicolon",
    delimiter: ";",
  },
  {
    value: FileDelimiterType.PIPE,
    label: "Pipe",
    delimiter: "|",
  },
  {
    value: FileDelimiterType.TAB,
    label: "Tab",
    delimiter: "\t",
  },
  {
    value: FileDelimiterType.OTHER,
    label: "Other",
    delimiter: "",
  },
];

const checkDelimiterValid = (delimiter: string) => {
  let regTest = new RegExp("[^A-Za-z0-9]");
  if (delimiter.length !== 1 || !regTest.test(delimiter)) return false;
  else return true;
};

export const SelectFileComponent = (props: { fileUploadedOk: Function }) => {
  const infoDelimiter = "The delimiter must be a special character";

  const [fileDelimiter, setFileDelimiter] = React.useState<number>(listSeparators[0].value);
  const [newFileDelimiter, setNewFileDelimiter] = React.useState<string>("");

  const [csvRecords, setCsvRecords] = React.useState<PreviewCallListResponse | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<FileList | null>(null);
  const [numberRows, setNumberRows] = React.useState<number>(0);

  const fileDelimiterValid = newFileDelimiter && checkDelimiterValid(newFileDelimiter);

  const setFilePreview = (files: FileList, delimiter: string = ",") => {
    if (files) {
      Array.from(files).forEach((file) => {
        let reader = new FileReader();
        reader.readAsText(file);

        reader.onload = async () => {
          const formData = new FormData();
          let recordsArray = reader?.result?.toString().split(/\r\n|\n/);

          formData.append("delimiter", delimiter);

          if (recordsArray) {
            if (recordsArray.length < 20) {
              formData.append("fileSelect", file);
            } else {
              let chunckSize = 0;

              for (let i = 0; i < recordsArray.length; i++) {
                chunckSize += recordsArray[i].length;
              }
              formData.append("fileSelect", file.slice(0, chunckSize));
            }
          }

          const data = await loadCallList(formData);

          if (data) {
            const previewData: PreviewCallListResponse = data;
            const delimiter = listSeparators.find((separator) => +separator.value === +fileDelimiter)!.delimiter;
            setCsvRecords({ headers: previewData.headers, rows: previewData.rows });
            if (recordsArray) setNumberRows(recordsArray.length - 1);
            props.fileUploadedOk({ headers: previewData.headers, rows: previewData.rows }, file, delimiter);
          }
        };
      });
    }
  };

  //============
  // Handlers
  //============

  const handleChangeFileDelimiter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = +e.target.value;
    const delimiter = listSeparators.find((separator) => +separator.value === +value)!.value;
    const fileField = selectedFile;

    setFileDelimiter(delimiter);

    if (+delimiter !== FileDelimiterType.OTHER) {
      const delimiterElement = listSeparators.find((separator) => +separator.value === +value)!.delimiter;
      setFilePreview(fileField!, delimiterElement);
    }
  };
  const handleFilePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const delimiter = newFileDelimiter ? newFileDelimiter : ",";
    setSelectedFile(files);
    setFilePreview(files!, delimiter);
  };

  const handleAddNewFileDelimiter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toString();
    setNewFileDelimiter(value);

    if (checkDelimiterValid(value)) {
      setFilePreview(selectedFile!, value);
    } else {
      setCsvRecords(null);
    }
  };

  return (
    <>
      <div className="w-full mb-4 flex">
        <div className="w-full flex">
          <div className="w-1/4 mr-5">
            <SelectInput
              title="File Delimiter"
              id="fileDelimiter"
              name="fileDelimiter"
              value={fileDelimiter}
              onChange={handleChangeFileDelimiter}
              options={listSeparators.map((separator) => {
                return new Option(separator.label, separator.value.toString());
              })}
            />
          </div>
          {fileDelimiter === FileDelimiterType.OTHER && (
            <div className="w-1/5 mr-5">
              <TextInput
                title="New File Delimiter"
                name="newFileDelimiter"
                id="newFileDelimiter"
                onChange={handleAddNewFileDelimiter}
              />
              {!fileDelimiterValid && (
                <span className="label-information" style={{ color: "#fb7063" }}>
                  {infoDelimiter}
                </span>
              )}
            </div>
          )}
          <div className="w-1/2">
            <FileInput
              title=" Upload File"
              placeholder="Choose file"
              name="fileUpload"
              id="fileUpload"
              onChange={handleFilePreview}
            />
          </div>
        </div>
      </div>
      <div className="sm:h-[200px]">
        {csvRecords && (
          <>
            <Table>
              <thead>
                <TitleRow colSpan={csvRecords.headers.length}>Preview</TitleRow>
                <ColumnsRow>
                  {csvRecords.headers &&
                    csvRecords.headers.map((header, index) => {
                      return <ColumnName key={index}> {header} </ColumnName>;
                    })}
                </ColumnsRow>
              </thead>
              <tbody>
                {csvRecords.rows &&
                  csvRecords.rows.map((row, k) => {
                    return (
                      <Row key={k}>
                        {row.map((col, i) => {
                          return <Cell key={i}> {col} </Cell>;
                        })}
                      </Row>
                    );
                  })}
              </tbody>
            </Table>
            {!!numberRows && (
              <div>
                <span> Number of rows: {numberRows}</span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
