import React from "react";
import { Cell, ColumnName, ColumnsRow, Row, SortedColumn, Table, TitleRow } from "../table";
import meuButtonImg from "../../images/three_dots_opt_icon.svg";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { LoaderSpinner } from "../loaderspinner";
import { sortBy, SortedState } from "../../utils/Sort";

export type ActionCellProps<T> = {
  rowNum: number;
  actions: T[];
  actionHandler: (action: T, rowNum: number) => void;
};

export function ActionCell<T>({ actions, actionHandler: actionFn, rowNum }: ActionCellProps<T>) {
  const [showPopover, setShowPopover] = React.useState<boolean>(false);

  return (
    <Cell>
      <Popover
        isOpen={showPopover}
        padding={5}
        onClickOutside={() => setShowPopover(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={"black"}
            arrowSize={10}
          >
            <div className="bg-origin-padding bg-black text-white rounded-md min-w-144 relative top-[-1px]">
              <ul className="cursor-pointer">
                {actions.map((action, idx) => (
                  <li
                    key={idx}
                    onClick={() => {
                      setShowPopover(false);
                      actionFn(action, rowNum);
                    }}
                    className="p-3 hover:bg-primaryPurple first:rounded-t-md last:rounded-b-md hover:text-white transition-colors select-none"
                  >
                    {action}
                  </li>
                ))}
              </ul>
            </div>
          </ArrowContainer>
        )}
        align="center"
        positions={["bottom", "top", "left", "right"]}
      >
        <div
          className="py-2 flex items-center justify-center w-5 rounded-md hover:shadow-window hover:bg-spaceWhite transition-all select-none active:bg-gray"
          onClick={() => setShowPopover(!showPopover)}
        >
          <img alt="" src={meuButtonImg} className="text-center" />
        </div>
      </Popover>
    </Cell>
  );
}

export type EntityTableProps<T, T2> = {
  columns: string[];
  sortableColumns?: (keyof T)[];
  title?: string;
  entities: T[];
  rowFn: (x: T) => JSX.Element[];
  actionFn: (x: T) => T2[];
  actionHandler: (action: T2, rowNum: number) => void;
  loading?: boolean;
};

export function stringToCell(strings: string[]) {
  return strings.map((d) => {
    return <Cell>{d}</Cell>;
  });
}

export function EntityTable<T, A>({
  columns,
  sortableColumns,
  title,
  entities,
  actionHandler,
  rowFn,
  actionFn,
  loading,
}: EntityTableProps<T, A>) {
  const [entitiesList, setEntitiesList] = React.useState<T[]>([]);
  const [selectedEntity, setSelectedEntity] = React.useState<SortedColumn>();
  const [unsortedList, setUnsortedList] = React.useState<T[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>();

  React.useEffect(() => {
    if (entities && entities.length) {
      setEntitiesList(entities);
      setUnsortedList([...entities]);
    }
  }, [entities]);

  React.useEffect(() =>{
    setIsLoading(loading);
  }, [loading]);
 
  function handleSetSortOrderIcon(columnSelected: SortedColumn) {
    if (columnSelected) {
      setSelectedEntity(columnSelected);
      changeSortOrder(columnSelected);
    }
  }

  function changeSortOrder(columnSel: SortedColumn) {
    if (!!columnSel && sortableColumns && sortableColumns.length) {
      let updatedList =
        columnSel.state === SortedState.none
          ? unsortedList
          : entities.sort(sortBy<T>(sortableColumns[+columnSel.id], columnSel.state));

      setEntitiesList(updatedList);
    }
  }

  function makeRow(entity: T) {
    let cells = rowFn(entity);
    let rowNum = entities.indexOf(entity);

    if (rowNum !== -1) {
      let actions = actionFn(entity);
      const popoverCell = <ActionCell<A> rowNum={rowNum} actionHandler={actionHandler} actions={actions} />;

      cells.push(popoverCell);
      return (
        <Row key={rowNum} id={rowNum.toString()} onDoubleClick={() => actionHandler(actions[0], rowNum)}>
          {cells}
        </Row>
      );
    }
  }

  return (
    <Table>
      <thead>
        <TitleRow colSpan={columns.length + 1}>{title}</TitleRow>
        <ColumnsRow columnIsselected={handleSetSortOrderIcon}>
          {columns.map((c, i) => {
            if (i === columns.length - 1) {
              return (
                <ColumnName
                  colSpan={2}
                  id={i.toString()}
                  selected={selectedEntity && selectedEntity.id === i.toString() ? true : false}
                  sortObj={selectedEntity}
                  key={i}
                >
                  {c}
                </ColumnName>
              );
            } else {
              return (
                <ColumnName
                  id={i.toString()}
                  selected={selectedEntity && selectedEntity.id === i.toString() ? true : false}
                  sortObj={selectedEntity}
                  key={i}
                >
                  {c}
                </ColumnName>
              );
            }
          })}
        </ColumnsRow>
      </thead>
      <tbody>{!isLoading ? entitiesList.map(makeRow) : <LoaderSpinner tableLength={columns.length} />}</tbody>
    </Table>
  );
}
