export enum CallListStatus {
  New = 1,
  Processing = 2,
  Open = 3,
  Loaded = 4,
  Closing = 5,
  Closed = 6,
}

export enum CampaignStatus {
  Stopped = 1,
  Starting = 2,
  Running = 3,
  Stopping = 4,
}
