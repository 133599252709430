import * as React from "react";
import {
  DisplayCampaign,
  ContactList,
  fetchContactListsForCampaign,
  fetchContactListStats,
  CampaignStats,
  ContactListStats,
} from "../api/livemonitoring";
import { StatsChart } from "./campaignsummary";
import { getPercentage } from "./utils";
import useResizeObserver from "use-resize-observer";

type ContactListBarProps = {
  title: string;
  percentage: number;
};

function ContactListBar({ title, percentage }: ContactListBarProps) {
  return (
    <div className="inline-grid gap-1 grid-cols-[100px_auto]">
      <div
        className="text-sm p-1 text-ellipsis overflow-hidden 
                      shadow-window rounded-full border border-spaceGray 
                      select-none whitespace-nowrap 
                      hover:bg-lightPurple transition-colors text-center"
      >
        {title}
      </div>
      <div className="rounded-r-md bg-primaryPurple" style={{ width: getPercentage(percentage, 100) }} />
    </div>
  );
}
type ContactListProgress = { listName: string; progress: number };

function ContactListsChart(props: { lists: ContactListProgress[] }) {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  let svgContent: React.ReactElement | null = null;
  const svgElemWidth = width;
  const svgElemHeight = height;

  // Draw chart guidelines. We begin drawing at chartOffset, which is
  // the width of a bar's title + the gap between the title and the bar start.
  const chartOffset = 104;
  const chartWidth = svgElemWidth - chartOffset;

  let guides: React.ReactElement[] = [];
  for (let i = 0; i <= 5; i++) {
    const x = chartOffset + chartWidth * (0.2 * i);
    let elem = (
      <g transform="translate(0.5,0.5)">
        <path strokeWidth="1" className="stroke-lightGray" d={`M ${x} 0 V ${svgElemHeight + 10}`} />
        <text className="text-xs fill-mediumGray" x={x - 10} y={svgElemHeight + 20}>
          {i * 20}%
        </text>
      </g>
    );
    guides.push(elem);
  }

  svgContent = <g>{guides}</g>;

  return (
    <div ref={ref} className="relative flex-1 flex flex-col h-[90%] justify-end">
      <svg className="absolute w-full h-full z-0 overflow-visible">{svgContent}</svg>
      <div className="w-full z-10 items-end">
        <div className="pb-1 flex-1 flex flex-col max-h-64 gap-4 overflow-y-auto">
          {props.lists.map((cl) => (
            <ContactListBar title={cl.listName} percentage={cl.progress} />
          ))}
        </div>
      </div>
    </div>
  );
}

export const ContactLists = (props: { campaign: DisplayCampaign }) => {
  //================
  // Hooks
  //================
  const [lists, setLists] = React.useState<ContactList[]>([]);
  const [listStats, setListStats] = React.useState<Map<number, ContactListStats>>(new Map());

  const [selectedListId, setSelectedList] = React.useState(0);

  //====================
  // Retrieval functions
  //====================

  const loadContactLists = React.useCallback(
    async (campaign: DisplayCampaign) => {
      const data = await fetchContactListsForCampaign(campaign);

      setLists(data);
      if (data.length > 0 && selectedListId === 0) {
        const defaultSelection = data[0].callListId;
        setSelectedList(defaultSelection);
      }
    },
    [selectedListId]
  );

  const loadContactListStats = React.useCallback(
    async (campaign: DisplayCampaign, contactListId: number) => {
      if (contactListId) {
        const listStats = new Map<number, ContactListStats>();
        for (let l of lists) {
          const data = await fetchContactListStats(campaign.clientId, campaign.campaignType, l.callListId);
          listStats.set(l.callListId, data);
        }
        setListStats(listStats);
      }
    },
    [lists]
  );

  React.useEffect(() => {
    loadContactLists(props.campaign);

    //const listsRefresh = setInterval(() => {
    //  loadContactLists(props.campaign);
    //}, 10000);
    //
    //return () => clearInterval(listsRefresh);
  }, [props.campaign, loadContactLists]);

  React.useEffect(() => {
    loadContactListStats(props.campaign, selectedListId);

    //const campaignsRefresh = setInterval(() => {
    //  //loadContactListStats(props.campaign, selectedListId);
    //}, 10000);
    //
    //return () => clearInterval(campaignsRefresh);
  }, [props.campaign, selectedListId, loadContactListStats]);

  //==================
  // Utility functions
  //==================

  // We often need to get the list along with its index in the lists array
  const findList = (listId: number) => {
    let result: [ContactList | undefined, number] = [undefined, -1];
    result[0] = lists.find((c: ContactList, idx: number) => {
      result[1] = idx;
      return c.callListId === listId;
    });

    return result;
  };

  const changeListSelection = (idx: number) => {
    const currentListIndex = lists.findIndex((list) => list.callListId === selectedListId);

    let nextListId;

    if (idx === 1) {
      if (currentListIndex === lists.length - 1) {
        nextListId = lists[0].callListId;
      } else {
        nextListId = lists[currentListIndex + 1].callListId;
      }
    } else {
      if (currentListIndex === 0) {
        nextListId = lists[lists.length - 1].callListId;
      } else {
        nextListId = lists[currentListIndex - 1].callListId;
      }
    }

    return setSelectedList(nextListId);
  };

  const allListsProgress: ContactListProgress[] = [];
  lists.forEach((l) => {
    let percentage = 0;
    const data = listStats.get(l.callListId);
    switch (data?.type) {
      case "TextingListStats":
        percentage = ((data.payload.textDelivered + data.payload.textFailed) / data.payload.textTotal) * 100;
        break;

      case "BroadcastListStats":
      case "LinkbackListStats":
        percentage =
          data.payload.countTotalCalled +
          data.payload.countTotalFailed +
          (data.payload.countTotalNoAnswer / data.payload.countTotal) * 100;
        break;
    }

    allListsProgress.push({ listName: l.originalFileName, progress: percentage });
  });

  //============
  // UI Elements
  //============
  if (lists.length && selectedListId && listStats.get(selectedListId)) {
    const selectedListStats = listStats.get(selectedListId);
    const [selectedList] = findList(selectedListId);
    return (
      <div className="p-5 h-full w-full flex">
        <ContactListsChart lists={allListsProgress} />
        <div className="flex-1 h-full flex flex-col">
          <div className="flex justify-evenly">
            {lists.length > 1 && (
              <div className="select-none cursor-pointer" onClick={(e) => changeListSelection(-1)}>
                <span>{"<"}</span>
              </div>
            )}
            <div>{selectedList?.originalFileName}</div>
            {lists.length > 1 && (
              <div className="select-none cursor-pointer" onClick={(e) => changeListSelection(1)}>
                <span>{">"}</span>
              </div>
            )}
          </div>
          {
            //<div className="px-10">{selectedListStats && <StatsChart showTitle={false} stats={selectedListStats} />}</div>
          }
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-full flex items-center justify-center text-lightGray2">No contact lists for this campaign</div>
    );
  }
};
