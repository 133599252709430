import { UsageReportfilter } from "../interfaces/reports";
import { Config } from "../config";
import { pick } from "../utils/Conversion";

let user = JSON.parse(localStorage.getItem("user")!);


export type UsageReportData = {
    clientId: number;
    callListId: number;
    callListName: string;
    campaignId: number;
    campaignName: string;
    created: string;
    selected: boolean;
    calls: number;
    billedDuration: number;
    cost: number;
    rate: number;
}

export const fetchUsageReport = async (filter: UsageReportfilter, clientId: number) => {
  let fetchProgress = new URL(`${Config.platformwebapi_url}/api/reports/${clientId}/usagereport`);
  
  const response = await fetch(fetchProgress.toString(), {
    method: "POST",
    body: JSON.stringify(filter),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  const usageList: UsageReportData[] = [];
  const responseJson = await response.json();
  for (let x of responseJson) {
    const result = pick<UsageReportData>(x, {
        clientId: Number,
        created: String,
        callListId: Number,
        callListName: String,
        campaignId: Number,
        campaignName: String,
        selected: () => Boolean(false),
        calls: (x) => {return x > 0 ? x : 0},
        billedDuration: (x) => {return x > 0 ? x : 0},
        cost: (x) => {return x > 0 ? x : 0},
        rate: (x) => {return x > 0 ? x : 0}
    });

    usageList.push(result);
  }

  return usageList;
};