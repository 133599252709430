export type Manager = {
  id?: number;
  name: string;
  username: string;
  password: string;
  newPassword: string;
  email: string;
  clientId: number;
  active: boolean;
};

export enum EditorState {
  Inactive,
  Adding,
  Editing,
}

export type NewManagerForm = {
  name: string;
  username: string;
  email: string;
  newPassword: string;
  confirmPassword: string;
};

export const emptyManagerForm: NewManagerForm = {
  name: "",
  username: "",
  email: "",
  newPassword: "",
  confirmPassword: "",
};

export const emptyManager: Manager = {
  id: 0,
  active: false,
  clientId: 0,
  email: "",
  name: "",
  newPassword: "",
  password: "",
  username: "",
};
