import { fetchData, getRequestInfo } from "../apifunctions";
import { Config } from "../config";
import { InboundTextMessage } from "../interfaces/textmanager";
import { pick } from "../utils/Conversion";

let user = JSON.parse(localStorage.getItem("user")!);

export const fetchTextingMessages = async (clientId: number) => {
  let url = new URL(`${Config.platformwebapi_url}/api/inboundmessagetext`);
  let params = new URLSearchParams();
  let list: InboundTextMessage[] = [];

  const config = {
    headers: { Authorization: "Bearer " + user.bearerToken },
  };
  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetchData(url.toString(), config);

  if (response.ok) {
    const responseJson = await response.json();
    for (let v of responseJson) {
      const inboundMessage = pick<InboundTextMessage>(v, {
        id: String,
        phoneNumber: String,
        date: String,
        message: String,
        messageIntent: Number,
      });
      list.push(inboundMessage);
    }
  }
  return getRequestInfo<InboundTextMessage>(list, response);
};
