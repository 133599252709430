import { Config } from "../config";
import { CustomField } from "../interfaces/accountfields";
import { pick } from "../utils/Conversion";

const user = JSON.parse(localStorage.getItem("user")!);

export const postCustomField = async (customField: CustomField, clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/customfield/${customField.id}/edit`);
  const params = new URLSearchParams();

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(customField),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const fetchAccountFieldsList = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/customfield`);
  const params = new URLSearchParams();

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();
  let listCustomFields: CustomField[] = [];
  for (let v of responseJson) {
    const customField = pick<CustomField>(v, {
      id: Number,
      clientId: Number,
      customIndex: Number,
      name: String,
      messageEnabled: Boolean,
      used: Boolean,
    });

    listCustomFields.push(customField);
  }
  return listCustomFields;
};
