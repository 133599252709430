import React from "react";
import { fetchCallLists, fetchCallListCampaigns, postCloseCallList, postReuseCallList } from "../api/contactlist";
import { CallListFile, CallListRenewRequest, Campaign } from "../interfaces/contactlist";
import { Cell } from "../shared/table";
import { formatDate } from "../utils/DateUtils";
import { CallListStatus } from "../enums/contactlist";
import ConfirmButton from "../shared/buttons/confirm";
import { CallListRenew } from "./calllistrenew";
import { EntityTable } from "../shared/entitytable";
import * as Asserts from "../utils/Asserts";

type CallListAction = "Reuse" | "Close";

export const CallList = (props: { clientId: number }) => {
  const [callLists, setCallLists] = React.useState<CallListFile[]>([]);
  const [callListsCampaigns, setCallListsCampaigns] = React.useState<Campaign[]>([]);

  // UI State
  const [showEditFields, setShowEditFields] = React.useState<boolean>(false);
  const [callListSelected, setCallListSelected] = React.useState<CallListFile | null>(null);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(true);

  let errorMessageReusage = "";

  //====================
  // Retrieval functions
  //====================

  const loadCallList = React.useCallback(async () => {
    const data = await fetchCallLists(props.clientId);
    setCallLists(data);
    setShowLoadingSpinner(false);
  }, [props.clientId]);

  React.useEffect(() => {
    loadCallList();
  }, [loadCallList]);

  const reuseCallList = async (callListSelected: CallListFile) => {
    let editableData = await fetchCallListCampaigns(props.clientId);

    setCallListsCampaigns(editableData);
    setShowEditFields(true);
    setCallListSelected(callListSelected);
  };

  const closeCallList = async (callListSelected: CallListFile) => {
    const data = await postCloseCallList(callListSelected);

    if (data) {
      loadCallList();
      setCallListSelected(null);
    }
  };

  //===============
  // Handlers
  //===============

  const handleSaveReusedCallList = async (callListRequest: CallListRenewRequest) => {
    if (callListRequest) {
      const data = await postReuseCallList(callListRequest);

      if (data) {
        setCallListSelected(null);
        setShowEditFields(false);

        loadCallList();
      } else {
        errorMessageReusage =
          "Failed to create a new contact list for the given filters. If there are no eligible numbers to be added, a new contact list won't be created.";
      }
    }
  };

  //===============
  // UI Elements
  //===============

  const getCallListOptions = (callListSelected: CallListFile) => {
    let result: CallListAction[] = [];
    if (callListSelected.callListStatusId === CallListStatus.Closed) {
      result.push("Reuse");
    } else if (
      callListSelected.callListStatusId === CallListStatus.Open ||
      callListSelected.callListStatusId === CallListStatus.New ||
      callListSelected.callListStatusId === CallListStatus.Loaded
    ) {
      result.push("Close");
    }

    return result;
  };

  const contactListrow = (callListItem: CallListFile) => {
    return [
      <Cell>{callListItem.originalFileName}</Cell>,
      <Cell>{callListItem.callListStatusName}</Cell>,
      <Cell>{callListItem.campaignName}</Cell>,
      <Cell>{callListItem.originalCallListName}</Cell>,
      <Cell>{formatDate(new Date(callListItem.created))}</Cell>,
    ];
  };

  const reuseCallListForm = () => {
    return (
      <CallListRenew
        callListSelected={callListSelected!}
        callListsCampaigns={callListsCampaigns}
        errorMessage={errorMessageReusage}
        onClose={() => setShowEditFields(false)}
        onSave={handleSaveReusedCallList}
      />
    );
  };

  function handleAction(action: CallListAction, row: number) {
    switch (action) {
      case "Close":
        {
          const cl = callLists[row];
          if (cl !== undefined) {
            closeCallList(cl);
          } else {
            throw new Error("Attempted to close a non-existent call list!");
          }
        }
        break;
      case "Reuse":
        {
          const cl = callLists[row];
          if (cl !== undefined) {
            reuseCallList(cl);
          } else {
            throw new Error("Attempted to close a non-existent call list!");
          }
        }
        break;
      default:
        Asserts.assertNever(action);
    }
  }

  return (
    <div className="min-h-0 h-full flex flex-col py-5 px-24 bg-white">
      <div className="full flex flex-col">
        <div className="flex flew-row justify-end">
          <div className="w-full pb-7 font-bold text-3xl text-primaryDarkGrey">Contact List</div>
          <div className="flex justify-end w-40 h-10 whitespace-nowrap">
            <ConfirmButton onClick={() => (window.location.href = "/main/upload")}>Add New</ConfirmButton>
          </div>
        </div>
        <div className="flex flex-col pl-5">{showEditFields ? reuseCallListForm() : null}</div>
      </div>
      <div className="min-h-0 sm:max-h-[350px] xl:max-h-[680px]">
        <EntityTable<CallListFile, CallListAction>
          columns={["Name", "Status", "Campaign", "Base Call List", "Created"]}
          sortableColumns={[
            "originalFileName",
            "callListStatusName",
            "campaignName",
            "originalCallListName",
            "created",
          ]}
          title={"Contact Lists"}
          entities={callLists}
          rowFn={contactListrow}
          actionFn={getCallListOptions}
          actionHandler={handleAction}
          loading={showLoadingSpinner}
        />
      </div>
    </div>
  );
};
