import React from "react";
import * as ReactDOM from "react-dom";

const ToolTip = (props: { positioningElement: HTMLElement | null; label: string; show: boolean }) => {
  const fixedClasses =
    "absolute bg-darkGray2 text-white rounded-sm py-1 pl-3 pr-3 transition-opacity duration-250 z-20";
  let top = 0;
  let left = 0;

  const [classes, setClasses] = React.useState(fixedClasses + " opacity-0");
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setClasses(fixedClasses + " opacity-100");
  }, []);

  if (props.positioningElement && tooltipRef.current) {
    const bodyRect = document.body.getBoundingClientRect();
    let elemRect = props.positioningElement.getBoundingClientRect() as DOMRect;
    let toolTipRect = tooltipRef.current.getBoundingClientRect() as DOMRect;

    top = elemRect.top - bodyRect.top + elemRect.height / 2 - toolTipRect.height / 2;
    left = elemRect.left - bodyRect.left + 75;
  }

  return ReactDOM.createPortal(
    props.show && (
      <div ref={tooltipRef} className={classes} style={{ top: top, left: left }}>
        {props.label}
      </div>
    ),
    document.body
  );
};

export default ToolTip;
