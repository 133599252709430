export function formatDate(date: Date) {
  let month: string;
  let monthNum = date.getMonth() + 1;
  if (monthNum <= 9) {
    month = "0" + monthNum.toString();
  }
  month = monthNum.toString();

  let day: string;
  let dayNum = date.getDate();
  if (dayNum <= 9) {
    day = "0" + dayNum.toString();
  }
  day = dayNum.toString();

  return month + "/" + day + "/" + date.getFullYear();
}

export const getCurrentDate = (newDate: Date = new Date()) => {
  const separator = "/";
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${month < 10 ? `0${month}` : `${month}`}${separator}${date}${separator}${year}`;
};

export const editDateFormat = (selectedDate: string, currentDate: boolean = false) => {
  const separator = "/";
  const splittedDate = selectedDate.split("-");
  const year = splittedDate[0];
  const month = splittedDate[1];
  const day = splittedDate[2];

  return `${month}${separator}${day}${separator}${year}`;
};

export const getWeekDay = (data: string) => {
  const weekDaysArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  let returnValue = "";

  if (data.length === 7) {
    const mappedData = (data + "").split("").map((i) => Number(i));

    mappedData.forEach((n, index) => {
      if (+n === 1) {
        returnValue = returnValue + weekDaysArray[index] + ", ";
      }
    });
  }

  return returnValue.slice(0, -2);
};

export const getDateandTime = (date: Date) => {
  return `${getCurrentDate(date) + " " + date.toLocaleString("en-US").split(",")[1]}`;
};
