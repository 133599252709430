import { TABLEDATATYPE } from "../../campaigns/dialer/tableutils";

export enum CallerIdEnum {
  None,
  Hide,
  Single,
  Dynamic,
}

export const callerIdTypes = [
  { id: CallerIdEnum.Hide, name: "Hide Caller Id" },
  { id: CallerIdEnum.Single, name: "Single Caller Id" },
  { id: CallerIdEnum.Dynamic, name: "Dynamic Caller Id" },
];
export const campaignTimeTable = [
  {
    primaryKey: "mondayStart",
    label: "Mon",
    format: TABLEDATATYPE.String,
  },
  {
    primaryKey: "tuesdayStart",
    label: "Tue",
    format: TABLEDATATYPE.String,
  },
  {
    primaryKey: "wednesdayStart",
    label: "Wed",
    format: TABLEDATATYPE.String,
  },
  {
    primaryKey: "thursdayStart",
    label: "Thu",
    format: TABLEDATATYPE.String,
  },
  {
    primaryKey: "fridayStart",
    label: "Fri",
    format: TABLEDATATYPE.String,
  },
  {
    primaryKey: "saturdayStart",
    label: "Sat",
    format: TABLEDATATYPE.String,
  },
  {
    primaryKey: "sundayStart",
    label: "Sun",
    format: TABLEDATATYPE.String,
  },
];
