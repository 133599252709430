import * as React from "react";
import { ClientHubConnection } from "../../api/clienthub";
import { ButtonRecordingStatus, MessageStepType } from "../../enums/messages";
import { HubData, MessageStep, StepRecordingResponse } from "../../interfaces/messages";
import ConfirmButton from "../../shared/buttons/confirm";
import closeIcon from "../../images/close_icon.svg";
import { TextInput } from "../../shared/input";
import { dynamicPhoneMask, phoneFormatted, phoneMask } from "../../utils/PhoneUtils";
import { recordPhoneCallMade } from "../../api/messages";
import AnimatedButton from "../../shared/buttons/animated";

export const RecordPhoneCall = (props: { data: MessageStep; onSave: Function; onClose: Function }) => {
  const [recordingName, setRecordingName] = React.useState<string>("");
  const [callStatus, setCallStatus] = React.useState<ButtonRecordingStatus>(ButtonRecordingStatus.MakeCall);
  const [newPhoneNumber, setNewPhoneNumber] = React.useState<string>("");
  const [isCallMade, setCallIsMade] = React.useState<boolean>(false);
  const [messageStep, setMessageStep] = React.useState<MessageStep | null>(null);
  const [hubData, setHubData] = React.useState<HubData | null>(null);

  const errorMessage = "Call error";
  const isPhoneValid =
    newPhoneNumber &&
    newPhoneNumber.length === 14 &&
    newPhoneNumber.substr(0, 1) !== "0" &&
    newPhoneNumber.substr(0, 1) !== "1";

  const enableSavedButton = callStatus === ButtonRecordingStatus.Done;

  const fillFields = React.useCallback(() => {
    if (props.data) {
      setRecordingName(props.data.value);
    }
  }, [props.data]);

  const setNewCallStatus = React.useCallback(async () => {
    await ClientHubConnection("recordingStatusUpdated", "/hubs/message_recording", handleCallBack);
  }, []);

  React.useEffect(() => {
    setNewCallStatus();
  }, [setNewCallStatus]);

  React.useEffect(() => {
    fillFields();
  }, [fillFields]);

  React.useEffect(() => {
    renderingButton(hubData!);
  }, [hubData]);

  const isMakeCallButtonDisabled = () => {
    return isCallMade || !isPhoneValid;
  };

  const isSaveButtonDisabled = () => {
    return isCallMade || callStatus !== ButtonRecordingStatus.MakeCall || !isPhoneValid || !recordingName;
  };

  const getButtonName = (status: ButtonRecordingStatus) => {
    const names = ["Make Call", "Dialing...", "In Call...", "Recording...", "Listening...", "Done", "Call"];

    if (status) {
      return names[status - 1];
    } else return names[ButtonRecordingStatus.MakeCall - 1];
  };

  const handleInsertPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    let numberInserted = e.target.value.toString().replace(/ /gm, "");
    setNewPhoneNumber(dynamicPhoneMask(numberInserted));
  };

  const renderingButton = (hubInfo: HubData) => {
    let buttonStatus: ButtonRecordingStatus;

    if (hubInfo && hubInfo.data) {
      const callData: StepRecordingResponse = hubInfo.data;
      const status = callData.stage.toLowerCase();

      switch (status) {
        case "":
          buttonStatus = ButtonRecordingStatus.MakeCall;
          break;
        case "nothing":
          buttonStatus = ButtonRecordingStatus.Dialing;
          break;
        case "inmenu":
          buttonStatus = ButtonRecordingStatus.InCall;
          break;
        case "recording":
          buttonStatus = ButtonRecordingStatus.Recording;
          break;
        case "listening":
          buttonStatus = ButtonRecordingStatus.Listening;
          break;
        case "finished":
          buttonStatus = ButtonRecordingStatus.Done;
          break;
        default:
          buttonStatus = ButtonRecordingStatus.MakeCall;
      }

      if (buttonStatus === ButtonRecordingStatus.Done && callData.saved) {
        setTimeout(() => {
          buttonStatus = ButtonRecordingStatus.MakeCall;
          setCallStatus(buttonStatus);
          setCallIsMade(false);
        }, 3000);
      }

      if (buttonStatus === ButtonRecordingStatus.Done && !callData.saved) {
        buttonStatus = ButtonRecordingStatus.Error;
        setCallIsMade(false);
      }
      if (buttonStatus === ButtonRecordingStatus.Dialing && callData.callStatus.toLowerCase() === "terminated") {
        buttonStatus = ButtonRecordingStatus.Error;
        setCallIsMade(false);
      }
      setCallStatus(buttonStatus);
    }
  };

  const handleCallBack = (dt: HubData) => {
    if (dt && dt.data) {
      setHubData(dt);
      let updatedMessage = new MessageStep();
      updatedMessage = {
        ...updatedMessage,
        fileName: dt.data.fileName,
      };
      setMessageStep(updatedMessage);
    }
  };

  const handleClose = () => {
    props.onClose(true);
  };

  const handleChangeRecordingName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecordingName(e.target.value);
  };

  const handleMakeCall = async () => {
    setCallIsMade(true);
    let message = new MessageStep();

    if (props.data) {
      message = props.data;
    } else {
      message = {
        ...message,
        phoneNumber: Number(phoneFormatted(phoneMask(newPhoneNumber), true)),
        value: recordingName,
        messageStepType: MessageStepType.RecordedWithPhone,
      };
    }
    const messageData = await recordPhoneCallMade(message);
    setMessageStep(messageData!);
  };

  const handleSave = () => {
    let message = messageStep;
    message = {
      id: message?.id!,
      file: message?.file!,
      fileName: message?.fileName!,
      isDirty: message?.isDirty!,
      messageId: message?.messageId!,
      stepOrder: message?.stepOrder!,
      ttsFieldId: message?.ttsFieldId!,
      ttsModeId: message?.ttsModeId!,
      ttsVoiceId: message?.ttsVoiceId!,
      phoneNumber: Number(phoneFormatted(phoneMask(newPhoneNumber), true)),
      value: recordingName,
      messageStepType: MessageStepType.RecordedWithPhone,
    };
    props.onSave(message);
  };

  return (
    <div
      className="
            items-center justify-around
            flex w-full bg-white 
            shadow-container flex-wrap opacity-100 
            h-auto py-8 px-6 mt-6"
    >
      <div className="w-1/4">
        <TextInput
          placeholder="Type phone number"
          name="phoneNumber"
          title="Phone Number"
          required={true}
          maxLength={14}
          onChange={handleInsertPhoneNumber}
          value={newPhoneNumber}
        ></TextInput>
        {callStatus === ButtonRecordingStatus.Error && <span className="fieldError">{errorMessage}</span>}
      </div>
      <div></div>
      {enableSavedButton && (
        <div className="w-1/6 mt-6">
          <AnimatedButton text="Saved"></AnimatedButton>
        </div>
      )}
      {!enableSavedButton && (
        <div className="w-1/6 mt-6">
          <ConfirmButton disabled={isMakeCallButtonDisabled()} onClick={handleMakeCall}>
            {getButtonName(callStatus)}
          </ConfirmButton>
        </div>
      )}

      <div className="w-1/4">
        <TextInput
          placeholder="Input name for the audio"
          name="recordingName"
          title="Recording Name"
          value={recordingName}
          onChange={handleChangeRecordingName}
        ></TextInput>
      </div>
      <div className="w-1/6 mt-6">
        <ConfirmButton disabled={isSaveButtonDisabled()} onClick={handleSave}>
          Save
        </ConfirmButton>
      </div>
      <img alt="" className="cursor-pointer relative block -top-16" src={closeIcon} onClick={handleClose} />
    </div>
  );
};
