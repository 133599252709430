import * as React from "react";
import { TextInput } from "../shared/input";
import { CampaignSpeed, CampaignSpeedLinkback, updateCampaignSpeedLinkback } from "../api/livemonitoring";
import ConfirmButton from "../shared/buttons/confirm";

export type CallsRatioProps = {
    campaignSpeed: CampaignSpeed;
    callBack: () => void;
};

export const CallsRatio: React.FC<CallsRatioProps> = (props) => {
  let speed = props.campaignSpeed as CampaignSpeedLinkback;

  const [callRatio, setCallRatio] = React.useState<string>(speed.callRatio.toString());
  const [agentsCount, setAgentsCount] = React.useState<string>(speed.agentsCount.toString());
  const buttonEnabled = agentsCount.length > 0 && callRatio.length > 0;

  const handleRatioChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    let numberInserted = e.target.value.toString().replace(/\D/g, '');
    setCallRatio(numberInserted);
  };

  const handleAgentsCountChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    let numberInserted = e.target.value.toString().replace(/\D/g, '');
    setAgentsCount(numberInserted);
  };

  const handleAddNewPhoneNumber = async () => {
    speed.agentsCount = Number.parseInt(agentsCount);
    speed.callRatio = Number.parseInt(callRatio);
    const result = updateCampaignSpeedLinkback(speed);
    props.callBack();
   // if (requisitionStatus) {
   //   setNewPhoneNumber("");
   //   loadRestrictions();
   // }
  };

  return (

    <div className="border border-lightGray p-2">
     <TextInput title="Call Ratio" value={callRatio} className="p-2 basis-5/12 border border-lightGray focus:border-mediumGray transition-colors rounded-md h-10"  maxLength={3} onChange={handleRatioChanges}></TextInput>
     <TextInput title="Agents Count" value={agentsCount} className="p-2 basis-5/12 border border-lightGray focus:border-mediumGray transition-colors rounded-md h-10"  maxLength={3} onChange={handleAgentsCountChanges}></TextInput>
    <div className="w-32 pt-5 ">
      <ConfirmButton onClick={handleAddNewPhoneNumber} disabled={!buttonEnabled}>
                  OK
      </ConfirmButton>
    </div>
  </div>
 
  );
};