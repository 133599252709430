import { Config } from "../config";
import { CampaignBroadcast, CampaignLinkback, Campaign } from "../interfaces/contactlist";
import { RciBucket } from "../interfaces/dci";
import { Message } from "../interfaces/messages";
import { pick } from "../utils/Conversion";

const user = JSON.parse(localStorage.getItem("user")!);

export const saveBroadCastCampaign = async (campaign: CampaignBroadcast) => {
  const url = new URL(`${Config.platformwebapi_url}/api/campaign/broadcast/${campaign.campaignId}/edit`);
  let updatedCampaign = campaign;

  delete updatedCampaign.updated;
  delete updatedCampaign.campaignStarted;

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(updatedCampaign),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

export const saveLinkbackCampaing = async (campaign: CampaignLinkback) => {
  const url = new URL(`${Config.platformwebapi_url}/api/campaign/linkback/${campaign.campaignId}/edit`);
  let updatedCampaign = campaign;

  delete updatedCampaign.updated;
  delete updatedCampaign.campaignStarted;

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(updatedCampaign),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

export const deleteCampaign = async (campaign: Campaign) => {
  const url = new URL(`${Config.platformwebapi_url}/api/campaign/${campaign.campaignId}/delete`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(campaign),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};
export const getCampaignsBroadcast = async (campaignId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/campaign/broadcast/${campaignId}/item`);

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();
  let campaignBroadcast = pick<CampaignBroadcast>(responseJson, {
    campaignId: Number,
    name: String,
    clientId: Number,
    campaignStatusId: Number,
    campaignStatusName: String,
    campaignType: Number,
    campaignTypeName: String,
    updated: String,
    startTime: (s: string) => new Date(s),
    stopTime: (s: string) => new Date(s),
    startAutomatically: Boolean,
    stopAutomatically: Boolean,
    callerIdNumber: String,
    callerIdName: String,
    campaignStarted: Boolean,
    callerIdTypeId: Number,
    startTimeSpan: String,
    stopTimeSpan: String,
    startWeekdays: String,
    stopWeekdays: String,
    rciBucketId: Number,
    customerMessageId: Number,
    machineMessageId: Number,
    callsPerMinute: Number,
  });

  return campaignBroadcast;
};

export const getCampaignsLinkback = async (campaignId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/campaign/linkback/${campaignId}/item`);

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();
  let campaignLinkBack = pick<CampaignLinkback>(responseJson, {
    campaignId: Number,
    name: String,
    clientId: Number,
    campaignStatusId: Number,
    campaignStatusName: String,
    campaignType: Number,
    campaignTypeName: String,
    updated: String,
    startTime: (s: string) => new Date(s),
    stopTime: (s: string) => new Date(s),
    startAutomatically: Boolean,
    stopAutomatically: Boolean,
    callerIdNumber: String,
    callerIdName: String,
    campaignStarted: Boolean,
    callerIdTypeId: Number,
    startTimeSpan: String,
    stopTimeSpan: String,
    startWeekdays: String,
    stopWeekdays: String,
    rciBucketId: Number,
    callRatio: Number,
    linkbackNumber: String,
    agentCount: Number,
    customerMessageId: Number,
    machineMessageId: Number,
    agentMessageId: Number,
  });

  return campaignLinkBack;
};

export const getMessages = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/message`);
  const params = new URLSearchParams();

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();
  let messagesList: Message[] = [];

  for (let v of responseJson) {
    const message = pick<Message>(v, {
      id: Number,
      name: String,
      clientId: Number,
      messageStatusId: Number,
      messageStatusName: String,
      messageSteps: Array,
      campaigns: String,
    });
    messagesList.push(message);
  }

  return messagesList;
};

export const getRciBuckets = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/rci`);
  const params = new URLSearchParams();

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();
  let rciBucketList: RciBucket[] = [];

  for (let v of responseJson) {
    const rciBucket = pick<RciBucket>(v, {
      rciBucketId: Number,
      name: String,
      size: Number,
      dateAssigned: (s: string) => new Date(s),
      campaigns: String,
      rciBucketSizeName: String,
    });
    rciBucketList.push(rciBucket);
  }

  return rciBucketList;
};

export const fetchCampaignsList = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/campaign/dialer`);
  const params = new URLSearchParams();

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();
  let listCampaigns: Campaign[] = [];
  for (let v of responseJson) {
    const customField = pick<Campaign>(v, {
      campaignId: Number,
      name: String,
      clientId: Number,
      campaignStatusId: Number,
      campaignStatusName: String,
      campaignType: Number,
      campaignTypeName: String,
      updated: String,
      startTime: (s: string) => new Date(s),
      stopTime: (s: string) => new Date(s),
      startAutomatically: Boolean,
      stopAutomatically: Boolean,
      callerIdNumber: String,
      callerIdName: String,
      campaignStarted: Boolean,
      callerIdTypeId: Number,
      startTimeSpan: String,
      stopTimeSpan: String,
      startWeekdays: String,
      stopWeekdays: String,
      rciBucketId: Number,
    });

    listCampaigns.push(customField);
  }
  return listCampaigns;
};
