import { isString } from "./TypeValidation";

export enum SortedState {
    none,
    desc,
    asc,
}

export function sortBy<T>(key: keyof T, state: SortedState) {
    return function (a: T, b: T) {
        const aKey = a[key];
        const bKey = b[key];

        let aVal = isString(aKey) ? aKey.toLowerCase() : aKey;
        let bVal = isString(bKey) ? bKey.toLowerCase() : bKey;
        return state === SortedState.desc
            ? aVal > bVal
                ? -1
                : aVal < bVal
                    ? 1
                    : 0
            : state === SortedState.asc
                ? aVal < bVal
                    ? -1
                    : aVal > bVal
                        ? 1
                        : 0
                : 0;
    };
}
