import * as React from "react";
import { TextingCampaignStats, LinkbackCampaignStats, BroadcastCampaignStats } from "../api/livemonitoring";
export type CallCampaignOverviewProps = {};

export const TextingCampaignOverview: React.FC<TextingCampaignStats> = (props) => {
  return (
    <div className="flex bg-white text-primaryDarkGrey shadow-window rounded-lg border border-spaceGray overflow-hidden">
      <div className="flex flex-col flex-1 justify-center items-center bg-secondaryOffWhite font-bold border-r-1 border-spaceGray text-xl">
        Text Stats
      </div>
      <div className="flex flex-col items-center py-3 flex-1 border-r-1 border-spaceGray">
        <span>Sent</span>
        <div className="font-bold">{props.payload.textDelivered + props.payload.textFailed}</div>
      </div>
      <div className="flex flex-col items-center py-3 flex-1 border-r-1 border-spaceGray">
        <span>Remaining</span>
        <div className="font-bold">
          {props.payload.textTotal - (props.payload.textDelivered + props.payload.textFailed)}
        </div>
      </div>
      <div className="flex flex-col items-center py-3 flex-1">
        <span>Total</span>
        <div className="font-bold">{props.payload.textTotal}</div>
      </div>
    </div>
  );
};

/*
PLA 1213 - Campaign header is not being updated properly
PLA 1280 - Improve campaign summary header for dialer campaigns
*/

export const CallCampaignOverview: React.FC<LinkbackCampaignStats | BroadcastCampaignStats> = (props) => {
  return (
    <div className="flex bg-white text-primaryDarkGrey shadow-window rounded-lg border border-spaceGray overflow-hidden">
      <div className="flex flex-col flex-1 justify-center items-center bg-secondaryOffWhite font-bold border-r-1 border-spaceGray text-xl">
        Call Stats
      </div>
      <div className="flex flex-col items-center py-3 flex-1 border-r-1 border-spaceGray">
        <span>Called</span>
        <div className="font-bold">{props.payload.countTotalCalled}</div>
      </div>
      <div className="flex flex-col items-center py-3 flex-1 border-r-1 border-spaceGray">
        <span>Remaining</span>
        <div className="font-bold">{props.payload.countTotal - props.payload.countTotalCalled}</div>
      </div>
      <div className="flex flex-col items-center py-3 flex-1">
        <span>Total</span>
        <div className="font-bold">{props.payload.countTotal}</div>
      </div>
    </div>
  );
};
