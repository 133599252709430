import * as React from "react";
import { ReactComponent as CloseIcon } from "../../../../images/close_black_24dp.svg";

export type FilterCardProps = {
  onClose: () => void;
  onClick: () => void;
};

export const FilterCard: React.FC<FilterCardProps> = (props) => {
  function handleClose(e: React.MouseEvent<HTMLSpanElement>) {
    e.stopPropagation();
    props.onClose();
  }

  return (
    <div
      className="bg-white border border-spaceGray rounded-lg shadow-window p-2 text-primaryDarkGrey text-sm"
      onClick={props.onClick}
    >
      <div onClick={handleClose}>
        <CloseIcon className="fill-primaryPurple float-right cursor-pointer hover:shadow-window transition-shadow rounded-full" />
      </div>
      {props.children}
    </div>
  );
};
