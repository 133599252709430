import React from "react";
import { fetchAccountFieldsList, postCustomField } from "../api/accountfields";
import { CustomField } from "../interfaces/accountfields";
import { CheckboxInput, TextInput } from "../shared/input";
import CancelButton from "../shared/buttons/cancel";
import ConfirmButton from "../shared/buttons/confirm";
import { EntityTable, stringToCell } from "../shared/entitytable";
import { assertNever } from "../utils/Asserts";

type CustomFieldAction = "Edit";

export const AccountFields = (props: { clientId: number }) => {
  //====================
  // Hooks
  //====================

  // Api
  const [accountFieldsList, setAccountFieldsList] = React.useState<CustomField[]>([]);

  // UI State
  const [hideFormFields, setHideFormFields] = React.useState<boolean>(true);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(true);

  // Inserted Values
  const [fieldName, setFieldName] = React.useState<string>("");
  const [fieldIsAvailable, setFieldIsAvailable] = React.useState<boolean>(false);

  // User Actions
  const [selectedAccountField, setSelectedAccountField] = React.useState<CustomField | null>(null);

  const loadCustomFields = React.useCallback(async () => {
    const data = await fetchAccountFieldsList(props.clientId);
    setAccountFieldsList(data);
    setShowLoadingSpinner(false);
  }, [props.clientId]);

  React.useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  //====================
  // API Functions
  //====================

  const saveAccountField = async () => {
    let accountFieldFound;
    let customFieldId = 0;
    let isFieldUsed = false;

    if (selectedAccountField) {
      accountFieldFound = accountFieldsList.find((field) => +field.id === +selectedAccountField.id);
      customFieldId = !!accountFieldFound ? accountFieldFound.id : 0;

      isFieldUsed = !!selectedAccountField ? selectedAccountField.used : false;
    }

    const customFieldObj: CustomField = {
      id: customFieldId,
      clientId: props.clientId,
      customIndex: 0,
      name: fieldName,
      messageEnabled: fieldIsAvailable ? true : false,
      used: isFieldUsed,
    };

    const requisitionStatus = await postCustomField(customFieldObj, props.clientId);

    if (requisitionStatus.ok) {
      setHideFormFields(true);
      setSelectedAccountField(null);
      setFieldName("");
      setFieldIsAvailable(false);
      loadCustomFields();
    }
  };

  //===============
  // Event handlers
  //===============

  const handleAvailabilitySet = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldIsAvailable(!fieldIsAvailable);
  };

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldName(e.target.value);
  };

  //===============
  // Actions
  //===============

  const editAccountField = (accountField: CustomField) => {
    setHideFormFields(false);
    if (accountField) {
      setFieldName(accountField.name);
      setSelectedAccountField(accountField);
      setFieldIsAvailable(accountField.messageEnabled);
    }
  };

  const insertAccountField = () => {
    if (accountFieldsList) {
      const hideForm = accountFieldsList.length < 50 ? false : true;
      setHideFormFields(hideForm);
    }
  };

  const cancel = () => {
    setHideFormFields(true);
    setFieldName("");
    setFieldIsAvailable(false);
  };

  //===============
  // UI Elements
  //===============

  function handleRowAction(action: CustomFieldAction, rowNum: number) {
    switch (action) {
      case "Edit":
        const fieldSelected = accountFieldsList[rowNum];
        if (fieldSelected) {
          editAccountField(fieldSelected);
        }
        break;

      default:
        assertNever(action);
    }
  }

  return (
    <div className="h-full bg-white flex flex-col py-5 px-24">
      <div className="flex flex-col">
        <div className="flex flew-row justify-end">
          <div className="w-full pb-7 font-bold text-3xl text-primaryDarkGrey">Account Fields</div>
          <div className="flex justify-end w-40 h-10 whitespace-nowrap">
            {hideFormFields ? <ConfirmButton onClick={() => insertAccountField()}>Add New</ConfirmButton> : null}
          </div>
        </div>

        {!hideFormFields ? (
          <div>
            <div className="flex gap-10">
              <div className="flex flex-col w-2/5">
                <TextInput
                  value={fieldName}
                  onChange={handleAccountNameChange}
                  id="accountFieldName"
                  title="Account Field Value"
                  placeholder="Type account field value"
                />
                <div className="my-5 flex gap-3 justify-around">
                  <ConfirmButton disabled={!fieldName} onClick={() => saveAccountField()}>
                    Save
                  </ConfirmButton>
                  <CancelButton onClick={() => cancel()}>Cancel</CancelButton>
                </div>
              </div>
              <div className="">
                <CheckboxInput
                  checked={fieldIsAvailable}
                  roundedslider={true}
                  onChange={handleAvailabilitySet}
                  title="Available for Messages"
                  sliderlabel={fieldIsAvailable ? "ON" : "OFF"}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="sm:max-h-[350px] xl:max-h-[680px]">
          <EntityTable<CustomField, CustomFieldAction>
            title="Account Fields"
            sortableColumns={["name", "messageEnabled", "used"]}
            columns={["Name", "Available", "In Use"]}
            entities={accountFieldsList}
            rowFn={(f) => stringToCell([f.name, f.messageEnabled ? "Yes" : "No", f.used ? "Yes" : "No"])}
            actionFn={() => ["Edit"]}
            actionHandler={handleRowAction}
            loading={showLoadingSpinner}
          />
        </div>
      </div>
    </div>
  );
};
