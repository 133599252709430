import * as React from "react";
import { fetchCurrentManager, updateManager } from "../api/manager";
import { Manager } from "../interfaces/manager";
import ConfirmButton from "../shared/buttons/confirm";
import { PasswordInput } from "../shared/passwordinput";
import { validEmail } from "../utils/Validation";

type CurrentManagerForm = {
  name: string;
  username: string;
  email: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
};

export const CurrentManagerDetails = (props: { clientId: number; saveCallback: () => void }) => {
  //======
  // Hooks
  //======

  const [manager, setManager] = React.useState<Manager | undefined>(undefined);
  const [formData, setCurrentManagerFormData] = React.useState<CurrentManagerForm>({
    name: "",
    username: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showExistingPassword, setShowExistingPassword] = React.useState(false);

  React.useEffect(() => {
    retrieveCurrentManager();
  }, []);

  //====================
  // Retrieval functions
  //====================

  async function retrieveCurrentManager() {
    const manager = await fetchCurrentManager();
    setManager(manager);
    setCurrentManagerFormData({ ...manager, confirmPassword: "" });
  }

  //========
  // UI Data
  //========
  const emailIsValid = validEmail(formData.email);
  const passwordsMatch = formData.newPassword === formData.confirmPassword;
  const formIsValid = emailIsValid && passwordsMatch && formData.password && formData.name && formData.username;

  //===============
  // Event handlers
  //===============

  async function handleSaveClicked(e: React.MouseEvent) {
    e.preventDefault();
    if (manager !== undefined) {
      await updateManager({ ...manager, ...formData });
      await retrieveCurrentManager();
      props.saveCallback();
    }
  }

  return (
    <form
      style={{
        display: "grid",
        gridGap: "20px",
        justifyContent: "center",
        gridTemplateColumns: "auto auto",
      }}
    >
      <fieldset style={{ width: "100%" }}>
        <label className="text-lg font-normal">Name</label>
        <input
          value={formData.name}
          onChange={(e) =>
            setCurrentManagerFormData({
              ...formData,
              name: e.target.value,
            })
          }
          type="text"
          className="border border-spaceGray shadow-none h-10 w-full"
        />
      </fieldset>
      <fieldset style={{ width: "100%" }}>
        <label className="text-lg font-normal">Username</label>
        <input
          value={formData.username}
          onChange={(e) => {
            setCurrentManagerFormData({
              ...formData,
              username: e.target.value,
            });
          }}
          type="text"
          className="border border-spaceGray shadow-none h-10 w-full"
        />
      </fieldset>
      <fieldset style={{ width: "100%", gridColumn: "1/3", marginBottom: "30px" }}>
        <label className="text-lg font-normal">Email</label>
        <input
          value={formData.email}
          onChange={(e) => {
            setCurrentManagerFormData({
              ...formData,
              email: e.target.value,
            });
          }}
          type="text"
          className="border border-spaceGray shadow-none h-10 w-full"
        />
        {!emailIsValid && <div>Please enter a valid email</div>}
      </fieldset>
      <fieldset style={{ width: "100%", gridColumn: "1/3" }}>
        <label className="text-lg font-normal">Password</label>
        <PasswordInput
          showPassword={showExistingPassword}
          showHideAction={() => {
            setShowExistingPassword(!showExistingPassword);
          }}
          value={formData.password}
          onChange={(e) =>
            setCurrentManagerFormData({
              ...formData,
              password: e.target.value,
            })
          }
        />
      </fieldset>
      <fieldset style={{ width: "100%", gridColumn: "1/3" }}>
        <label className="text-lg font-normal">New Password</label>
        <PasswordInput
          showPassword={showNewPassword}
          showHideAction={() => {
            setShowNewPassword(!showNewPassword);
          }}
          value={formData.newPassword}
          onChange={(e) =>
            setCurrentManagerFormData({
              ...formData,
              newPassword: e.target.value,
            })
          }
        />
      </fieldset>
      <fieldset style={{ width: "100%", gridColumn: "1/3" }}>
        <label className="text-lg font-normal">Confirm Password</label>
        <PasswordInput
          showPassword={showNewPassword}
          showHideAction={() => {
            setShowNewPassword(!showNewPassword);
          }}
          value={formData.confirmPassword}
          onChange={(e) =>
            setCurrentManagerFormData({
              ...formData,
              confirmPassword: e.target.value,
            })
          }
        />
        {!passwordsMatch && <div>The password does not match.</div>}
      </fieldset>
      <ConfirmButton disabled={!formIsValid} onClick={(e) => handleSaveClicked(e)}>
        Save
      </ConfirmButton>
    </form>
  );
};
