import * as React from "react";
import { getCampaigns } from "../../../api/upload";
import { UploadRestrictionsEnum } from "../../../enums/upload";
import { CallListMapItem, Campaign } from "../../../interfaces/contactlist";
import { CallListMap } from "../../../interfaces/upload";
import { CheckboxInput, DateInput, SelectInput, TextInput } from "../../../shared/input";
import { editDateFormat, getCurrentDate } from "../../../utils/DateUtils";

export const UploadFileComponent = (props: {
  clientId: number;
  callListMap: CallListMapItem[];
  delimiterSelected: string;
  uploadReady: Function;
}) => {
  const [contactListName, setContactListName] = React.useState<string>("");
  const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);
  const [campaignSelected, setCampaignSelected] = React.useState<number>(0);
  const [selectedDate, setSelectedDate] = React.useState<string>("");
  const [campaignFilter, setCampaignFilter] = React.useState<number>(0);
  const [callListFilter, setCallListFilter] = React.useState<number>(1);

  const infoMessageSelection = !!!campaignSelected ? "A campaign selection is required" : "";
  const infoMessageName = !!!contactListName ? "A name is required for the contact list" : "";
  const enableSaving = !!contactListName && !!campaignSelected;

  const callListMap = props.callListMap;
  const delimiterSelected = props.delimiterSelected;
  const uploadReady = props.uploadReady;

  //============
  // API Functions
  //============

  //TODO: This has a very large number of dependencies. Are they really all needed here?
  const prepareData = React.useCallback(
    (dataReady: boolean) => {
      const getCallListFilter = () => {
        return !callListFilter
          ? !campaignFilter
            ? UploadRestrictionsEnum.NoRestriction
            : UploadRestrictionsEnum.UniqueTimespan
          : UploadRestrictionsEnum.UniqueCalllist;
      };

      if (dataReady) {
        let callListData: CallListMap = {
          callListName: contactListName,
          campaignId: campaignSelected,
          delimiter: delimiterSelected,
          callListMapList: callListMap,
          restriction: {
            restrictionType: getCallListFilter(),
            pastXDays: new Date(selectedDate),
          },
        };
        uploadReady(dataReady, callListData);
      } else {
        uploadReady(dataReady, null);
      }
    },
    [
      callListFilter,
      campaignFilter,
      contactListName,
      campaignSelected,
      delimiterSelected,
      callListMap,
      selectedDate,
      uploadReady,
    ]
  );

  const loadCampaigns = React.useCallback(async () => {
    const data = await getCampaigns(props.clientId);
    if (data) {
      setCampaigns(data);
    }
  }, [props.clientId]);

  React.useEffect(() => {
    loadCampaigns();
    setSelectedDate(getCurrentDate());
  }, [loadCampaigns]);

  React.useEffect(() => {
    prepareData(enableSaving);
  }, [enableSaving, prepareData]);

  //============
  // Utils
  //============

  const getOptions = (list: Campaign[]) => {
    let array = [new Option("", "0")];
    list?.forEach((campaign) => {
      array.push(new Option(campaign.name, campaign.campaignId.toString()));
    });
    return array;
  };

  //============
  // Handlers
  //============

  const hadleAddContactListName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setContactListName(value);
  };

  const handleSelectCampaign = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = +e.target.value;
    setCampaignSelected(value);
  };

  const handleDateSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(editDateFormat(e.target.value));
  };

  const handleChangeFilter = (
    filterCallList: number,
    filterCampaign: number,
    filteringType: UploadRestrictionsEnum
  ) => {
    let updatedFilterCallList = filterCallList;
    let updatedFilterCampaign = filterCampaign;

    if (filteringType === UploadRestrictionsEnum.UniqueCalllist) {
      updatedFilterCallList = filterCallList ? 0 : 1;
      setCallListFilter(updatedFilterCallList);
    } else if (filteringType === UploadRestrictionsEnum.UniqueTimespan) {
      updatedFilterCampaign = filterCampaign ? 0 : 1;
      setCampaignFilter(updatedFilterCampaign);
    }

    console.log("duplicate phone numbers? ", updatedFilterCallList);
    console.log("phone numbers that have been...?", updatedFilterCampaign);
  };
  //============
  // UI Components
  //============
  return (
    <div className="w-full">
      <div className="w-125 ml-auto mr-auto h-full sm:h-[250px] sm:overflow-auto">
        <div className="w-full">
          <TextInput
            title="Contact List Name"
            placeholder="Name your contact list"
            value={contactListName}
            onChange={hadleAddContactListName}
          />
          <span className="text-statusDisabled">{infoMessageName}</span>
        </div>
        <div className="w-full mt-5">
          <SelectInput title="Campaign" onChange={handleSelectCampaign} options={getOptions(campaigns)} />
          <span className="text-statusDisabled">{infoMessageSelection}</span>
        </div>
        <span className="border-l-darkGray2 pt-7 block"> Filter Out </span>
        <div className="w-full">
          <CheckboxInput
            checked={callListFilter === 1}
            onChange={() => handleChangeFilter(callListFilter, campaignFilter, UploadRestrictionsEnum.UniqueCalllist)}
            sliderlabel="Duplicate phone numbers already found on this contact list."
            roundedslider={false}
          />
        </div>
        <div className="w-full">
          <CheckboxInput
            checked={campaignFilter === 1}
            onChange={() => handleChangeFilter(callListFilter, campaignFilter, UploadRestrictionsEnum.UniqueTimespan)}
            sliderlabel="Phone numbers that have been called since the date below."
            roundedslider={false}
          />
        </div>
        <div className="w-full flex mt-7">
          <div className="w-1/4">
            <TextInput readOnly={true} value={selectedDate} onChange={handleDateSelection} title="Choose Date" />
          </div>
          <div className="w-7 mt-4 p-0">
            <DateInput onChange={handleDateSelection} />
          </div>
        </div>
      </div>
    </div>
  );
};
