import * as React from "react";
import * as Asserts from "../../utils/Asserts";
import {
  fetchCampaignsList,
  getCampaignsBroadcast,
  getCampaignsLinkback,
  deleteCampaign,
} from "../../api/dialercampaign";
import { CampaignType } from "../../api/livemonitoring";
import { Campaign, CampaignBroadcast, CampaignLinkback } from "../../interfaces/contactlist";
import { BroadcastEditor } from "./broadcasteditor";
import { LinkbackEditor } from "./linkbackeditor";
import ConfirmButton from "../../shared/buttons/confirm";
import { EntityTable, stringToCell } from "../../shared/entitytable";
import { Modal } from "../../shared/modal";
import { CampaignCreator } from "./selector";
import moment from "moment";
import { getWeekDay } from "../../utils/DateUtils";

type DialerCampaignActions = "Edit" | "Delete";

export const DialerCampaigns = (props: { clientId: number }) => {
  //======
  // Hooks
  //======

  // Back end
  const [campaignItems, setCampaignItems] = React.useState<Campaign[]>([]);

  // UI state
  const [showconfirmDeletionModal, setShowConfirmDeletionModal] = React.useState<boolean>(false);
  const [showCampaignCreator, setShowCampaignCreator] = React.useState<boolean>(false);
  const [showCampaignForm, setShowCampaignForm] = React.useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(true);
  // User selection
  const [newCampaignType, setNewCampaignType] = React.useState<CampaignType | undefined>(undefined);
  const [campaignSelected, setCampaignSelected] = React.useState<Campaign | undefined>(undefined);
  const [campaignLinkback, setCampaignLinkback] = React.useState<CampaignLinkback | undefined>(undefined);
  const [campaignBroadcast, setCampaignBroadcast] = React.useState<CampaignBroadcast | undefined>(undefined);

  //==============
  // API Functions
  //==============
  //
  const loadCampaigns = React.useCallback(async () => {
    const data = await fetchCampaignsList(props.clientId);
    setCampaignItems(data);
    setShowLoadingSpinner(false);
  }, [props.clientId]);

  React.useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const editCampaign = async (campaign: Campaign) => {
    if (campaign.campaignType === CampaignType.Broadcast) {
      const data = await getCampaignsBroadcast(campaign.campaignId);
      setCampaignBroadcast(data);
    } else if (campaign.campaignType === CampaignType.Linkback) {
      const data = await getCampaignsLinkback(campaign.campaignId);
      setCampaignLinkback(data);
    }
    setShowCampaignForm(true);
  };

  const prepareToDelete = (campaign: Campaign) => {
    setCampaignSelected(campaign);
    setShowConfirmDeletionModal(true);
  };

  const confirmDeleteCampaign = async (campaign: Campaign | undefined) => {
    if (campaign !== undefined) {
      const result = await deleteCampaign(campaign);
      if (result) {
        setShowConfirmDeletionModal(false);
        loadCampaigns();
      }
    } else {
      throw Error("Attempting to delete a non-existent campaign!");
    }
  };

  //============
  // Handlers
  //============

  const resetCampaignData = () => {
    setCampaignBroadcast(undefined);
    setCampaignLinkback(undefined);
    setNewCampaignType(undefined);
  };

  const handleAddNewCampaign = () => {
    setShowCampaignCreator(true);
  };

  const handleCampaignTypeSelected = (type: CampaignType) => {
    setShowCampaignCreator(false);
    setNewCampaignType(type);
    setShowCampaignForm(true);
  };

  const handleCampaignSaved = (isSaved: boolean) => {
    if (isSaved) {
      setShowCampaignForm(false);
      loadCampaigns();
      resetCampaignData();
    }
  };
  //============
  // UI Elements
  //============
  const confirmDeletionModal = (
    <Modal
      title={"Delete Campaign"}
      content={
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl text-center">Do you want to permanently delete this campaign?</p>
        </div>
      }
      buttons={
        <div className="h-full flex justify-evenly items-center">
          <button
            className="flex-1 ml-16 mr-8 px-10 py-2 
                       bg-white text-primaryPurple rounded-full 
                       shadow shadow-transparentGray"
            onClick={() => {
              confirmDeleteCampaign(campaignSelected);
            }}
          >
            Delete
          </button>
          <button
            className="flex-1 mr-16 ml-8 px-10 py-2 
                       bg-primaryPurple text-white border border-white rounded-full 
                       shadow shadow-transparentGray"
            onClick={() => setShowConfirmDeletionModal(false)}
          >
            Cancel
          </button>
        </div>
      }
      buttonClose={() => setShowConfirmDeletionModal(false)}
    />
  );

  // TODO: Verify it there is a need to use it, because is not being set evet (03/10/22)

  /*
    const editionErrorModal = (
      <Modal
        title={"Error"}
        content={
          <div className="flex flex-col items-center justify-center mt-10">
            <p className="mt-7 text-lg text-center px-7">Error retrieving campaign. Please, try again.</p>
          </div>
        }
        buttons={
          <div className="h-full flex justify-evenly items-center">
            <button
              className="flex-1 ml-16 mr-8 px-10 py-2 
                         bg-white text-primaryPurple rounded-full 
                         shadow shadow-transparentGray"
              onClick={() => setShowErrorModal(false)}
            >
              Ok
            </button>
          </div>
        }
        buttonClose={() => setShowErrorModal(false)}
      />
    );
    */

  function EditBroadcastCampaign(campaign?: CampaignBroadcast) {
    return (
      <BroadcastEditor
        clientId={props.clientId}
        campaign={campaign}
        campaignsList={campaignItems}
        onSave={handleCampaignSaved}
        onSaveCancel={() => {
          setShowCampaignForm(false);
          resetCampaignData();
        }}
      />
    );
  }

  function EditLinkbackCampaign(campaign?: CampaignLinkback) {
    return (
      <LinkbackEditor
        clientId={props.clientId}
        campaign={campaign}
        campaignsList={campaignItems}
        onSave={handleCampaignSaved}
        onSaveCancel={() => {
          setShowCampaignForm(false);
          resetCampaignData();
        }}
      />
    );
  }

  const CampaignForm = () => {
    if (newCampaignType) {
      switch (newCampaignType) {
        case CampaignType.Broadcast:
          return EditBroadcastCampaign();

        case CampaignType.Linkback:
          return EditLinkbackCampaign();

        default:
          throw Error("Attempting to create an unsupported campaign type!");
      }
    } else if (campaignBroadcast !== undefined) {
      return EditBroadcastCampaign(campaignBroadcast);
    } else if (campaignLinkback !== undefined) {
      return EditLinkbackCampaign(campaignLinkback);
    } else {
      throw Error("Campaign editor is in an invalid state!");
    }
  };

  function handleActions(action: DialerCampaignActions, rowNum: number) {
    switch (action) {
      case "Edit":
        {
          const c = campaignItems[rowNum];
          if (c) {
            editCampaign(c);
          } else {
            throw Error("Attempting to edit non-existent campaign!");
          }
        }
        break;
      case "Delete":
        {
          const c = campaignItems[rowNum];
          if (c) {
            prepareToDelete(c);
          } else {
            throw Error("Attempting to delete non-existent campaign!");
          }
        }
        break;
      default:
        Asserts.assertNever(action);
    }
  }

  if (!showCampaignCreator) {
    return (
      <>
        {showconfirmDeletionModal && confirmDeletionModal}
        <div className="min-h-0 h-full bg-white flex flex-col pt-5 py-24 px-24">
          <div className={"flex mb-5" + (showCampaignForm ? " flex-col" : " flex-row")}>
            <label className="w-full flex text-3xl text-primaryDarkGrey font-bold">Campaigns List</label>
            {showCampaignForm ? (
              CampaignForm()
            ) : (
              <div className="flex justify-end w-40 h-10 whitespace-nowrap">
                {!showCampaignForm && <ConfirmButton onClick={handleAddNewCampaign}>Add Campaign</ConfirmButton>}
              </div>
            )}
          </div>
          <div className="min-h-0 sm:max-h-[350px] xl:max-h-[680px]">
            <EntityTable<Campaign, DialerCampaignActions>
              title="Campaigns"
              sortableColumns={[
                "name",
                "campaignStatusName",
                "campaignTypeName",
                "startTime",
                "startWeekdays",
                "stopTime",
                "stopWeekdays",
                "updated",
              ]}
              columns={[
                "Name",
                "Status",
                "Type",
                "Auto Start Time (ET)",
                "Start Weekdays",
                "Auto Stop Time (ET)",
                "Stop Weekdays",
                "Updated",
              ]}
              entities={campaignItems}
              rowFn={(c) => {
                return stringToCell([
                  c.name,
                  c.campaignStatusName,
                  c.campaignTypeName,
                  moment(c.startTime).format("hh:mm a"),
                  getWeekDay(c.startWeekdays),
                  moment(c.stopTime).format("hh:mm a"),
                  getWeekDay(c.stopWeekdays),
                  c.updated ? moment(c.updated).format("YYYY-MM-DD") : "",
                ]);
              }}
              actionFn={() => ["Edit", "Delete"]}
              actionHandler={handleActions}
              loading = {showLoadingSpinner}
            />
          </div>
        </div>
      </>
    );
  } else {
    return <CampaignCreator onSelection={handleCampaignTypeSelected} />;
  }
};
