import * as React from "react";
import { campaignColor } from "./utils";
import { PauseButton } from "./Icons/PauseButton";
import { PlayButton } from "./Icons/PlayButton";
import { CampaignSpeed, CampaignStats, CampaignType, DisplayCampaign, LinkbackCampaignStats } from "../api/livemonitoring";
import { Window } from "../shared/window";
import { ProgressBar } from "../shared/progressbar";
import { Popover } from "react-tiny-popover";
import { CallsPerMinute } from "./callsperminute";
import { CallsRatio } from "./callsratio";

export type CampaignSummaryProps = {
  campaignButtonClick: () => void;
  stats: CampaignStats;
  campaign: DisplayCampaign;
  campaignSpeed: CampaignSpeed;
};

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return { x: centerX + radius * Math.cos(angleInRadians), y: centerY + radius * Math.sin(angleInRadians) };
}

function calculateArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  //prettier-ignore
  let d = ["M", start.x, start.y, 
           "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y, 
           "L", x, y, 
           "Z"];

  return d;
}

export const AnsweredByPersonDetails: React.FC<{ stats: LinkbackCampaignStats }> = (props) => {
  const data = props.stats.payload;
  const notTransfer = Math.ceil(((data.countPerson - data.countTransfer) / data.countPerson) * 100 );
  const transfer = Math.ceil((data.countTransfer / data.countPerson) * 100);
  return (
  <>
      <li className="indent-6 text-sm">
            <p>Not Transferred: {isNaN(notTransfer)? 0: notTransfer}%</p>
            <p>Transferred: {isNaN(transfer)? 0: transfer }%</p>
      </li>
  </>);
}

export const StatsChart: React.FC<{ showTitle: boolean; stats: CampaignStats }> = (props) => {
  // Radius of the pie chart
  const r = 85;
  switch (props.stats.type) {
    case "TextingCampaignStats": {
      const data = props.stats.payload;

      // If we have no stats, draw an empty grey circle.
      const total = data.textDelivered + data.textFailed + data.textRemaining;
      let chartSVGElement: JSX.Element | undefined = undefined;

      if (total === 0) {
        chartSVGElement = (
          <svg width={r * 2} height={r * 2}>
            <circle className="fill-lightGray" r={r} cx={r} cy={r} />
          </svg>
        );
      } else {
        // Otherwise, draw a standard pie chart.
        const delivered = (data.textDelivered / total) * 360;
        if (delivered === 360) {
          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <circle className="fill-primaryPurple" r={r} cx={r} cy={r} />
            </svg>
          );
        }
        const remaining = (data.textRemaining / total) * 360;
        if (remaining === 360) {
          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <circle className="fill-yellow" r={r} cx={r} cy={r} />
            </svg>
          );
        }
        const failed = (data.textFailed / total) * 360;
        if (failed === 360) {
          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <circle className="fill-redAlert" r={r} cx={r} cy={r} />
            </svg>
          );
        }

        let mark = 0;
        const d1 = calculateArc(r, r, r, 0, (mark += delivered));
        const d2 = calculateArc(r, r, r, mark, (mark += remaining));
        const d3 = calculateArc(r, r, r, mark, (mark += failed));

        if (chartSVGElement === undefined) {
          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <path className="fill-primaryPurple" d={d1.join(" ")} stroke="white" strokeWidth={2} />
              <path className="fill-yellow " d={d2.join(" ")} stroke="white" strokeWidth={2} />
              <path className="fill-redAlert" d={d3.join(" ")} stroke="white" strokeWidth={2} />
            </svg>
          );
        }
      }

      return (
        <div className="grid gap-5 auto-cols-max">
          {props.showTitle && <span className="font-bold justify-self-center text-xl">Text Results</span>}
          <div className="row-start-2">{chartSVGElement}</div>
          <ul className="row-start-2">
            <li className="flex items-center gap-2">
              <svg width="14" height="14" viewBox="0 0 32 32">
                <path className="fill-primaryPurple" transform="translate(16,16)" d="M-16,-16h32v32h-32Z" />
              </svg>
              <span>Texts Delievered {Math.round((data.textDelivered / (total ? total : 1)) * 100)}%</span>
            </li>
            <li className="flex items-center gap-2">
              <svg width="14" height="14" viewBox="0 0 32 32">
                <path className="fill-yellow" transform="translate(16,16)" d="M-16,-16h32v32h-32Z" />
              </svg>
              <span>Texts Remaining {Math.round((data.textRemaining / (total ? total : 1)) * 100)}%</span>
            </li>
            <li className="flex items-center gap-2">
              <svg width="14" height="14" viewBox="0 0 32 32">
                <path className="fill-redAlert" transform="translate(16,16)" d="M-16,-16h32v32h-32Z" />
              </svg>
              <span>Failed {Math.round((data.textFailed / (total ? total : 1)) * 100)}%</span>
            </li>
          </ul>
        </div>
      );
    }

    case "BroadcastCampaignStats":
    case "LinkbackCampaignStats": {
      const data = props.stats.payload;

      // If we have no stats, draw an empty grey circle.
      const total = data.countMachine + data.countPerson + data.countTotalNoAnswer + data.countTotalFailed;
      let chartSVGElement: JSX.Element | undefined = undefined;

      if (total === 0) {
        chartSVGElement = (
          <svg width={r * 2} height={r * 2}>
            <circle className="fill-lightGray" r={r} cx={r} cy={r} />
          </svg>
        );
      } else {
        // Otherwise, draw a standard pie chart.
        const personAnswered = (data.countPerson / total) * 360;
        if (personAnswered === 360) {
          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <circle className="fill-primaryPurple" r={r} cx={r} cy={r} />
            </svg>
          );
        }
        const machineAnswered = (data.countMachine / total) * 360;
        if (machineAnswered === 360) {
          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <circle className="fill-yellow" r={r} cx={r} cy={r} />
            </svg>
          );
        }
        const notAnswered = (data.countTotalNoAnswer / total) * 360;
        if (notAnswered === 360) {
          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <circle className="fill-mediumGray2" r={r} cx={r} cy={r} />
            </svg>
          );
        }
        const failed = (data.countTotalFailed / total) * 360;
        if (failed === 360) {
          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <circle className="fill-redAlert" r={r} cx={r} cy={r} />
            </svg>
          );
        }

        if (chartSVGElement === undefined) {
          let mark = 0;
          const d1 = calculateArc(r, r, r, 0, (mark += personAnswered));
          const d2 = calculateArc(r, r, r, mark, (mark += machineAnswered));
          const d3 = calculateArc(r, r, r, mark, (mark += notAnswered));
          const d4 = calculateArc(r, r, r, mark, (mark += failed));

          chartSVGElement = (
            <svg width={r * 2} height={r * 2}>
              <path className="fill-primaryPurple" d={d1.join(" ")} stroke="white" strokeWidth={2} />
              <path className="fill-yellow" d={d2.join(" ")} stroke="white" strokeWidth={2} />
              <path className="fill-mediumGray2" d={d3.join(" ")} stroke="white" strokeWidth={2} />
              <path className="fill-redAlert" d={d4.join(" ")} stroke="white" strokeWidth={2} />
            </svg>
          );
        }
      }

      return (
        <div className="grid gap-5 auto-cols-max">
          {props.showTitle && <span className="font-bold justify-self-center text-xl">Call Results</span>}
          <div className="row-start-2">{chartSVGElement}</div>
          <ul className="row-start-2">
            <li className="flex items-center gap-2">
              <svg width="14" height="14" viewBox="0 0 32 32">
                <path className="fill-primaryPurple" transform="translate(16,16)" d="M-16,-16h32v32h-32Z" />
              </svg>
              <span>Answered by Person {Math.round((data.countPerson / (total ? total : 1)) * 100)}%</span>
            </li>
            { props.stats.type === "LinkbackCampaignStats" && <AnsweredByPersonDetails stats={props.stats} /> }
            <li className="flex items-center gap-2">
              <svg width="14" height="14" viewBox="0 0 32 32">
                <path className="fill-yellow" transform="translate(16,16)" d="M-16,-16h32v32h-32Z" />
              </svg>
              <span>Answered by Machine {Math.round((data.countMachine / (total ? total : 1)) * 100)}%</span>
            </li>
            <li className="flex items-center gap-2">
              <svg width="14" height="14" viewBox="0 0 32 32">
                <path className="fill-mediumGray2" transform="translate(16,16)" d="M-16,-16h32v32h-32Z" />
              </svg>
              <span>Not Answered {Math.round((data.countTotalNoAnswer / (total ? total : 1)) * 100)}%</span>
            </li>
            <li className="flex items-center gap-2">
              <svg width="14" height="14" viewBox="0 0 32 32">
                <path className="fill-redAlert" transform="translate(16,16)" d="M-16,-16h32v32h-32Z" />
              </svg>
              <span>Failed {Math.round((data.countTotalFailed / (total ? total : 1)) * 100)}%</span>
            </li>
          </ul>
        </div>
      );
    }

    default:
      return null;
  }
};

export const CampaignSummary: React.FC<CampaignSummaryProps> = (props) => {
  let campaignDetailsWindowTitle = "Today's Calls";
  let campaignStatus = props.campaign.campaignStatusName;
  let campaignButton = null;

  const [showCallRatioPopover, setShowCallRatioPopover] = React.useState<boolean>(false);

  const closePopover = () => {
    setShowCallRatioPopover(false);
  };

  switch (props.campaign.campaignTypeName) {
    case "Texting":
      campaignDetailsWindowTitle = `${props.campaign.name}`;
      break;
  }

  switch (props.campaign.campaignStatusName) {
    case "Running":
    case "Starting":
      campaignButton = <PauseButton />;
      break;
    case "Stopping":
    case "Stopped":
      campaignButton = <PlayButton />;
      break;
  }

  let callSummaryLine;
  switch (props.stats.type) {
    case "LinkbackCampaignStats":
      callSummaryLine = (
        <div className="flex gap-5 text-lg">
          <span>
            Calls Transferred: <span className="font-bold">{props.stats.payload.countTransfer}</span>
          </span>
          <span>
            Call Length Avg: <span className="font-bold">{props.stats.payload.avgDuration}</span>
          </span>
          <span>
            After Transfer Avg: <span className="font-bold">{props.stats.payload.avgDurationTransfer}</span>
          </span>
        </div>
      );
      break;
    case "BroadcastCampaignStats":
      callSummaryLine = (
        <div className="flex gap-5 text-lg">
          <span>
            Call Length Avg: <span className="font-bold">{props.stats.payload.avgDuration}</span>
          </span>
        </div>
      );
      break;
    case "TextingCampaignStats":  
      callSummaryLine = (
        <div style={{ visibility: "hidden" }}>
          <span>No stats to display</span>
        </div>
      );
      break;
  }

  return (
    <Window
      header={
        <div
          className="flex items-center pl-7 font-bold"
          style={{ color: campaignColor(props.campaign.campaignStatusName) }}
        >
          <div className="flex-1 flex items-center">
            {campaignDetailsWindowTitle}
            <div className="detailsProgressBar">
              <div className="w-40 h-6 ml-5">
                <ProgressBar
                  bgColor={campaignColor(props.campaign.campaignStatusName)}
                  percentage={props.campaign.percentage}
                />
              </div>
            </div>
          </div>
          <div>
            <Popover
              isOpen={showCallRatioPopover}
              align="end"
              positions={["bottom"]}
              onClickOutside={() => setShowCallRatioPopover(false)}
              content={
                <div className="bg-white shadow-window rounded-md">
                  {props.campaign.campaignType === CampaignType.Broadcast ? 
                    <CallsPerMinute campaignSpeed={props.campaignSpeed} callBack={closePopover}></CallsPerMinute> : 
                    <CallsRatio campaignSpeed={props.campaignSpeed} callBack={closePopover}></CallsRatio>}
                </div>
              }
            >
              <div onClick={() => setShowCallRatioPopover(!showCallRatioPopover)}>
                <span>{props.campaign.campaignType === CampaignType.Broadcast ? 'Calls per Minute' : 'Call Ratio'}</span>
              </div>
             
            </Popover>
          </div>
          <div className="flex-1 flex gap-2 justify-end items-center">
            <span>{campaignStatus.toUpperCase()}</span>
            <div style={{ display: "flex" }} onClick={props.campaignButtonClick}>
              {campaignButton}
            </div>
          </div>
        </div>
      }
    >
      <div className="pt-2">
        <StatsChart showTitle={true} stats={props.stats} />
      </div>
      <div className="flex justify-end px-6">{callSummaryLine}</div>
    </Window>
  );
};
