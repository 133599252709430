import { Config } from "../config";
import { extractUserAuthInfo } from "../hooks/useClientInfo";

export const performLogin = async (loginData: { code: string; username: string; password: string }) => {
  let url = new URL(`${Config.platformwebapi_url}/api/security/login`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(loginData),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    const userJson = await response.json();
    return extractUserAuthInfo(userJson);
  }
};


export const isTokenExpired = async () => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/security/testtoken`);
  const response = await fetch(url.toString(), {
  method: "GET",
  headers: {
    Authorization: "Bearer " + user.bearerToken,
    "Content-Type": "application/json",
  },
  });

  return response.status === 401;
};
