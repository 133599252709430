import { Config } from "../config";
import { pick } from "../utils/Conversion";
let user = JSON.parse(localStorage.getItem("user")!);
export class State {
    stateId: number;
    name: string;
    abbreviation: string;

    constructor(stateId: number, name: string, abbreviation: string) {
        this.stateId = stateId;
        this.name = name;
        this.abbreviation = abbreviation;
    }
}

export const fetchStates = async () => {
    let url = new URL(`${Config.platformwebapi_url}/api/donotcall/states`);
    const response = await fetch(url.toString(), {
      headers: { Authorization: "Bearer " + user.bearerToken },
    });
  
    const responseJson = await response.json();
    let states: State[] = [];
    for (let v of responseJson) {
      const state = pick<State>(v, {
        stateId: Number,
        name: String,
        abbreviation: String,
      });
  
      states.push(state);
    }
    return states;
  };