import * as React from "react";
import * as Asserts from "../../../utils/Asserts";
import * as DefaultData from "../column_data";
import { ColumnFilter, ColumnID, DullData } from "../../../interfaces/reports";
import { FilterCard } from "./card";
import { FilterEditor } from "./editor";
import { Window } from "../../../shared/window";
import moment from "moment";

export const Filters = ({
  activeFilters,
  onFiltersChange,
  dullData,
}: {
  activeFilters: ColumnFilter[];
  onFiltersChange: (filters: ColumnFilter[]) => void;
  dullData: DullData
}) => {
  const [columnToFilter, setColumnToFilter] = React.useState<ColumnID | undefined>(undefined);

  function handleChooseColumnToFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    //TODO: Find a way to omit the as and narrow the string to a ColumnID properly
    setColumnToFilter(e.target.value as ColumnID);
  }

  function handleFilterApplied(f: ColumnFilter) {
    let newActiveFilters = activeFilters.slice();

    let idx = -1;
    newActiveFilters.forEach((activeFilter, index) => {
      if (activeFilter.columnId === f.columnId) {
        idx = index;
      }
    });

    if (idx >= 0) {
      newActiveFilters[idx] = f;
    } else {
      newActiveFilters.push(f);
    }

    onFiltersChange(newActiveFilters);
    setColumnToFilter(undefined);
  }

  function removeFilter(columnId: ColumnID) {
    let updatedFilters = activeFilters.filter((activeFilter) => {
      return activeFilter.columnId !== columnId;
    });
    onFiltersChange([...updatedFilters]);

    if (columnId === columnToFilter) {
      setColumnToFilter(undefined);
    }
  }

  function createCardElement(item: ColumnFilter): JSX.Element {
    let columnData = DefaultData.columns.get(item.columnId);
    if (!columnData) {
      throw new Error("Column data was undefined!");
    }

    const displayName = columnData.displayName;

    let fieldNameBaseStyle: React.CSSProperties = { fontWeight: "bold" };
    let detailsStyle: React.CSSProperties = { color: "#A9A9A9" };
    switch (item.type) {
      //prettier-ignore
      case "keyword": {
        return (
          <FilterCard onClick={() => setColumnToFilter(item.columnId) } onClose={() => removeFilter(item.columnId)}>
            <span style={fieldNameBaseStyle}>{displayName}</span>
            <p style={detailsStyle}> w/keyword <span style={{ whiteSpace: "nowrap" }}>{item.keyword}</span></p>
          </FilterCard>
        );
      }

      //prettier-ignore
      case "constrained-keyword": {
        return (
          <FilterCard onClick={() => setColumnToFilter(item.columnId) } onClose={() => removeFilter(item.columnId)}>
            <span style={fieldNameBaseStyle}>{displayName}</span> 
            <p style={detailsStyle}> w/key <span style={{ whiteSpace: "nowrap" }}>{item.choice}</span></p>
          </FilterCard>
        );
      }

      //prettier-ignore
      case "numeric-range": {
        return (
          <FilterCard onClick={() => setColumnToFilter(item.columnId) } onClose={() => removeFilter(item.columnId)}>
            <span style={fieldNameBaseStyle}>{displayName}</span>
            <p style={detailsStyle}> between <span style={{ whiteSpace: "nowrap" }}>{item.start} and {item.end}</span></p>
          </FilterCard>
        );
      }

      //prettier-ignore
      case "currency-range": {
        return (
          <FilterCard onClick={() => setColumnToFilter(item.columnId) } onClose={() => removeFilter(item.columnId)}>
            <span style={fieldNameBaseStyle}>{displayName}</span>
            <p style={detailsStyle}> between <span style={{ whiteSpace: "nowrap" }}> {item.start} and {item.end}</span></p>
          </FilterCard>
        );
      }

      //prettier-ignore
      case "date-range": {
        return (
          <FilterCard onClick={() => setColumnToFilter(item.columnId) } onClose={() => removeFilter(item.columnId)}>
            <span style={fieldNameBaseStyle}>{displayName}</span>
            <p style={detailsStyle}> between <span>{item.startDay} and {item.endDay}</span></p>
          </FilterCard>
        );
      }

      //prettier-ignore
      case "multiple-constrained-keyword": {
        return (
          <FilterCard onClick={() => setColumnToFilter(item.columnId) } onClose={() => removeFilter(item.columnId)}>
            <span style={fieldNameBaseStyle}>{displayName}</span> 
            <div style={{width: '100%', maxWidth: '200px'}}>
              <p style={detailsStyle}><span>{Array.from(item.choices.values()).join(", ")}</span></p>
            </div>
          </FilterCard>
        );
      }
      
      //prettier-ignore
      case "multiple-constrained-options": {
        return (
          <FilterCard onClick={() => setColumnToFilter(item.columnId) } onClose={() => removeFilter(item.columnId)}>
            <span style={fieldNameBaseStyle}>{displayName}</span> 
            <div style={{width: '100%', maxWidth: '200px'}}>
              <p style={detailsStyle}><span>{Array.from(item.choices.values()).join(", ")}</span></p>
            </div>
          </FilterCard>
        );
      }

      default:
        return Asserts.assertNever(item);
    }
  }

  const activeFilterCards = activeFilters.map(createCardElement);

  let editor = null;

  if (columnToFilter) {
    let columnData = DefaultData.columns.get(columnToFilter);
    if (!columnData) {
      throw new Error("Column data was undefined!");
    }

    const colFilterType = columnData.filterType;

    let editorState: Partial<ColumnFilter> = { type: colFilterType };
    if (!editorState) {
      throw new Error("Editor state was undefined!");
    }

    editorState.columnId = columnToFilter;

    activeFilters.forEach((activeFilter) => {
      if (activeFilter.columnId === columnToFilter) {
        editorState = activeFilter;
      }
    });

    if (editorState.type === "date-range") {
      editorState.startDay = editorState.startDay ? editorState.startDay : moment(new Date()).format("YYYY-MM-DD");
      editorState.endDay = editorState.endDay ? editorState.endDay : moment(new Date()).format("YYYY-MM-DD");
      editorState.startTime = editorState.startTime ? editorState.startTime : "00:00:00";
      editorState.endTime = editorState.endTime ? editorState.endTime : "23:59:59";
    }

    if (editorState.type === "multiple-constrained-keyword") {
      editorState.options = dullData.campaigns;
    }

    if (editorState.type === "multiple-constrained-options" && editorState?.columnId === "callDisposition") {
      editorState.options = dullData.callDisposition;
    }

    if (editorState.type === "multiple-constrained-options" && editorState?.columnId === "transferred") {
      editorState.options = dullData.transferred;
    }

     editor = (
      <Window title={columnData.displayName}>
        <FilterEditor initialFilterState={editorState} onApply={handleFilterApplied} />
      </Window>
    );
  }

  return (
    <>
      <Window title="Add Filter">
        <div className="flex flex-col gap-3 mb-3">{activeFilterCards}</div>
        <select className="border h-10 w-full font-normal mb-8" onChange={handleChooseColumnToFilter}>
          {Array.from(DefaultData.columns.values())
            //TODO: Consider a more efficient approach to this. A map/set could work well here.
            .filter((item) => {
              let columnAlreadyFiltered = false;
              activeFilters.forEach((activeFilter) => {
                if (activeFilter.columnId === item.id) {
                  columnAlreadyFiltered = true;
                }
              });
              return !columnAlreadyFiltered;
            })
            .filter((item) => item.id !== "calllistName" && item.id !== "campaignName" && item.id !== "callerIdTypeName" && item.id !== "callTypeName")
            .map((item) => <option value={item.id}>{item.displayName}</option>)
            .concat([
              <option value="" disabled selected hidden>
                Choose filter...
              </option>,
            ])}
        </select>
      </Window>
      {editor}
    </>
  );
};
