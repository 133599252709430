import * as React from "react";
import { getMessages, saveLinkbackCampaing, getRciBuckets } from "../../../api/dialercampaign";
import { CampaignStatus } from "../../../enums/contactlist";
import { Modal } from "../../../shared/modal";
import { phoneFormatted, phoneMask, dynamicPhoneMask } from "../../../utils/PhoneUtils";
import { CampaignLinkback, Campaign } from "../../../interfaces/contactlist";
import { RciBucket } from "../../../interfaces/dci";
import { Message } from "../../../interfaces/messages";
import { campaignTimeTable, callerIdTypes, CallerIdEnum } from "../../../interfaces/dialercampaigns/misc";
import { CheckboxInput, NumberInput, SelectInput, TextInput, TimeInput } from "../../../shared/input";
import ConfirmButton from "../../../shared/buttons/confirm";
import CancelButton from "../../../shared/buttons/cancel";
import { ContentTable } from "../../../shared/contenttable";
import moment from "moment";

export type CampaignLinkbackForm = {
  name: string;
  status: string;
  callRation: number;
  agentsCount: number;
  defaultCallerIdName: string;
  defaultCallerIdNumber: string;
  linkbackPhoneNumber: string;
  callerId: number;
  dciBucketId: number;
  autoStartValue: string;
  autoStopValue: string;
  startWeekDay: Array<number>;
  stopWeekDay: Array<number>;
  customerMessageId: number;
  machineMessageId: number;
  agentMessageId: number;
};

const initialFormData: CampaignLinkbackForm = {
  name: "",
  status: "",
  callRation: 0,
  agentsCount: 0,
  defaultCallerIdName: "",
  defaultCallerIdNumber: "",
  linkbackPhoneNumber: "",
  callerId: CallerIdEnum.None,
  dciBucketId: 0,
  autoStartValue: "",
  autoStopValue: "",
  startWeekDay: [0, 0, 0, 0, 0, 0, 0],
  stopWeekDay: [0, 0, 0, 0, 0, 0, 0],
  customerMessageId: 0,
  machineMessageId: 0,
  agentMessageId: 0,
};

export const LinkbackEditor = (props: {
  clientId: number;
  campaign: CampaignLinkback | undefined;
  campaignsList: Campaign[];
  onSave: Function;
  onSaveCancel: Function;
}) => {
  const [formData, setCampaignFormData] = React.useState<CampaignLinkbackForm>(initialFormData);

  //======
  // Hooks
  //======

  // Back end
  const [dciBucketlist, setDciBucketList] = React.useState<RciBucket[]>([]);
  const [messageList, setMessageList] = React.useState<Message[]>([]);

  // UI state
  const [showConfirmSavingModal, setShowConfirmSavingModal] = React.useState<boolean>(false);

  // Fields check
  const disableBucketSelection = formData.callerId !== CallerIdEnum.Dynamic;
  const bucketRequiredInfo =
    formData.callerId && formData.callerId === CallerIdEnum.Dynamic && !formData.dciBucketId
      ? "A bucket must be used for Dynamic Caller ID."
      : null;

  const nameIsRequiredInfo = !!!formData.name ? "Name is Required" : null;
  const nameDuplicated =
    !props.campaign && formData.name && props.campaignsList.find((campaign) => campaign.name === formData.name)
      ? "Campaign name must be unique"
      : null;
  const callRatioInfo = !!!formData.callRation ? "The call ratio must be a number above 0." : null;
  const agentCountInfo = !!!formData.agentsCount ? "The agent count must be a number above 0." : null;
  const phoneNumberLinkbackRequiredInfo = !!!formData.linkbackPhoneNumber ? "A phone number is required" : null;
  const phoneNumberLinkbackError =
    !!formData.linkbackPhoneNumber && formData.linkbackPhoneNumber.length !== 14
      ? "A phone number must have 10 digits"
      : null;
  const phoneNumberDefaultRequiredInfo = !!!formData.defaultCallerIdNumber ? "A phone number is required" : null;
  const phoneNumberDefaultError =
    !!formData.defaultCallerIdNumber && formData.defaultCallerIdNumber.length !== 14
      ? "A phone number must have 10 digits"
      : null;

  const enableSaving =
    formData.name &&
    formData.callRation &&
    formData.agentsCount &&
    formData.defaultCallerIdNumber &&
    formData.defaultCallerIdNumber.length === 14 &&
    formData.linkbackPhoneNumber &&
    formData.linkbackPhoneNumber.length === 14 &&
    validateAutoDates(formData);

  // Create options for the field type select input

  let callerIdSelectOptions = [{ text: "Select..." }];
  let dcibucketSelectOptions = [{ text: "Select..." }];

  callerIdSelectOptions = callerIdSelectOptions.concat(
    callerIdTypes.map((c) => {
      return { value: c.id, text: c.name };
    })
  );

  dcibucketSelectOptions = dcibucketSelectOptions.concat(
    dciBucketlist.map((b) => {
      return { value: b.rciBucketId, text: b.name };
    })
  );
  //==============
  // API Functions
  //==============

  const getRciInfo = React.useCallback(async () => {
    const rciData = await getRciBuckets(props.clientId);
    setDciBucketList(rciData);
  }, [props.clientId]);

  const loadMessages = React.useCallback(async () => {
    const data = await getMessages(props.clientId);

    if (data) {
      setMessageList(data);
    }
  }, [props.clientId]);

  React.useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  React.useEffect(() => {
    getRciInfo();
  }, [getRciInfo]);

  React.useEffect(() => {
    if (props.campaign !== undefined) {
      setCampaignFormData({
        name: props.campaign.name,
        status: props.campaign.campaignStatusName,
        callRation: props.campaign.callRatio,
        agentsCount: props.campaign.agentCount,
        defaultCallerIdName: props.campaign.callerIdName,
        defaultCallerIdNumber: phoneMask(phoneFormatted(props.campaign.callerIdNumber, false), true),
        callerId: props.campaign.callerIdTypeId,
        linkbackPhoneNumber: phoneMask(phoneFormatted(props.campaign.linkbackNumber, false), true),
        dciBucketId: props.campaign.rciBucketId,
        agentMessageId: props.campaign.agentMessageId,
        customerMessageId: props.campaign.customerMessageId,
        machineMessageId: props.campaign.machineMessageId,
        autoStartValue: getCurrentTime(props.campaign.startTime),
        autoStopValue: getCurrentTime(props.campaign.stopTime),
        startWeekDay: (props.campaign.startWeekdays + "").split("").map((i) => Number(i)),
        stopWeekDay: (props.campaign.stopWeekdays + "").split("").map((i) => Number(i)),
      });
    } else {
      setCampaignFormData({
        ...initialFormData,
        autoStartValue: getCurrentTime(new Date()),
        autoStopValue: getCurrentTime(new Date()),
      });
    }
  }, [props.campaign]);

  const saveCampaignEdition = async () => {
    const linkbackCampaign: CampaignLinkback = {
      agentCount: formData.agentsCount,
      agentMessageId: formData.agentMessageId,
      callRatio: formData.callRation,
      callerIdName: formData.defaultCallerIdName,
      callerIdNumber: phoneFormatted(phoneMask(formData.defaultCallerIdNumber, false), true),
      callerIdTypeId: formData.callerId,
      campaignId: props.campaign ? props.campaign.campaignId : 0,
      campaignStatusId: props.campaign ? props.campaign.campaignStatusId : CampaignStatus.Stopped,
      campaignStatusName: props.campaign ? props.campaign.campaignStatusName : "",
      campaignType: props.campaign ? props.campaign.campaignType : 0,
      campaignTypeName: props.campaign ? props.campaign.campaignTypeName : "Linkback",
      clientId: props.clientId,
      customerMessageId: formData.customerMessageId,
      linkbackNumber: phoneFormatted(phoneMask(formData.linkbackPhoneNumber, false), true),
      machineMessageId: formData.machineMessageId,
      name: formData.name,
      startTime: configureTime(formData.autoStartValue),
      stopTime: configureTime(formData.autoStopValue),
      startAutomatically: formData.startWeekDay.find((day) => day === 1) ? true : false,
      stopAutomatically: formData.stopWeekDay.find((day) => day === 1) ? true : false,
      startTimeSpan: formData.autoStartValue,
      stopTimeSpan: formData.autoStopValue,
      startWeekdays: formData.startWeekDay.toString().split(",").join(""),
      stopWeekdays: formData.stopWeekDay.toString().split(",").join(""),
      rciBucketId: formData.dciBucketId,
      updated: undefined,
      campaignStarted: undefined,
    };
    const data = await saveLinkbackCampaing(linkbackCampaign);

    if (data) {
      props.onSave(true);
    }
  };

  const prepareToSave = () => {
    if (!formData.customerMessageId || !formData.machineMessageId || !formData.agentMessageId) {
      setShowConfirmSavingModal(true);
      return;
    } else {
      saveCampaignEdition();
    }
  };

  //======
  // Util Functions
  //======

  function configureTime (time: String) {
    const data = time.split(":").map(Number);
    let newDate: Date = new Date();

    newDate.setUTCHours(data[0], data[1]);
    return new Date(newDate);
  };

  const getCurrentTime = (date: Date) => {
    return moment(date).format("HH:mm");
  };

  function validateAutoDates (data: CampaignLinkbackForm) {
    if (data.startWeekDay.includes(1, 0) && data.autoStartValue.length === 0)
      return false;

    if (data.stopWeekDay.includes(1, 0) && data.autoStopValue.length === 0)
      return false;

    return true;
  }

  //======
  // Fields Selections
  //======

  const getStartWeekDays = (e: React.ChangeEvent<HTMLInputElement>, weekDay: number, rowNumber: number) => {
    const isChecked = e.target.checked;
    let updatedStartWeekDays = formData.startWeekDay;

    if (rowNumber === 0) {
      updatedStartWeekDays[weekDay] = isChecked ? 1 : 0;
    }
    return updatedStartWeekDays;
  };

  const getStopWeekDays = (e: React.ChangeEvent<HTMLInputElement>, weekDay: number, rowNumber: number) => {
    const isChecked = e.target.checked;
    let updatedStopWeekDays = formData.stopWeekDay;

    if (rowNumber === 1) {
      updatedStopWeekDays[weekDay] = isChecked ? 1 : 0;
    }

    return updatedStopWeekDays;
  };

  //============
  // Handlers
  //============

  const handleCallerIdChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    // It will only load the buckets if the user selects the "Dynamic Caller Id" option
    if (+e.target.value === CallerIdEnum.Dynamic) {
      setCampaignFormData({
        ...formData,
        callerId: +e.target.value,
      });
      getRciInfo();
    } else {
      setCampaignFormData({
        ...formData,
        callerId: +e.target.value,
        dciBucketId: 0,
      });
    }
  };

  const handleBucketSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (+e.target.value === 0) {
      window.location.href = "main/rci";
    } else {
      const val = +e.target.value;
      setCampaignFormData({
        ...formData,
        dciBucketId: val,
      });
    }
  };

  //============
  // UI Elements
  //============

  const getOptions = (list: Message[]) => {
    let mappedList = [{ value: 0, text: "Select" }];
    list.forEach((message, l) => {
      mappedList.push({
        value: message.id,
        text: message.name,
      });
    });

    return mappedList;
  };

  const getCheckboxes = (rowNumber: number) => {
    return campaignTimeTable.map((item, i) => {
      return (
        <CheckboxInput
          roundedslider={false}
          sliderlabel=""
          name={item.primaryKey}
          checked={
            rowNumber === 0
              ? formData.startWeekDay[i] === 1
                ? true
                : false
              : formData.stopWeekDay[i] === 1
              ? true
              : false
          }
          onChange={(e) => {
            setCampaignFormData({
              ...formData,
              startWeekDay: getStartWeekDays(e, i, rowNumber),
              stopWeekDay: getStopWeekDays(e, i, rowNumber),
            });
          }}
        />
      );
    });
  };

  const confirmSavingModal = (
    <Modal
      title={"Confirm Action"}
      content={
        <div className="flex justify-center items-center flex-col">
          <p className="text-primaryDarkGrey text-lg text-center py-0 px-5">
            This campaign does not have a message assigned, are you okay with that?
          </p>
        </div>
      }
      buttons={
        <div className="flex justify-evenly items-center h-full">
          <div className="w-60">
            <CancelButton
              onClick={() => {
                saveCampaignEdition();
              }}
            >
              Yes, save my campaign
            </CancelButton>
          </div>
          <div className="w-60">
            <CancelButton
              onClick={() => {
                setShowConfirmSavingModal(false);
              }}
            >
              No, let me fix that
            </CancelButton>
          </div>
        </div>
      }
      buttonClose={() => setShowConfirmSavingModal(false)}
    />
  );

  const getRows = () => {
    let tableRowsCount = 2;
    const startTimeInput = (
      <TimeInput
        placeholder="Time + AM/PM"
        title="Auto Start"
        addedText="(ET)"
        value={formData.autoStartValue}
        onChange={(e) =>
          setCampaignFormData({
            ...formData,
            autoStartValue: e.target.value,
          })
        }
      />
    );
    const stopTimeInput = (
      <TimeInput
        placeholder="Time + AM/PM"
        title="Auto Stop"
        addedText="(ET)"
        value={formData.autoStopValue}
        onChange={(e) =>
          setCampaignFormData({
            ...formData,
            autoStopValue: e.target.value,
          })
        }
      />
    );
    let tableItems = Array.from(Array(tableRowsCount), (e, i) => {
      let row: any[] = [];
      row = getCheckboxes(i);
      if (i === 0) {
        row.unshift(startTimeInput);
      } else {
        row.unshift(stopTimeInput);
      }
      return row;
    });
    return tableItems;
  };

  return (
    <div className="flex flex-col gap-5">
      {showConfirmSavingModal && confirmSavingModal}
      <div className="grid grid-cols-5 gap-5 mt-5">
        <div className="">
          <TextInput
            title="Linkback Campaign Name"
            value={formData.name}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                name: e.target.value,
              })
            }
          />
          <span className="text-xs text-redAlert">
            {nameIsRequiredInfo}
            {nameDuplicated}
          </span>
        </div>
        <div className="">
          <TextInput disabled={true} title="Campaign Status" value={formData.status} />
        </div>
        <div className="col-sm-2 col-md-2 col-lg-2">
          <NumberInput
            title="Call Ratio"
            value={formData.callRation}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                callRation: +e.target.value,
              })
            }
          />
          <span className="text-xs text-redAlert">{callRatioInfo}</span>
        </div>
        <div className="">
          <NumberInput
            title="Agents Count"
            value={formData.agentsCount}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                agentsCount: +e.target.value,
              })
            }
          />
          <span className="text-xs text-redAlert">{agentCountInfo}</span>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-5">
        <div className="">
          <TextInput
            title="Default Caller ID Name"
            value={formData.defaultCallerIdName}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                defaultCallerIdName: e.target.value,
              })
            }
            placeholder="Type Caller Id Name"
          />
        </div>
        <div className="">
          <TextInput
            title="Default Caller ID Number"
            value={formData.defaultCallerIdNumber}
            maxLength={14}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                defaultCallerIdNumber: dynamicPhoneMask(e.target.value.toString().replace(/ /gm, "")),
              })
            }
          />
          <span className="text-xs text-redAlert">
            {phoneNumberDefaultRequiredInfo}
            {phoneNumberDefaultError}
          </span>
        </div>
        <div className="">
          <TextInput
            title="Linkback Phone Number"
            value={formData.linkbackPhoneNumber}
            maxLength={14}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                linkbackPhoneNumber: dynamicPhoneMask(e.target.value.toString().replace(/ /gm, "")),
              })
            }
          />
          {!formData.linkbackPhoneNumber && (
            <span className="text-xs text-redAlert">{phoneNumberLinkbackRequiredInfo}</span>
          )}
          {formData.linkbackPhoneNumber && formData.linkbackPhoneNumber.length !== 14 && (
            <span className="text-xs text-redAlert">{phoneNumberLinkbackError}</span>
          )}
        </div>
        <div className="">
          <SelectInput
            title="Caller ID"
            value={formData.callerId}
            onChange={handleCallerIdChange}
            options={callerIdSelectOptions}
            required={true}
          />
        </div>
        <div className="">
          <SelectInput
            title="DCI Bucket"
            disabled={!!disableBucketSelection}
            value={formData.dciBucketId}
            onChange={handleBucketSelection}
            options={dcibucketSelectOptions}
          />
          <span className="text-xs text-redAlert">{bucketRequiredInfo}</span>
        </div>
      </div>
      <div className="w-7/12">
        <ContentTable columns={["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]} rows={getRows()} />
      </div>
      <div className="grid grid-cols-3 gap-5">
        <div className="">
          <SelectInput
            title="Call answered by Customer"
            value={formData.customerMessageId}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                customerMessageId: +e.target.value,
              })
            }
            options={getOptions(messageList)}
          />
        </div>
        <div className="">
          <SelectInput
            title="Call answered by voicemail or machine"
            value={formData.machineMessageId}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                machineMessageId: +e.target.value,
              })
            }
            options={getOptions(messageList)}
          />
        </div>
        <div className="">
          <SelectInput
            title="Call is linked back"
            value={formData.agentMessageId}
            onChange={(e) =>
              setCampaignFormData({
                ...formData,
                agentMessageId: +e.target.value,
              })
            }
            options={getOptions(messageList)}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5 my-5">
        <div className="flex gap-5">
          <ConfirmButton
            disabled={!enableSaving}
            onClick={() => {
              prepareToSave();
            }}
          >
            Save
          </ConfirmButton>
          <CancelButton
            onClick={() => {
              props.onSaveCancel(true);
            }}
          >
            Cancel
          </CancelButton>
        </div>
      </div>
    </div>
  );
};
