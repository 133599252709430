import { CampaignType } from "../../../api/livemonitoring";
import whiteFritzImg from "../../../images/whiteFritz.svg";

export const CampaignCreator = (props: { onSelection: Function }) => {
  const handleCampaignSelection = (campaignSelected: number) => {
    if (campaignSelected) {
      props.onSelection(campaignSelected);
    }
  };

  return (
    <div className="min-h-0 h-full flex flex-col bg-menuBackground">
      <div className="w-full h-2/6 px-12 bg-menuBackground inline-flex flex-nowrap items-center justify-center">
        <div className="logo">
          <img alt="" src={whiteFritzImg} />
        </div>
        <div className="flex flex-col gap-5 ml-5 text-white">
          <span className="text-4xl font-bold">Create A New Campaign</span>
          <span className="text-2xl">Ready to get started? Pick the type of campaign you would like to create!</span>
        </div>
      </div>
      <div className="flex flex-1 gap-1">
        <div
          className="flex-1 flex bg-primaryPurple text-white justify-center items-center"
          onClick={() => handleCampaignSelection(CampaignType.Linkback)}
        >
          <div className="flex flex-col w-92">
            <span className="text-5xl font-bold">Linkback</span>
            <span className="text-md">
              Plays your custom message and gives your customers the option to speak with a live agent.
            </span>
          </div>
        </div>
        <div
          className="flex-1 flex bg-lightBlue text-white justify-center items-center"
          onClick={() => handleCampaignSelection(CampaignType.Broadcast)}
        >
          <div className="flex flex-col w-92">
            <span className="text-5xl font-bold">Broadcast</span>
            <span className="text-md">
              Broadcasts your custom messages to customers or their answering machines as a reminder.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
