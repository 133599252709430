import * as React from "react";
import { ColumnFilter, filterIsComplete } from "../../../../interfaces/reports";
import ConfirmButton from "../../../../shared/buttons/confirm";

export const FilterEditor = (props: {
    initialFilterState: Partial<ColumnFilter>;
    onApply: (f: ColumnFilter) => void;
  }) => {
  //TODO: This is a little tricky. Wrap this plus the useEffect up into a custom hook.
  const [filterState, setFilterState] = React.useState<Partial<ColumnFilter>>(props.initialFilterState);

  React.useEffect(() => {
    setFilterState(props.initialFilterState);
  }, [props.initialFilterState]);

  function callApplyFn() {
    if (!filterIsComplete(filterState)) throw new Error("Attempting to apply an incomplete filter!");

    let completeFilter: ColumnFilter = filterState;
    props.onApply(completeFilter);
  }

  const applyButton = <ConfirmButton onClick={callApplyFn}>Apply</ConfirmButton>;

  if (!filterState.type) {
    throw new Error("Filter type is undefined!");
  }

  switch (filterState.type) {
    case "keyword": {
      const capturedState = { ...filterState };

      const updateKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, keyword: e.target.value });
      };

      return (
        <div className="flex flex-col">
          <p className="text-center font-bold mb-8">Filter By Keyword</p>
          <input
            className="border h-10 w-full font-normal mb-8"
            type="text"
            value={filterState.keyword}
            onChange={updateKeyword}
          />
          {applyButton}
        </div>
      );
    }

    case "constrained-keyword": {
      return <div className="flex flex-col"></div>;
    }

    case "multiple-constrained-keyword": {
      const capturedState = { ...filterState };

      const updateConstrainedKeyword = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let options = e.target.selectedOptions;
        let filters : Map<number, string> = new Map<number, string>();
        for (let i = 0; i < options.length; i++) {
          let sel = options[i];
          let name = sel.text;
          let value = sel.value;
          filters.set(parseInt(value), name);
        }
        setFilterState({ ...capturedState, choices: filters });
      };

      return (
        <div className="flex flex-col">
          <p className="text-center font-bold mb-8">Filter By Campaign</p>
          <select className="border h-30 w-full font-normal mb-8" multiple onChange={updateConstrainedKeyword}>
            { 
              Array.from(filterState.options!).map((item) => <option value={item[0]}>{item[1]}</option>)
            }
          </select>

          {applyButton}
        </div>);
    }

    case "multiple-constrained-options": {
      const capturedState = { ...filterState };

      const updateConstrainedKeyword = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let options = e.target.selectedOptions;
        let filters : Map<number, string> = new Map<number, string>();
        for (let i = 0; i < options.length; i++) {
          let sel = options[i];
          let name = sel.text;
          let value = sel.value;
          filters.set(parseInt(value), name);
        }
        setFilterState({ ...capturedState, choices: filters });
      };

      return(
        <div className="flex flex-col">
          
        <p className="text-center font-bold mb-8">  {capturedState.columnId === "transferred" ? "Transferred" : "Filter by Call Disposition"}</p>
        <select className="border h-30 w-50 font-normal mb-8" multiple onChange={updateConstrainedKeyword} style={{overflow: "scroll"}}>
            { 
              Array.from(filterState.options!).map((item) => <option value={item[0]} title={item[1]}>{item[1]}</option>)
            }
        </select>
        {applyButton}
      </div>
      );
    }

    case "numeric-range": {
      const capturedState = { ...filterState };

      const updateStart = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, start: parseInt(e.target.value) });
      };

      const updateEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, end: parseInt(e.target.value) });
      };

      return (
        <div className="flex flex-col">
          <p className="text-center font-bold mb-8">Number Between</p>
          <div className="flex flex-col items-center mb-8">
            <span>
              <input
                className="border h-10 w-12 font-normal text-center"
                type="number"
                value={filterState.start}
                onChange={updateStart}
              />{" "}
              sec
            </span>
            <span>+</span>
            <span>
              <input
                className="border h-10 w-12 font-normal text-center"
                type="number"
                value={filterState.end}
                onChange={updateEnd}
              />{" "}
              sec
            </span>
          </div>
          {applyButton}
        </div>
      );
    }

    case "date-range": {
      const capturedState = { ...filterState };

      const updateStartDay = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, startDay: e.target.value });
      };

      const updateEndDay = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, endDay: e.target.value });
      };

      const updateStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, startTime: e.target.value });
      };

      const updateEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, endTime: e.target.value });
      };

      const inputStyle: React.CSSProperties = { fontSize: "16px", marginBottom: "5px" };

      return (
        <div className="flex flex-col">
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginBottom: "30px" }}>
            <p className="text-center font-bold mb-8">Date Between</p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <input style={inputStyle} type="date" value={filterState.startDay} onChange={updateStartDay} />
              <input style={inputStyle} type="time" step="1" value={filterState.startTime} onChange={updateStartTime} />
            </div>
            <span>+</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <input style={inputStyle} type="date" value={filterState.endDay} onChange={updateEndDay} />
              <input style={inputStyle} type="time" step="1" value={filterState.endTime} onChange={updateEndTime} />
            </div>
          </div>
          {applyButton}
        </div>
      );
    }

    case "currency-range": {
      const capturedState = { ...filterState };

      const updateStart = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, start: parseInt(e.target.value) });
      };

      const updateEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...capturedState, end: parseInt(e.target.value) });
      };

      return (
        <div className="flex flex-col">
          <p className="text-center font-bold mb-8">Number Between</p>
          <div className="flex flex-col items-center mb-8">
            <span className="relative">
              <span className="absolute left-[-11px] top-2">$</span>
              <input
                className="border h-10 w-12 font-normal text-center"
                type="number"
                value={filterState.start}
                onChange={updateStart}
              />
            </span>
            <span>+</span>
            <span className="relative">
              <span className="absolute left-[-11px] top-2">$</span>
              <input
                className="border h-10 w-12 font-normal text-center"
                type="number"
                value={filterState.end}
                onChange={updateEnd}
              />
            </span>
          </div>
          {applyButton}
        </div>
      );
    }
  }
};
