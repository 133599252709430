import moment from "moment";
import * as React from "react";
import { ArchivedReport, downloadReport, fetchArchivedReports } from "../../api/archive";
import { Row, Table, ColumnName, Cell, ColumnsRow, TitleRow } from "../../shared/table";

export const ArchivePage = (props: { clientId: number }) => {
  const [report, setReport] = React.useState<ArchivedReport[]>([]);

  React.useEffect(() => {
    async function retrieveReports() {
      const reports = await fetchArchivedReports(props.clientId, 25);
      setReport(reports);
    }

    retrieveReports();
  }, [props.clientId]);

  function reportRow(report: ArchivedReport, id: number) {
    return (
      <Row key={id}>
        <Cell>{report.reportStatusName}</Cell>
        <Cell>{report.numberOfRecords}</Cell>
        <Cell>{moment(report.created).format("YYYY-MM-DD hh:mm:ss")}</Cell>
        <Cell>{moment(report.lastDownload).isValid() ? moment(report.lastDownload).format("YYYY-MM-DD hh:mm:ss") : ""}</Cell>
        <Cell>{report.downloadCount}</Cell>
        <Cell style={{ textAlign: "right", paddingRight: "25px" }}>
          <button
            className="px-2 rounded-full shadow-button b-transparentPurple bg-primaryPurple text-white disabled:bg-lightPurple2"
            disabled={report.reportStatusId !== 3}
            onClick={() => downloadReport(report)}
          >
            Download
          </button>
        </Cell>
      </Row>
    );
  }

  return (
    <div className="h-full flex flex-col py-5 px-24">
      <div className="mb-5 text-primaryDarkGrey font-bold text-2xl">Report Archives</div>
      <div className="sm:max-h-[350px] xl:max-h-[680px]">
        <Table>
          <thead>
            <TitleRow colSpan={6}>Reports</TitleRow>
            <ColumnsRow>
              <ColumnName>Export Status</ColumnName>
              <ColumnName>Number of Records</ColumnName>
              <ColumnName>Date Created</ColumnName>
              <ColumnName>Last Downloaded</ColumnName>
              <ColumnName colSpan={2}>Number of Downloads</ColumnName>
            </ColumnsRow>
          </thead>
          <tbody>{report.map(reportRow)}</tbody>
        </Table>
      </div>
    </div>
  );
};
