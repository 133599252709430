import { Config } from "../config";
import { CustomField } from "../interfaces/accountfields";
import { CallListMapItem } from "../interfaces/contactlist";

const user = JSON.parse(localStorage.getItem("user")!);

export const postCallList = async (clientId: number, data: FormData) => {
  const url = new URL(`${Config.platformwebapi_url}/api/calllist/upload`);
  let result;
  let params = new URLSearchParams();
  params.append("clientId", String(clientId));

  url.search = params.toString();

  await fetch(url.toString(), {
    method: "POST",
    body: data,
    headers: {
      Authorization: "Bearer " + user.bearerToken,
    },
  })
    .then((resp) => resp.json())
    .then((res) => (result = res));
  return result;
};

export const getCustomFields = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/customfield`);
  let params = new URLSearchParams();
  let result: CustomField[] = [];

  params.append("clientId", String(clientId));

  url.search = params.toString();

  await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  })
    .then((resp) => resp.json())
    .then((resp) => (result = resp));

  return result;
};

export const getCampaigns = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/campaign`);
  let params = new URLSearchParams();
  let result;

  params.append("clientId", String(clientId));

  url.search = params.toString();

  await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  })
    .then((resp) => resp.json())
    .then((res) => (result = res));

  return result;
};

export const loadCallList = async (data: FormData) => {
  const url = new URL(`${Config.platformwebapi_url}/api/calllist/preview`);
  let result;

  await fetch(url.toString(), {
    method: "POST",
    body: data,
    headers: {
      Authorization: "Bearer " + user.bearerToken,
    },
  })
    .then((resp) => resp.json())
    .then((res) => (result = res));

  return result;
};

export const getMappedFields = async (clientId: number, data: string[]) => {
  let managerId = JSON.parse(localStorage.getItem("user")!).id;
  const url = new URL(`${Config.platformwebapi_url}/api/calllist/mapping`);
  let params = new URLSearchParams();
  let result: CallListMapItem[] = [];

  params.append("clientId", String(clientId));
  params.append("managerId", String(managerId));

  url.search = params.toString();

  await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(data),
    headers: { 
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
    
  })
  .then((resp) => resp.json())
  .then((resp) => (result = resp))
  .catch(err => { 
    console.log(err); 
    result = [];        
  });

  return result;
};
