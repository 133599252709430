import React, { HTMLInputTypeAttribute } from "react";
import searchIcon from "../../images/search-icon-purple.svg";

type TextProps = React.InputHTMLAttributes<HTMLInputElement>;
interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  componentRef?: React.RefObject<HTMLTextAreaElement>;
}
interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  options: any[];
}
interface TimeProps extends React.InputHTMLAttributes<HTMLInputElement> {
  addedText: string;
}

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  roundedslider: boolean;
  sliderlabel: string;
}

interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const SearchInput: React.FC<SearchProps> = (props) => {
  return (
    <input
      {...props}
      className="min-w-0 w-full h-10 rounded-full border border-lightGray px-8 appearance-none focus:border-mediumGray transition-colors"
      style={{ background: `#FFFFFF url(${searchIcon}) no-repeat 10px` }}
    />
  );
};

export const DateInput: React.FC<TextProps> = ({ title, ...rest }) => {
  return (
    <label className="text-left inline-grid w-full">
      <span
        className={
          `
        flex
        text-primaryDarkGrey 
        font-sans 
        text-base ` + (rest.required ? " after:content-['*']" : "")
        }
      >
        {title}
      </span>
      <input className="peer px-1 bg-white border-0 text-primaryDarkGrey h-5 text-base rounded" type="date" {...rest} />
    </label>
  );
};

export const NumberInput: React.FC<TextProps> = ({ title, ...rest }: TextProps) => {
  return (
    <label className="text-left inline-grid w-full">
      <span
        className={
          `
        flex
        text-primaryDarkGrey 
        font-sans 
        text-base ` + (rest.required ? " after:content-['*']" : "")
        }
      >
        {title}
      </span>
      <input
        className="peer px-1 bg-white border border-lightGray text-primaryDarkGrey h-10 text-base rounded"
        type="number"
        {...rest}
      />
    </label>
  );
};
export const TimeInput: React.FC<TimeProps> = ({ title, placeholder, addedText, ...rest }: TimeProps) => {
  return (
    <label className="align-baseline block h-12 w-48 py-2">
      <span className="mr-4">{title}</span>
      <input type="time" placeholder={placeholder} {...rest} />
      {addedText ? <span>{addedText}</span> : null}
    </label>
  );
};

export const TextInput: React.FC<TextProps> = ({ title, ...rest }: TextProps) => {
  return (
    <label className="text-left inline-grid w-full">
      <span
        className={"flex text-primaryDarkGrey font-sans text-base " + (rest.required ? " after:content-['*']" : "")}
      >
        {title}
      </span>
      <input
        className="peer px-1 bg-white border border-lightGray text-primaryDarkGrey h-10 text-base rounded disabled:bg-lightGray"
        type="text"
        {...rest}
      />
    </label>
  );
};

export const TextAreaInput: React.FC<TextAreaProps> = ({ title, componentRef, ...rest }) => {
  return (
    <label className="text-left inline-grid w-full">
      <span
        className={
          `
          flex
          text-primaryDarkGrey 
          font-sans 
          text-base ` + (rest.required ? " after:content-['*']" : "")
        }
      >
        {title}
      </span>
      <textarea
        ref={componentRef}
        className="px-1 bg-white border border-lightGray text-primaryDarkGrey h-36 text-base rounded"
        type="text"
        {...rest}
      />
    </label>
  );
};

export const FileInput: React.FC<TextProps> = ({ title, ...rest }) => {
  return (
    <label className="text-left grid w-full">
      <div className="flex gap-2 items-baseline">
        <span
          className={` flex text-primaryDarkGrey font-sans text-base ` + (rest.required ? " after:content-['*']" : "")}
        >
          {title}
        </span>
        <span className="text-darkGrayTitle text-xs font-bold">Max. size: 120 MB</span>
      </div>
      <input
        type="file"
        className="border-1 border-solid border-lightGray rounded-md file:bg-primaryPurple file:text-white file:h-8 file:rounded-lg file:m-1 file:border-none file:px-2"
        {...rest}
      />
    </label>
  );
};

export const SelectInput: React.FC<SelectProps> = ({ ...rest }) => {
  let options = rest.options.map((opt, k) => {
    return (
      <option key={k} value={opt.value}>
        {opt.text}
      </option>
    );
  });

  options.push(
    <option key={-1} value={-1} disabled hidden>
      Please choose...
    </option>
  );

  return (
    <div className="w-full grid">
      <label className="text-left inline-grid w-full">
        <span> {rest.title} </span>
      </label>
      <div className="flex items-center">
        <select
          className="px-1 bg-white border border-lightGray text-primaryDarkGrey h-10 text-base rounded w-full"
          {...rest}
        >
          {options}
        </select>
      </div>
    </div>
  );
};

export const CheckboxInput: React.FC<CheckboxProps> = ({ roundedslider, sliderlabel, ...rest }) => {
  return (
    <div className="relative z-1">
      <label className="text-left inline-grid w-full">
        <span> {rest.title} </span>
      </label>
      <div className="flex items-center">
        {roundedslider ? (
          <label className="relative mr-3 inline-block w-16 h-7">
            <input className="peer opacity-0" type="checkbox" checked={rest.checked} {...rest} />
            <span
              className="absolute cursor-pointer inset-0 rounded-full
                                       bg-mediumGraySlider peer-checked:bg-blueChecked transition-all 
                                       before:absolute 
                                       before:h-5 
                                       before:w-5 
                                       before:transform
                                       before:translate-x-1
                                       peer-checked:before:translate-x-10
                                       before:bottom-1 
                                       before:rounded-full
                                       before:bg-white
                                       before:transition-transform
                                       disabled:bg-lightGray"
            ></span>
          </label>
        ) : (
          <label className="relative inline-block w-8 h-7 peer-disabled:text-lightGray2">
            <input className="peer opacity-0" type="checkbox" checked={rest.checked} {...rest} />
            <span
              className="absolute top-0 left-0 h-7 w-7 rounded-lg
                                       bg-lightLavander peer-disabled:bg-lightGray border-2 border-solid 
                                       border-lavender peer-disabled:border-lightGray cursor-pointer
                                       after:content-['']
                                       after:absolute
                                       peer-checked:bg-primaryPurple               
                                       peer-checked:after:block
                                       peer-checked:after:left-2
                                       peer-checked:after:top-1
                                       peer-checked:after:w-1/4
                                       peer-checked:after:h-3
                                       peer-checked:after:border-solid
                                       peer-checked:after:border-white
                                       peer-checked:after:border-r-3
                                       peer-checked:after:border-b-3
                                       peer-checked:after:rotate-45"
            ></span>
          </label>
        )}
        {sliderlabel ? <span>{sliderlabel}</span> : null}
      </div>
    </div>
  );
};
