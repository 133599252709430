import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import { Config } from "../config";
import { StepRecordingResponse } from "../interfaces/messages";

let user = JSON.parse(localStorage.getItem("user")!);
let hubConnection: HubConnection | undefined;

export enum HubStatus {
  Success = 0,
  Error = 1,
}

export const ClientHubConnection = async (eventName: string, groupHub: string, callBack: Function) => {
  hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${Config.platformwebapi_url}` + groupHub, {
      accessTokenFactory: () => user.bearerToken,
      withCredentials: false,
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();

  await hubConnection.start();

  hubConnection.on(eventName, (data: any) => {
    callBack(new HubData(data, HubStatus.Success));
  });
};

export class HubData {
  data: StepRecordingResponse | undefined;
  status: HubStatus | HubStatus.Success;

  constructor(data: any, sts: HubStatus) {
    this.data = data;
    this.status = sts;
  }
}

export class HubEvent {
  eventName: string;
  callback: Callback;

  constructor(eventName: string, callback: Callback) {
    this.eventName = eventName;
    this.callback = callback;
  }
}
type Callback = (data: HubData) => { data: any };
