
export const phoneMask = (phoneNumber: string, addMask: boolean = false) => {
    let modifiedPhone = phoneNumber;
  
    if (addMask) {
      const mask = phoneNumber.replace(/\D/g, "").match(/(\d{3})(\d{3})(\d{4})/);
      if (mask) {
        modifiedPhone = "(" + mask[1] + ") " + mask[2] + "-" + mask[3];
      }
    } else {
      modifiedPhone = phoneNumber.replace("(", "");
      modifiedPhone = modifiedPhone.replace(")", "");
      modifiedPhone = modifiedPhone.replace("-", "");
      modifiedPhone = modifiedPhone.replace(" ", "");
    }
    return modifiedPhone;
  };
  
  export const phoneFormatted = (phoneNumber: string, includeCountry: boolean = false) => {
    return includeCountry ? `1${phoneNumber}` : phoneNumber.substr(1, phoneNumber.length - 1);
  };
  
  export const dynamicPhoneMask = (phoneNumber: string) => {
    let phoneTyped = phoneNumber;
  
    phoneTyped = phoneTyped.replace(/\D/g, "");
    phoneTyped = phoneTyped.replace(/(\d{3})(\d{1})/, "($1) $2");
    phoneTyped = phoneTyped.replace(/(\d{3})(\d{1})/, "$1-$2");
  
    return phoneTyped;
  };
  