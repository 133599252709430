export interface TextMessage {
  id: number;
  clientId: number;
  name: string;
  message: string;
  created: string;
  updated: string;
  campaigns: string;
}

export enum MessageTextErrors {
  EMPTY = 1,
  TOOLARGE = 2,
  VALIDATION = 3,
}
