export enum MessageStatus {
  Pending = 1,
  Processing = 2,
  Ready = 3,
  Error = 4,
}

export enum MessageStepType {
  RecordedWithPhone = 1,
  RecordedOnComputer = 2,
  UploadedSoundFile = 3,
  FixedTTS = 4,
  FieldTTS = 5,
}

export enum ButtonRecordingStatus {
  MakeCall = 1,
  Dialing = 2,
  InCall = 3,
  Recording = 4,
  Listening = 5,
  Done = 6,
  Error = 7,
}

export enum HubStatus {
  Success = 0,
  Error = 1,
}
