import React from "react";
import { Cell, ColumnName, ColumnsRow, Row, Table, TitleRow } from "../shared/table";
import { getDateandTime } from "../utils/DateUtils";
import { dynamicPhoneMask, phoneFormatted } from "../utils/PhoneUtils";
import closeIcon from "../images/close_icon.svg";
import { LoaderSpinner } from "../shared/loaderspinner";
import { InboundTextMessage, MessageIntentType } from "../interfaces/textmanager";
import { fetchTextingMessages } from "../api/textmanager";
import { CheckboxInput } from "../shared/input";
import { useErrorHandling } from "../apiError";

// TODO: Change messages to more user friendly ones
const getUserOption = (intent: MessageIntentType) => {
  switch (intent) {
    case 1:
      return "None";
    case 2:
      return "Opt In";
    case 3:
      return "This message was categorized as an opt-out.";
    case 4:
      return "Help";
    default:
      return "";
  }
};

const messageBubble = (messageSelected: InboundTextMessage | null) => {
  if (messageSelected) {
    return (
      <div>
        <div className="relative">
          <div className="bg-gray w-2 h-2 absolute top-4 -right-1 rotate-45"></div>
          <div className="bg-gray w-full h-auto p-4 min-h-20 rounded-lg">{messageSelected.message}</div>
        </div>
        <span className="mt-3 block text-right">{getDateandTime(new Date(messageSelected.date)) + " (ET)"}</span>
        <div className="relative">
          <div className="bg-lightPurple w-2 h-2 absolute top-4 -left-1 rotate-45"></div>
          <div className="mt-7 w-full h-auto min-h-20 italic">{getUserOption(messageSelected.messageIntent)}</div>
        </div>
      </div>
    );
  }
};

export const TextManager = (props: { clientId: number }) => {
  const triggerError = useErrorHandling();

  //====================
  // Variables
  //====================
  const [inboundMessages, setInboundMessages] = React.useState<InboundTextMessage[]>([]);
  const [selectedMessage, setSelectedMessage] = React.useState<InboundTextMessage | null>(null);
  const [showInboundMessageInfo, setShowInboundMessageInfo] = React.useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(true);

  //====================
  // Hooks
  //====================
  React.useEffect(() => {
    async function loadInboundMessages() {
      const dt = await fetchTextingMessages(props.clientId);

      if (dt) {
        if (!dt.err) {
          setInboundMessages(dt.data.sort((a: InboundTextMessage, b: InboundTextMessage) => +a.date - +b.date));
        } else {
          triggerError(dt.err);
        }
      }
      setShowLoadingSpinner(false);
    }

    loadInboundMessages();
  }, [props.clientId, triggerError]);

  //====================
  // Retrieval functions
  //====================

  const getConditionalRowStyles = (currentMessageId: number) => {
    return selectedMessage
      ? +currentMessageId === +selectedMessage.id
        ? { backgroundColor: "#F7F5FE" }
        : { backgroundColor: "#FFF" }
      : { backgroundColor: "#FFF" };
  };

  const inboundMessagesRow = (message: InboundTextMessage, index: number) => {
    return (
      <Row
        id={index.toString()}
        onClick={() => selectInboundMessage(message)}
        style={getConditionalRowStyles(+message?.id)}
      >
        <Cell> {dynamicPhoneMask(phoneFormatted(message.phoneNumber))}</Cell>
        <Cell> {getDateandTime(new Date(message.date)) + " (ET)"}</Cell>
        <Cell colSpan={2}>
          <span className="block truncate sm:w-20 xl:w-96">{message.message}</span>
        </Cell>

        <Cell>
          {message.messageIntent === MessageIntentType.OptOut ? (
            <div className="justify-center -mt-5">
              <CheckboxInput
                roundedslider={false}
                sliderlabel=""
                checked={message.messageIntent === MessageIntentType.OptOut}
                readOnly={true}
              ></CheckboxInput>
            </div>
          ) : null}
        </Cell>
      </Row>
    );
  };

  const inboundMessageInfo = () => {
    return (
      <div className="animate-slider ml-5 w-3/12 relative overflow-auto rounded-lg shadow-window border border-spaceGray ">
        <div className="h-12 whitespace-nowrap w-full">
          <div className="flex justify-between pl-6 h-full bg-secondaryOffWhite border-b border-spaceGray">
            <span className="text-left text-lg font-bold pt-2">Text Message</span>
            <img
              alt=""
              className="w-6 h-6 cursor-pointer mt-3 mr-3"
              src={closeIcon}
              onClick={() => setShowInboundMessageInfo(false)}
            />
          </div>
        </div>
        <div className="p-9">{messageBubble(selectedMessage)}</div>
      </div>
    );
  };

  const selectInboundMessage = (messageSelected: InboundTextMessage) => {
    setShowInboundMessageInfo(true);
    setSelectedMessage(messageSelected);
  };

  return (
    <div className="h-full flex flex-col py-5 px-24 bg-white">
      <div className="flex flex-col">
        <div className="flex flew-row justify-end">
          <div className="w-full pb-7 font-bold text-3xl text-primaryDarkGrey">Text Manager</div>
        </div>
      </div>
      <div className="flex overflow-auto">
        <div className={showInboundMessageInfo ? "w-9/12 min-h-500 grid" : "w-full min-h-500 grid"}>
          <div className="sm:max-h-[350px] xl:max-h-[680px]">
            <Table>
              <thead>
                <TitleRow colSpan={5}> Inbound Texts </TitleRow>
                <ColumnsRow>
                  <ColumnName>Phone Number</ColumnName>
                  <ColumnName>Date and Time</ColumnName>
                  <ColumnName colSpan={2}>Message</ColumnName>
                  <ColumnName>Opt out?</ColumnName>
                </ColumnsRow>
              </thead>
              <tbody>
                {!showLoadingSpinner ? inboundMessages.map(inboundMessagesRow) : <LoaderSpinner tableLength={5} />}
              </tbody>
            </Table>
          </div>
        </div>
        {showInboundMessageInfo ? inboundMessageInfo() : null}
      </div>
    </div>
  );
};
