import { Config } from "../config";
import { pick } from "../utils/Conversion";

export enum CallListStatus {
  New = 1,
  Processing = 2,
  Open = 3,
  Loaded = 4,
  Closing = 5,
  Closed = 6,
}

export enum CampaignStatus {
  Stopped = 1,
  Starting = 2,
  Running = 3,
  Stopping = 4,
}

export enum DaysOfTheWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export enum CampaignType {
  Broadcast = 1,
  Linkback = 2,
  Texting = 3,
}

export type CallListsSummary = {
  campaignId: number;
  calllists: ModelCallListStats[];
};

export type ModelCallListStats = {
  callListId: number;
  campaignId: number;
  originalFileName: string;
  callListStatusId: CallListStatus;
  callsTotal: number;
  callsCalledCount: number;
  callsNotCalledCount: number;
};

export type CampaignStatsHeader = {
  campaignId: number;
  clientId: number;
  campaignType: number;
};

export type ListStatsHeader = {
  campaignType: number;
  callListId: number;
  clientId: number;
};

export type BroadcastStats = {
  avgDuration: number;
  countTotal: number;
  countTotalCalled: number;
  countMachine: number;
  countPerson: number;
  countTotalFailed: number;
  countTotalNoAnswer: number;
};

export type LinkbackStats = {
  avgDurationTransfer: number;
  countTransfer: number;
};

export type TextingStats = {
  textDelivered: number;
  textFailed: number;
  textRemaining: number;
  textTotal: number;
};

export interface CampaignSpeedBroadcast extends CampaignSpeed {
  callsPerMinute: number;
}

export interface CampaignSpeedLinkback extends CampaignSpeed {
  agentsCount: number;
  callRatio: number;
}

export interface CampaignSpeed {
  campaignId: number;
} 

function extractBroadcastSpeed(json: any) {
  const value = pick<CampaignSpeedBroadcast>(json, {
    callsPerMinute: Number,
    campaignId: Number
  });
  return value;
}

function extractLinkbackSpeed(json: any) {
  const value = pick<CampaignSpeedLinkback>(json, {
    agentsCount: Number,
    callRatio: Number,
    campaignId: Number
  });
  return value;
}

// CONTACT LIST STATS
export type BroadcastListPayload = ListStatsHeader & BroadcastStats;
export type TextingListPayload = ListStatsHeader & TextingStats;
export type LinkbackListPayload = ListStatsHeader & BroadcastStats & LinkbackStats;

export type TextingListStats = {
  type: "TextingListStats";
  payload: TextingListPayload;
};

export type LinkbackListStats = {
  type: "LinkbackListStats";
  payload: LinkbackListPayload;
};

export type BroadcastListStats = {
  type: "BroadcastListStats";
  payload: BroadcastListPayload;
};

export type ContactListStats = BroadcastListStats | LinkbackListStats | TextingListStats;

function extractLinkbackListStats(json: any) {
  const value = pick<LinkbackListPayload>(json, {
    callListId: Number,
    clientId: Number,
    avgDuration: Number,
    countTotal: Number,
    countTotalCalled: Number,
    countMachine: Number,
    countPerson: Number,
    campaignType: Number,
    countTotalFailed: Number,
    countTotalNoAnswer: Number,
    avgDurationTransfer: Number,
    countTransfer: Number,
  });

  return value;
}

function extractBroadcastListStats(json: any) {
  const value = pick<BroadcastListPayload>(json, {
    callListId: Number,
    clientId: Number,
    avgDuration: Number,
    countTotal: Number,
    countTotalCalled: Number,
    countMachine: Number,
    countPerson: Number,
    campaignType: Number,
    countTotalFailed: Number,
    countTotalNoAnswer: Number,
  });

  return value;
}

function extractTextingListStats(json: any) {
  const value = pick<TextingListPayload>(json, {
    callListId: Number,
    clientId: Number,
    campaignType: Number,
    textTotal: Number,
    textDelivered: Number,
    textFailed: Number,
    textRemaining: Number,
  });

  return value;
}

// CAMPAIGN STATS

export type BroadcastCampaignPayload = CampaignStatsHeader & BroadcastStats;
export type TextingCampaignPayload = CampaignStatsHeader & TextingStats;
export type LinkbackCampaignPayload = CampaignStatsHeader & BroadcastStats & LinkbackStats;

export type TextingCampaignStats = {
  type: "TextingCampaignStats";
  payload: TextingCampaignPayload;
};

export type LinkbackCampaignStats = {
  type: "LinkbackCampaignStats";
  payload: LinkbackCampaignPayload;
};

export type BroadcastCampaignStats = {
  type: "BroadcastCampaignStats";
  payload: BroadcastCampaignPayload;
};

export type CampaignStats = TextingCampaignStats | LinkbackCampaignStats | BroadcastCampaignStats;

export interface BaseCampaign {
  campaignId: number;
  name: string;
  clientId: number;
  campaignStatusId: Number;
  campaignStatusName: string;
  campaignType: number;
  campaignTypeName: string;
  updated: string;
  startTime: Date;
  stopTime: Date;
  startAutomatically: boolean;
  stopAutomatically: boolean;
  callerIdNumber: string;
  callerIdName: string;
  campaignStarted: boolean;
  callerIdTypeId: number;
  startTimeSpan: string;
  stopTimeSpan: string;
  startWeekdays: string;
  stopWeekdays: string;
  rciBucketId: number;
  selected: boolean;
}

export interface DisplayCampaign extends BaseCampaign {
  percentage: number;
}

export interface CampaignCompletionPercentage {
  campaignId: number;
  progress: number;
}

export interface ContactList {
  callListId: number;
  originalFileName: string;
  callListStatusId: number;
}

export interface ContactListSummary {
  callListId: number;
  campaignType: number;
  clientId: number;
  textDelivered: number;
  textFailed: number;
  textRemaining: number;
  textTotal: number;
}

function extractContactList(json: any) {
  const value = pick<ContactList>(json, {
    callListId: Number,
    originalFileName: String,
    callListStatusId: Number,
  });

  return value;
}

function extractBaseCampaign(json: any) {
  const value = pick<BaseCampaign>(json, {
    campaignId: Number,
    name: String,
    clientId: Number,
    campaignStatusId: Number,
    campaignStatusName: String,
    campaignType: Number,
    campaignTypeName: String,
    updated: String,
    startAutomatically: Boolean,
    stopAutomatically: Boolean,
    startTime: (s: string) => new Date(s),
    stopTime: (s: string) => new Date(s),
    startWeekdays: String,
    stopWeekdays: String,
    rciBucketId: Number,
    callerIdName: String,
    callerIdNumber: String,
    callerIdTypeId: Number,
    startTimeSpan: String,
    stopTimeSpan: String,
    campaignStarted: Boolean,
    selected: Boolean,
  });

  return value;
}

function extractLinkbackStats(json: any) {
  const value = pick<LinkbackCampaignPayload>(json, {
    campaignId: Number,
    clientId: Number,
    avgDuration: Number,
    countTotal: Number,
    countTotalCalled: Number,
    countMachine: Number,
    countPerson: Number,
    campaignType: Number,
    countTotalFailed: Number,
    countTotalNoAnswer: Number,
    avgDurationTransfer: Number,
    countTransfer: Number,
  });

  return value;
}

function extractBroadcastStats(json: any) {
  const value = pick<BroadcastCampaignPayload>(json, {
    campaignId: Number,
    clientId: Number,
    avgDuration: Number,
    countTotal: Number,
    countTotalCalled: Number,
    countMachine: Number,
    countPerson: Number,
    campaignType: Number,
    countTotalFailed: Number,
    countTotalNoAnswer: Number,
  });

  return value;
}

function extractTextingStats(json: any) {
  const value = pick<TextingCampaignPayload>(json, {
    campaignId: Number,
    clientId: Number,
    campaignType: Number,
    textTotal: Number,
    textDelivered: Number,
    textFailed: Number,
    textRemaining: Number,
  });

  return value;
}

export const fetchBaseCampaigns = async (clientId: number) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign`);
  let params = new URLSearchParams();
  params.append("clientId", String(clientId));
  url.search = params.toString();
  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();

  let campaigns: BaseCampaign[] = [];
  for (let v of responseJson) {
    const value = extractBaseCampaign(v);
    campaigns.push(value);
  }

  return campaigns;
};

export const fetchContactListsForCampaign = async (campaign: BaseCampaign) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/calllist`);
  let params = new URLSearchParams();
  params.append("clientId", String(campaign.clientId));
  params.append("campaignId", String(campaign.campaignId));
  params.append("type", String(campaign.campaignType));

  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson: CallListsSummary = await response.json();
  const callLists = responseJson.calllists;

  let contactLists = [];

  for (let v of callLists) {
    const value = extractContactList(v);
    contactLists.push(value);
  }
  return contactLists;
};


export const fetchCampaignSpeed = async (campaignId: number, campaignType: number, clientId: number) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign/speed`);
  let params = new URLSearchParams();
  params.append("clientId", String(clientId));
  params.append("campaignId", String(campaignId));
  params.append("campaignType", String(campaignType));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();

  switch (campaignType) {
    case 1:
      return extractBroadcastSpeed(responseJson);
    case 2:
      return extractLinkbackSpeed(responseJson);
   
    default:
      throw new Error("Unknown campaign type encountered while extracting campaign speed!");
  }
};

export const fetchContactListStats = async (clientId: number, campaignType: number, listId: number) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/calllist`);
  let params = new URLSearchParams();
  params.append("clientId", String(clientId));
  params.append("calllistId", String(listId));
  params.append("type", String(campaignType));

  url.search = params.toString();
  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();

  switch (campaignType) {
    case 1:
      const broadcastStatsPayload = extractBroadcastListStats(responseJson);
      return {
        type: "BroadcastListStats",
        payload: broadcastStatsPayload,
      } as BroadcastListStats;
    case 2:
      const linkbackStatsPayload = extractLinkbackListStats(responseJson);
      return {
        type: "LinkbackListStats",
        payload: linkbackStatsPayload,
      } as LinkbackListStats;
    case 3:
      const textingStatsPayload = extractTextingListStats(responseJson);
      return {
        type: "TextingListStats",
        payload: textingStatsPayload,
      } as TextingListStats;
    default:
      throw new Error("Unknown campaign type encountered while extracting call list stats!");
  }
};

export const fetchCampaignStats = async (campaign: BaseCampaign) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign`);
  let params = new URLSearchParams();
  params.append("clientId", String(campaign.clientId));
  params.append("campaignId", String(campaign.campaignId));
  params.append("type", String(campaign.campaignType));
  url.search = params.toString();
  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();

  switch (campaign.campaignTypeName) {
    case "Linkback":
      const linkbackStatsPayload = extractLinkbackStats(responseJson);
      return {
        type: "LinkbackCampaignStats",
        payload: linkbackStatsPayload,
      } as LinkbackCampaignStats;
    case "Broadcast":
      const broadcastStatsPayload = extractBroadcastStats(responseJson);
      return {
        type: "BroadcastCampaignStats",
        payload: broadcastStatsPayload,
      } as BroadcastCampaignStats;
    case "Texting":
      const textingStatsPayload = extractTextingStats(responseJson);
      return {
        type: "TextingCampaignStats",
        payload: textingStatsPayload,
      } as TextingCampaignStats;
    default:
      throw Error("Invalid campaign type!");
  }
};

export const fetchCampaignCompletionPercentages = async (clientId: number) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign/progress`);
  let params = new URLSearchParams();
  params.append("clientId", String(clientId));
  url.search = params.toString();
  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();

  let completionPercentages: CampaignCompletionPercentage[] = [];
  for (let v of responseJson) {
    const value = pick<CampaignCompletionPercentage>(v, {
      campaignId: Number,
      progress: Number,
    });

    completionPercentages.push(value);
  }

  return completionPercentages;
};


export const fetchCampaignCompletionPercentage = async (clientId: number, campaigns: number[]) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign/progress1`);
  let params = new URLSearchParams();
  params.append("clientId", String(clientId));
  url.search = params.toString();
  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(campaigns),
    headers: { Authorization: "Bearer " + user.bearerToken, "Content-Type": "application/json" },
  });
  const responseJson = await response.json();

  let completionPercentages: CampaignCompletionPercentage[] = [];
  for (let v of responseJson) {
    const value = pick<CampaignCompletionPercentage>(v, {
      campaignId: Number,
      progress: Number,
    });

    completionPercentages.push(value);
  }

  return completionPercentages;
};

export const startCampaign = async (campaign: BaseCampaign) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign/${campaign.campaignId}/start`);
  fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(campaign),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });
};

export const stopCampaign = async (campaign: BaseCampaign) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign/${campaign.campaignId}/stop`);
  fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(campaign),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });
};

export const updateCampaignSpeedBroadcast = async (campaign: CampaignSpeedBroadcast) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign/broadcast/${campaign.campaignId}/speed`);
  fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(campaign),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });
};

export const updateCampaignSpeedLinkback = async (campaign: CampaignSpeedLinkback) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaign/linkback/${campaign.campaignId}/speed`);
  fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(campaign),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });
};
