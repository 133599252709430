import * as React from "react";
import { postCallList } from "../api/upload";
import { CallListFile, CallListMapItem } from "../interfaces/contactlist";
import { CallListMap, UploadCallListResponse } from "../interfaces/upload";
import CancelButton from "../shared/buttons/cancel";
import ConfirmButton from "../shared/buttons/confirm";
import { Modal } from "../shared/modal";
import { Stepper } from "../shared/stepper";
import { AccountField, CheckboxField, MapFileComponent, MappedConfig, PreviewCallListResponse } from "./steps/map";
import { PreviewFileComponent } from "./steps/preview";
import { SelectFileComponent } from "./steps/selectfile";
import { UploadFileComponent } from "./steps/uploadfile";

const stepData: string[] = ["Select file", "Map", "Preview", "Upload"];

type UploadedInfo = {
  accountsNotImported: number;
  accountsTotal: number;
  accountPhonesImported: number;
  accountPhonesTotal: number;
  alerts: String[];
};

export const Upload = (props: { clientId: number }) => {
  // Step
  const [currentStep, setcurrentStep] = React.useState<number>(0);
  const [nextStepReady, setNextStepReady] = React.useState<boolean>(false);

  // Callist Upload const
  const [uploadResponse, setUploadResponse] = React.useState<UploadCallListResponse | null>(null);

  // Created callList const
  const [callListMapItems, setCallListMapItems] = React.useState<CallListMapItem[]>([]);
  const [callListMap, setCallListMap] = React.useState<CallListMap | null>(null);

  // File selected const
  const [fileInput, setFileInput] = React.useState<File | null>(null);
  const [csvRecords, setCsvRecords] = React.useState<PreviewCallListResponse | null>(null);
  const [fileDelimiter, setFileDelimiter] = React.useState<string>("");

  // Modals
  const [showProcessingModal, setShowProcessingModal] = React.useState<boolean>(false);
  const [showUploadedModal, setShowUploadedModal] = React.useState<boolean>(false);

  // Uploaded Info
  const [accountInfo, setAccountInfo] = React.useState<UploadedInfo | null>(null);

  React.useEffect(() => {
    if (uploadResponse) {
      setShowProcessingModal(false);
      getMessagesFromUpload(uploadResponse);
      setShowUploadedModal(true);
    }
  }, [uploadResponse]);

  const closeModalAndReturn = () => {
    setShowUploadedModal(false);
    window.location.replace("/main/calllist");
  };

  const processingModalContainer = (
    <Modal
      title={"Upload Progress"}
      content={
        <div className="flex flex-col justify-center items-center text-primaryDarkGrey">
          <p className="px-20 text-lg text-center">Processing contact list...</p>
        </div>
      }
      buttons={
        <div className="flex w-full justify-around mt-7 ">
          <div className="w-60">
            <CancelButton onClick={() => setShowProcessingModal(false)}>Ok</CancelButton>
          </div>
        </div>
      }
      buttonClose={() => setShowProcessingModal(false)}
    />
  );

  const uploadedModalContainer = (
    <Modal
      title={"Upload Finished!"}
      content={
        <div className="flex flex-col justify-center items-center text-primaryDarkGrey">
          {accountInfo ? (
            <div>
              <p className="px-20 mt-4 text-lg text-center">
                Accounts Imported: {accountInfo?.accountsTotal - accountInfo?.accountsNotImported} of{" "}
                {accountInfo?.accountsTotal}
              </p>
              <p className="px-20 mt-4 text-lg text-center">
                Accounts Phones Imported: {accountInfo.accountPhonesImported} of {accountInfo.accountPhonesTotal}{" "}
              </p>
              {accountInfo.alerts && accountInfo.alerts.length ? (
                <div className="px-20 mt-4 text-lg text-center">
                  <p> Alerts: </p> {accountInfo.alerts.map((message) => `${message}\n`)}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      }
      buttons={
        <div className="flex w-full justify-around mt-7 ">
          <div className="w-60">
            <CancelButton onClick={() => closeModalAndReturn()}>Ok</CancelButton>
          </div>
        </div>
      }
      buttonClose={() => closeModalAndReturn()}
    />
  );

  const saveData = async () => {
    setShowProcessingModal(true);
    const formData = new FormData();
    formData.append("fileSelect", fileInput!);
    formData.append("callListMap", JSON.stringify(callListMap));
    formData.append("headers", JSON.stringify(csvRecords?.headers));

    const uploadResp = await postCallList(props.clientId, formData);
    setUploadResponse(uploadResp!);
  };

  const getMessagesFromUpload = (uploadResponse: UploadCallListResponse) => {
    let messageError = [];

    if (!!uploadResponse.wrongFormatPhoneNumbers) {
      messageError.push(uploadResponse.wrongFormatPhoneNumbers + " Phone numbers didn't match the correct format");
    }
    if (!!uploadResponse.fieldsTrucated) {
      messageError.push(uploadResponse.fieldsTrucated + " Account Fields were truncated");
    }
    if (!!uploadResponse.restrictedPhoneNotCallable) {
      messageError.push(uploadResponse.restrictedPhoneNotCallable + " Phone numbers had other restrictions");
    }
    setAccountInfo({
      accountPhonesImported: uploadResponse.accountPhonesImported,
      accountPhonesTotal: uploadResponse.accountPhonesTotal,
      accountsNotImported: uploadResponse.accountsNotImported,
      accountsTotal: uploadResponse.accountsTotal,
      alerts: messageError,
    });
  };

  const getStepSelected = () => {
    switch (currentStep) {
      case 0:
        return <SelectFileComponent fileUploadedOk={handleFileUpload} />;
      case 1:
        return <MapFileComponent records={csvRecords!} clientId={props.clientId} mappedOk={handleMappedFields} />;
      case 2:
        return <PreviewFileComponent callListMap={callListMapItems} records={csvRecords!} />;
      case 3:
        return (
          <UploadFileComponent
            clientId={props.clientId}
            callListMap={callListMapItems}
            delimiterSelected={fileDelimiter}
            uploadReady={handleUploadCallList}
          />
        );
    }
  };

  //============
  // Handlers
  //============

  const handleUploadCallList = React.useCallback((isDataReady: boolean, callListData: CallListMap) => {
    setNextStepReady(isDataReady);
    if (isDataReady) {
      setCallListMap(callListData);
    }
  }, []);

  const handleChangeStep = (offset: number) => {
    if (offset === 1 && currentStep === stepData.length - 1) {
      saveData();
    } else {
      const nextStep = currentStep + offset;
      setcurrentStep(nextStep);
      if (nextStep === 1) {
        // When the step is the preview one, recreate calllistmapitem
        setCallListMapItems([]);
        setNextStepReady(false);
      }
    }
  };

  const handleStepSelection = (selectedStep: number) => {
    setcurrentStep(selectedStep);
    setNextStepReady(false);
  };

  const handleFileUpload = (fileRowsandCols: PreviewCallListResponse, file: File, delimiter: string) => {
    setFileDelimiter(delimiter);
    setCsvRecords(fileRowsandCols);
    setNextStepReady(true);
    setFileInput(file);
  };

  const createPhoneNumberMapping = (config: MappedConfig) => {
    const phoneNumberAccField = new AccountField(0, "Phone Number");
    const phoneField = config.callListFiles.find((phone) => {
      if (phone.fieldColumn !== undefined) {
        if (config.phoneNumberSelected) return +phone.fieldColumn === +config.phoneNumberSelected.id;
      } else {
        throw new Error("Expected a phone's fieldColumn to be defined!");
      }
    });

    let phoneNumberItem: CallListMapItem = {
      accountField: { id: 0, value: "" },
      selectedCallListField: [],
    };
    let phoneNumberSelected: CallListFile[] = [phoneField!];

    phoneNumberItem.accountField = phoneNumberAccField;
    phoneNumberItem.selectedCallListField = phoneNumberSelected;
    return phoneNumberItem;
  };

  const includePhoneNumber = (mappedPhonesList: CallListMapItem, config: MappedConfig) => {
    let phoneNumberCallListSelectedFiles: CallListFile[] = mappedPhonesList.selectedCallListField;
    const phoneFieldFile: CallListFile = config.callListFiles.find((phone) => {
      if (phone.fieldColumn !== undefined) {
        if (config.phoneNumberSelected) return +phone.fieldColumn === +config.phoneNumberSelected.id;
      } else {
        throw new Error("Expected a phone's fieldColumn to be defined!");
      }
    })!;

    const phoneAlreadyAdded = phoneNumberCallListSelectedFiles.find((phone) => {
      if (phone.fieldColumn !== undefined && phoneFieldFile.fieldColumn !== undefined) {
        return +phone.fieldColumn === +phoneFieldFile.fieldColumn;
      } else {
        throw new Error("Expected a phone's fieldColumn to be defined!");
      }
    });

    if (!!phoneAlreadyAdded) {
      const updatedPhoneNumberCallList = phoneNumberCallListSelectedFiles.filter((phone) => {
        if (phone.fieldColumn !== undefined && phoneFieldFile.fieldColumn !== undefined) {
          return +phone.fieldColumn !== +phoneFieldFile.fieldColumn;
        } else {
          throw new Error("Expected a phone's fieldColumn to be defined!");
        }
      });
      phoneNumberCallListSelectedFiles = updatedPhoneNumberCallList;
    } else {
      phoneNumberCallListSelectedFiles.push(phoneFieldFile);
    }

    return phoneNumberCallListSelectedFiles;
  };

  const handleMappedFields = (mappedConfig: {
    ready: boolean;
    callListMapItems: CallListMapItem[];
    callListFiles: CallListFile[];
    phoneNumberSelected: CheckboxField;
  }) => {
    // For each checkbox selection, the fields have to be mapped again
    let configuration: MappedConfig = JSON.parse(JSON.stringify(mappedConfig));
    let updatedMappedItems = JSON.parse(JSON.stringify(configuration.callListMapItems));
    let currentMappedItems: CallListMapItem[] =
      JSON.parse(JSON.stringify(callListMapItems)) && JSON.parse(JSON.stringify(callListMapItems)).length
        ? JSON.parse(JSON.stringify(callListMapItems))
        : updatedMappedItems;

    if (configuration.callListMapItems.length && configuration.callListFiles.length) {
      // The user selected a new field for the phonenumber mapping
      if (!!configuration.phoneNumberSelected) {
        if (!!!currentMappedItems.find((item: CallListMapItem) => +item.accountField.id === 0)) {
          // If is the first phone number insertion
          const phoneField = createPhoneNumberMapping(configuration);
          currentMappedItems.unshift(phoneField);
          setNextStepReady(configuration.ready);
          setCallListMapItems(currentMappedItems);
        } else {
          // If there is already a phone number
          const phoneFields = includePhoneNumber(currentMappedItems[0], configuration);
          currentMappedItems[0].selectedCallListField = phoneFields;
          setNextStepReady(configuration.ready);
          setCallListMapItems(currentMappedItems);
        }
      } else {
        // What the user selected was an updated in one of the select dropdowns

        if (currentMappedItems.find((item: CallListMapItem) => +item.accountField.id === 0)) {
          const phoneFields = currentMappedItems[0];
          const customFields = configuration.callListMapItems;

          currentMappedItems = customFields;
          currentMappedItems.unshift(phoneFields);

          setNextStepReady(configuration.ready);
          setCallListMapItems(currentMappedItems);
        }
      }
    }
  };

  return (
    <div className="h-full flex flex-col gap-5 py-5 px-24 bg-white">
      {showProcessingModal && processingModalContainer}
      {showUploadedModal && uploadedModalContainer}

      <div className="flex h-20 flex-col p-5">
        <div className="flex flew-row justify-end">
          <div className="w-full pb-7 font-bold text-3xl text-primaryDarkGrey">Upload Contact List</div>
        </div>
      </div>
      <div className="flex justify-center h-20 w-full">
        <Stepper steps={stepData} currentStep={currentStep} onStepChange={handleStepSelection} />
      </div>
      <div className="sm:h-[400px]">
        {getStepSelected()}
        <div className="flex gap-4 justify-center items-center mt-8">
          {currentStep !== 0 && (
            <div className="basis-2/12">
              <CancelButton onClick={(e) => handleChangeStep(-1)}>Previous Step</CancelButton>
            </div>
          )}
          <div className="basis-2/12">
            <ConfirmButton disabled={!nextStepReady} onClick={(e) => handleChangeStep(1)}>
              {currentStep === stepData.length - 1 ? "Upload and Finish" : "Next Step"}
            </ConfirmButton>
          </div>
        </div>
      </div>
    </div>
  );
};
