import { Message, MessageStep } from "./../interfaces/messages/index";
import { TtsField, TtsVoice } from "../interfaces/messages";
import { MessageForm } from "../types/messages";
import { pick } from "../utils/Conversion";
import { Config } from "../config";
import { fetchData, getRequestInfo } from "../apifunctions";

let user = JSON.parse(localStorage.getItem("user")!);

export const recordPhoneCallMade = async (message: MessageStep) => {
  const url = new URL(`${Config.platformwebapi_url}/api/message/call/`);

  if (message) {
    const response = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify(message),
      headers: {
        Authorization: "Bearer " + user.bearerToken,
        "Content-Type": "application/json",
      },
    });
    if (response.ok) return message;
  }
};

export const fetchMessagePreview = async (messageId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/message/${messageId}/preview`);

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();

  console.log(responseJson);
};

export const fetchMessagesList = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/message`);
  let params = new URLSearchParams();

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();
  let messagesList: MessageForm[] = [];
  for (let v of responseJson) {
    const message = pick<MessageForm>(v, {
      id: Number,
      name: String,
      clientId: Number,
      messageStatusId: Number,
      messageStatusName: String,
      messageSteps: Array,
      campaigns: String,
      created: String,
      updated: String,
    });

    messagesList.push(message);
  }
  return messagesList;
};

export const fetchInUseFields = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/ttsfield/getinuse`);
  let params = new URLSearchParams();

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();

  let fieldsList: TtsField[] = [];
  for (let v of responseJson) {
    const message = pick<TtsField>(v, {
      id: Number,
      value: String,
    });

    fieldsList.push(message);
  }
  return fieldsList;
};

export const fetchVoices = async () => {
  const url = new URL(`${Config.platformwebapi_url}/api/ttsvoice`);
  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();

  let voiceList: TtsVoice[] = [];
  for (let v of responseJson) {
    const message = pick<TtsVoice>(v, {
      id: Number,
      name: String,
    });

    voiceList.push(message);
  }
  return voiceList;
};

export const postMessage = async (message: Message, files: Array<any>) => {
  const url = new URL(`${Config.platformwebapi_url}/api/message/${message.id}/edit/`);

  if (message) {
    let formattedMessage = new FormData();

    files.forEach((file, index) => {
      const count = index + 1;
      formattedMessage.append("fileSelect" + count, file);
    });

    formattedMessage.append("message", JSON.stringify(message));

    const response = await fetch(url.toString(), {
      method: "POST",
      body: formattedMessage,
      headers: {
        Authorization: "Bearer " + user.bearerToken,
      },
    });
    return response;
  } else {
    console.log("Couldnt add the message on the DB");
  }
};

export const fetchGetMessageSteps = async (messageId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/message/${messageId}/steps`);

  let params = new URLSearchParams();
  params.append("clientId", String(messageId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();

  let messageSteps: MessageStep[] = [];
  for (let v of responseJson) {
    const step = pick<Omit<MessageStep, "phoneNumber">>(v, {
      id: Number,
      messageId: Number,
      messageStepType: Number,
      stepOrder: Number,
      value: String,
      ttsVoiceId: Number,
      fileName: String,
      ttsModeId: Number,
      ttsFieldId: Number,
      file: String,
      clientsFileName: String,
      isDirty: Boolean,
    });
    messageSteps.push(step);
  }
  return messageSteps;
};

export const deleteMessageObj = async (message: Message, messageList: Message[]) => {
  const url = new URL(`${Config.platformwebapi_url}/api/message/${message.id}/delete/`);
  let messageToRemove = messageList.find((item) => item.id === message.id);
  messageToRemove = {
    messageSteps: [],
    id: messageToRemove?.id!,
    name: messageToRemove?.name!,
    clientId: messageToRemove?.clientId!,
    messageStatusId: messageToRemove?.messageStatusId!,
    messageStatusName: messageToRemove?.messageStatusName!,
    campaigns: messageToRemove?.campaigns!,
  };

  if (!!messageToRemove) {
    const response = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify(messageToRemove),
      headers: {
        Authorization: "Bearer " + user.bearerToken,
        "Content-Type": "application/json",
      },
    });
    return response.ok;
  }
};

export const fetchPreviewMessage = async (message: Message) => {
  const url = new URL(`${Config.platformwebapi_url}/api/message/${message.id}/preview/`);
  let blobFile = [];

  const response = await fetchData(url.toString(), {
    method: "GET",
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/pdf",
    },
  });

  if (response.ok) {
    const blob = await response.blob();
    blobFile.push(window.URL.createObjectURL(new Blob([blob])));
  }

  return getRequestInfo<string>(blobFile, response);
};
