import React from "react";
import { pick } from "../../utils/Conversion";

export type UserAuthInfo = {
  userName: string;
  bearerToken: string;
  isAuthenticated: boolean;
  id: number;
  name: string;
  clientId: number;
};

export function extractUserAuthInfo(user: any) {
  return pick<UserAuthInfo>(user, {
    bearerToken: String,
    clientId: Number,
    id: Number,
    isAuthenticated: Boolean,
    name: String,
    userName: String,
  });
}

export default function useAuthInfo() {
  const getAuthInfo = () => {
    const storedObject = localStorage.getItem("user");
    if (storedObject) {
      let user = JSON.parse(storedObject);
      return extractUserAuthInfo(user);
    } else {
      return undefined;
    }
  };

  const [authInfo, setAuthInfo] = React.useState<UserAuthInfo | undefined>(getAuthInfo());

  const saveAuthInfo = (userToken: UserAuthInfo) => {
    localStorage.setItem("user", JSON.stringify(userToken));
    setAuthInfo(userToken);
  };

  const deleteAuthInfo = React.useCallback(() => {
    localStorage.removeItem("user");
    setAuthInfo(undefined);
  }, []);

  return { setAuthInfo: saveAuthInfo, authInfo, deleteAuthInfo };
}
