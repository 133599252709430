import * as React from "react";

export const ProgressBar = (props: { bgColor: string; percentage: number }) => {
  const containerElem = React.useRef<HTMLDivElement>(null);
  const spanElem = React.useRef<HTMLSpanElement>(null);
  const percentageElem = React.useRef<HTMLDivElement>(null);

  let coverageWidth = 0;
  let percentageWidth = 0;

  if (containerElem.current && spanElem.current) {
    coverageWidth = +containerElem.current.offsetWidth / 2 + +spanElem.current.offsetWidth / 2;
  }
  if (percentageElem.current) {
    percentageWidth = percentageElem.current.offsetWidth;
  }

  return (
    <div className="w-full h-full relative rounded-full border-1 overflow-hidden">
      <div
        ref={containerElem}
        className="w-full h-full absolute text-center z-20"
        style={{ borderColor: props.bgColor }}
      >
        <span
          ref={spanElem}
          className="block m-auto w-fit"
          style={{ color: percentageWidth > coverageWidth ? "white" : "black" }}
        >
          {props.percentage}%
        </span>
      </div>
      <div
        ref={percentageElem}
        className="w-20 h-full absolute z-10"
        style={{
          backgroundColor: props.bgColor,
          width: props.percentage + "%",
        }}
      ></div>
    </div>
  );
};
