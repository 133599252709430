import { Config } from "../config";
import { TextingCampaign, TextingNumber } from "../interfaces/textingcampaigns";
import { pick } from "../utils/Conversion";

const extractTextingCampaign = (json: any) => {
  const value = pick<TextingCampaign>(json, {
    campaignId: Number,
    clientId: Number,
    name: String,
    campaignStatusName: String,
    updated: String,
    textPerMinute: Number,
    messageTextId: Number,
    textMessageName: String,
    sendersNumberId: Number,
    originalSendersNumberId: Number,
    startAutomatically: Boolean,
    stopAutomatically: Boolean,
    startTime: (s: string) => new Date(s),
    stopTime: (s: string) => new Date(s),
    startWeekdays: String,
    stopWeekdays: String,
  });

  return value;
};

export const fetchCampaigns = async (clientId: number) => {
  let user = JSON.parse(localStorage.getItem("user")!!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaigntext`);
  let params = new URLSearchParams();
  params.append("clientId", String(clientId));
  url.search = params.toString();
  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();

  let messages: TextingCampaign[] = [];
  for (let obj of responseJson) {
    const campaign = extractTextingCampaign(obj);
    messages.push(campaign);
  }

  return messages;
};

export const fetchTextingNumbers = async (clientId: number) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaigntext/${clientId}/phonenumbers`);
  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });
  const responseJson = await response.json();

  let textingNumbers: TextingNumber[] = [];
  for (let v of responseJson) {
    const value = pick<TextingNumber>(v, {
      textingPhoneNumberId: Number,
      phoneNumber: String,
    });

    textingNumbers.push(value);
  }

  return textingNumbers;
};

export const saveCampaign = async (campaign: Partial<TextingCampaign>) => {
  let user = JSON.parse(localStorage.getItem("user")!);
  let url = new URL(`${Config.platformwebapi_url}/api/campaigntext/${campaign.campaignId}/edit`);
  fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(campaign),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.bearerToken,
    },
  });
};
