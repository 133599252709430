import * as React from "react";
import { fetchManagers, updateManager, deleteManager, addManager } from "../api/manager";
import { Manager, EditorState, NewManagerForm, emptyManagerForm, emptyManager } from "../interfaces/manager";
import CancelButton from "../shared/buttons/cancel";
import ConfirmButton from "../shared/buttons/confirm";
import { PasswordInput } from "../shared/passwordinput";
import { Cell } from "../shared/table";
import { validEmail, validSimpleString, validAlphanumericIdentifier } from "../utils/Validation";
import { EntityTable } from "../shared/entitytable";
import { assertNever } from "../utils/Asserts";

type ManagerAction = "Edit" | "Enable" | "Disable" | "Delete";

export const ManagerPage = (props: { clientId: number }) => {
  //======
  // Hooks
  //======

  // Backend state
  const [managers, setManagers] = React.useState<Manager[]>([]);

  // UI State
  const [showNewManagerPassword, setShowNewManagerPassword] = React.useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(true);

  // Manager editor state
  const [editorState, setEditorState] = React.useState(EditorState.Inactive);
  const [newManagerFormData, setNewManagerFormData] = React.useState<NewManagerForm>(emptyManagerForm);
  const [editingManager, setEditingManager] = React.useState<Manager>(emptyManager);

  //====================
  // Retrieval functions
  //====================
  const retrieveManagers = React.useCallback(async () => {
    const result = await fetchManagers(props.clientId);
    setManagers(result);
    setShowLoadingSpinner(false);
  }, [props.clientId]);

  React.useEffect(() => {
    retrieveManagers();
  }, [retrieveManagers]);

  async function toggleManagerStatus(m: Manager) {
    let updatedManager: Manager = { ...m, active: !m.active };
    await updateManager(updatedManager);
    retrieveManagers();
  }

  //=============
  // UI Behaviors
  //=============
  function resetEditorState() {
    setEditorState(EditorState.Inactive);
    switch (editorState) {
      case EditorState.Adding:
        setNewManagerFormData(emptyManagerForm);
        break;

      case EditorState.Editing:
        setEditingManager(emptyManager);
        break;
    }
  }

  function handleManagerActions(action: ManagerAction, idx: number) {
    const manager = managers[idx];
    if (manager === undefined) {
      throw Error("Attempting to perform an action on an invalid entity!");
    }

    switch (action) {
      case "Disable":
      case "Enable":
        toggleManagerStatus(manager);
        break;

      case "Edit":
        setEditorState(EditorState.Editing);
        setEditingManager(manager);
        break;
      case "Delete":
        deleteManager(manager);
        retrieveManagers();
        break;

      default: {
        assertNever(action);
      }
    }
  }

  function actions(manager: Manager) {
    let actions: ManagerAction[] = [];

    if (!manager.active) {
      actions.push("Enable");
    } else {
      actions.push("Disable");
    }

    return actions.concat(["Edit", "Delete"]);
  }

  function toggleShowNewManagerPassword() {
    setShowNewManagerPassword(!showNewManagerPassword);
  }

  //============
  // UI Elements
  //============

  // Editor for existing/new managers, connected to table of existing managers.
  let editorContent = null;
  switch (editorState) {
    case EditorState.Inactive:
      editorContent = (
        <div className="w-full flex flex-row justify-between">
          <div className="text-primaryDarkGrey font-bold text-3xl mb-3">Managers</div>
          <div className="flex justify-end w-40 h-10 whitespace-nowrap">
            <ConfirmButton onClick={() => setEditorState(EditorState.Adding)}>Add Manager</ConfirmButton>
          </div>
        </div>
      );
      break;

    case EditorState.Adding:
      {
        const form = newManagerFormData;

        const emailIsValid = validEmail(form.email);
        const passwordsMatch = form.newPassword === form.confirmPassword;

        const addingFormIsValid =
          validSimpleString(form.name) &&
          validAlphanumericIdentifier(form.username) &&
          emailIsValid &&
          validAlphanumericIdentifier(form.newPassword) &&
          validAlphanumericIdentifier(form.confirmPassword) &&
          passwordsMatch;

        editorContent = (
          <div>
            <form className="grid gap-5 justify-center grid-cols-2">
              <fieldset className="w-full">
                <label className="text-lg font-normal">Name</label>
                <input
                  value={form.name}
                  onChange={(e) =>
                    setNewManagerFormData({
                      ...form,
                      name: e.target.value,
                    })
                  }
                  type="text"
                  className="border border-spaceGray shadow-none h-10 w-full"
                />
              </fieldset>
              <fieldset className="w-full">
                <label className="text-lg font-normal">Username</label>
                <input
                  value={form.username}
                  onChange={(e) =>
                    setNewManagerFormData({
                      ...form,
                      username: e.target.value,
                    })
                  }
                  type="text"
                  className="border border-spaceGray shadow-none h-10 w-full"
                />
              </fieldset>
              <fieldset className="w-full col-span-2">
                <label className="text-lg font-normal">Email</label>
                <input
                  value={form.email}
                  onChange={(e) =>
                    setNewManagerFormData({
                      ...form,
                      email: e.target.value,
                    })
                  }
                  type="text"
                  className="border border-spaceGray shadow-none h-10 w-full"
                />
                {!emailIsValid && <div>Please enter a valid email</div>}
              </fieldset>
              <fieldset className="w-full col-span-2">
                <label className="text-lg font-normal">New Password</label>
                <PasswordInput
                  showPassword={showNewManagerPassword}
                  showHideAction={toggleShowNewManagerPassword}
                  value={form.newPassword}
                  onChange={(e) =>
                    setNewManagerFormData({
                      ...form,
                      newPassword: e.target.value,
                    })
                  }
                />
              </fieldset>
              <fieldset className="w-full col-span-2">
                <label className="text-lg font-normal">Confirm Password</label>
                <PasswordInput
                  showPassword={showNewManagerPassword}
                  showHideAction={toggleShowNewManagerPassword}
                  value={form.confirmPassword}
                  onChange={(e) =>
                    setNewManagerFormData({
                      ...form,
                      confirmPassword: e.target.value,
                    })
                  }
                />
                {!passwordsMatch && <div>The password does not match.</div>}
              </fieldset>
              <ConfirmButton disabled={!addingFormIsValid} onClick={(e) => handleEditorSave(e)}>
                Save
              </ConfirmButton>
              <CancelButton onClick={(e) => handleEditorCancel(e)}>Cancel</CancelButton>
            </form>
          </div>
        );
      }
      break;

    case EditorState.Editing:
      const editingFormIsValid =
        editingManager.name.length > 0 && editingManager.username.length > 0 && validEmail(editingManager.email);

      editorContent = (
        <div>
          <form className="grid gap-5 justify-center grid-cols-2">
            <fieldset className="w-full">
              <label className="text-lg font-normal">Name</label>
              <input
                value={editingManager.name}
                onChange={(e) =>
                  setEditingManager({
                    ...editingManager,
                    name: e.target.value,
                  })
                }
                type="text"
                className="border border-spaceGray shadow-none h-10 w-full"
              />
            </fieldset>
            <fieldset className="w-full">
              <label className="text-lg font-normal">Username</label>
              <input
                value={editingManager.username}
                onChange={(e) =>
                  setEditingManager({
                    ...editingManager,
                    username: e.target.value,
                  })
                }
                type="text"
                className="border border-spaceGray shadow-none h-10 w-full"
              />
            </fieldset>
            <fieldset className="w-full col-span-2">
              <label className="text-lg font-normal">Email</label>
              <input
                value={editingManager.email}
                onChange={(e) =>
                  setEditingManager({
                    ...editingManager,
                    email: e.target.value,
                  })
                }
                type="text"
                className="border border-spaceGray shadow-none h-10 w-full"
              />
            </fieldset>
            <ConfirmButton disabled={!editingFormIsValid} onClick={(e) => handleEditorSave(e)}>
              Save
            </ConfirmButton>
            <CancelButton onClick={(e) => handleEditorCancel(e)}>Cancel</CancelButton>
          </form>
        </div>
      );
      break;
  }

  const editor = (
    <div className={"flex mb-5 " + (editorState === EditorState.Inactive ? "justify-end" : "justify-start")}>
      {editorContent}
    </div>
  );

  //===============
  // Event handlers
  //===============
  async function handleEditorSave(e: React.MouseEvent) {
    e.preventDefault();
    switch (editorState) {
      case EditorState.Adding:
        await addManager({
          clientId: props.clientId,
          name: newManagerFormData.name,
          email: newManagerFormData.email,
          password: "",
          newPassword: newManagerFormData.newPassword,
          username: newManagerFormData.username,
          active: true,
        });
        retrieveManagers();
        break;

      case EditorState.Editing:
        await updateManager(editingManager);
        retrieveManagers();
        break;
    }
    resetEditorState();
  }

  function handleEditorCancel(e: React.MouseEvent) {
    e.preventDefault();
    resetEditorState();
  }

  return (
    <div className="h-full flex items-start justify-start py-5 px-24">
      <div className="w-full max-h-full overflow-y-auto rounded-lg">
        {editor}
        <div className="sm:max-h-[350px] xl:max-h-[680px]">
          <EntityTable<Manager, ManagerAction>
            title={"Other Managers"}
            sortableColumns={["id", "name", "email", "active"]}
            columns={["ID", "Name", "Email", "Status"]}
            entities={managers}
            rowFn={(m: Manager) => {
              return [
                <Cell>{m.id}</Cell>,
                <Cell>{m.name}</Cell>,
                <Cell>{m.email}</Cell>,
                <Cell>
                  <span className={m.active ? "text-statusEnabled" : "text-statusDisabled"}>
                    {m.active ? "Enabled" : "Disabled"}
                  </span>
                </Cell>,
              ];
            }}
            actionFn={actions}
            actionHandler={handleManagerActions}
            loading={showLoadingSpinner}
          />
        </div>
      </div>
    </div>
  );
};
