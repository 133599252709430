import * as React from "react";
import ascSortIcon from "../../images/sort_ascending_icon.svg";
import descSortIcon from "../../images/sort_descending_icon.svg";
import { SortedState } from "../../utils/Sort";

type TableProps = React.HTMLAttributes<HTMLTableElement>;
type CellDataProps = React.TdHTMLAttributes<HTMLTableCellElement>;

export type SortedColumn = {
  id: string;
  state: SortedState;
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  columnIsselected?: Function;
}

interface HeaderDataProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  selected?: boolean;
  sortObj?: SortedColumn;
}

export const Table: React.FC<TableProps> = ({ ...props }: TableProps) => {
  return (
    <div className="max-h-full relative overflow-auto rounded-lg shadow-window border border-spaceGray">
      <table className="w-full border-separate text-primaryDarkGrey">{props.children}</table>
    </div>
  );
};

export const ColumnsRow: React.FC<RowProps> = ({ ...rest }: RowProps) => {
  const [currentSortOrder, setCurrentSortOrder] = React.useState<SortedColumn>();

  const changeSort = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const state = currentSortOrder ? getOrdenationState(currentSortOrder.state) : SortedState.asc;
    const col: SortedColumn = { id: (e.target as Element).id.toString(), state: state };

    if (rest.columnIsselected && !!col && !!col.id) {
      setCurrentSortOrder(col);
      rest.columnIsselected(col);
    }
  };

  const getOrdenationState = (currentSort: SortedState) => {
    switch (currentSort) {
      case SortedState.none:
        return SortedState.asc;
      case SortedState.asc:
        return SortedState.desc;
      case SortedState.desc:
        return SortedState.none;
      default:
        return SortedState.none;
    }
  };

  return (
    <tr className="group sticky top-0 h-12" onClick={changeSort}>
      {rest.children}
    </tr>
  );
};

export const ColumnName: React.FC<HeaderDataProps> = ({ ...props }: HeaderDataProps) => {
  const getSortIcon = (sortState: SortedState) => {
    switch (sortState) {
      case SortedState.asc:
        return ascSortIcon;
      case SortedState.desc:
        return descSortIcon;
      case SortedState.none:
        return "";
      default:
        return "";
    }
  };
  const icon = props.sortObj ? getSortIcon(props.sortObj.state) : "";
  const imgClassName = props.sortObj && props.selected ? "ml-2 w-6 visible" : "ml-2 w-6 invisible";

  return (
    <th
      className="pl-6 bg-lightPurple border-b border-spaceGray text-left text-md whitespace-nowrap cursor-pointer select-none"
      colSpan={props.colSpan}
      id={props.id}
    >
      <div className="flex" id={props.id}>
        {props.children}

        <div className="w-[24px]">
          <img src={icon} className={imgClassName} />
        </div>
      </div>
    </th>
  );
};

export const TitleRow: React.FC<HeaderDataProps> = ({ ...props }: HeaderDataProps) => {
  return (
    <tr className="h-12 whitespace-nowrap">
      <th className="pl-6 bg-secondaryOffWhite border-b border-spaceGray text-left text-lg" {...props}>
        {props.children}
      </th>
    </tr>
  );
};

export const Cell: React.FC<CellDataProps> = ({ ...props }) => {
  return (
    <td className="border-b border-spaceGray pl-6 whitespace-nowrap" {...props}>
      {props.children}
    </td>
  );
};

export const Row: React.FC<RowProps> = ({ ...props }: RowProps) => {
  return (
    <tr
      className="border-b border-spaceGray bg-white hover:bg-lightPurple transition-colors duration-100 h-12 cursor-pointer"
      {...props}
    >
      {props.children}
    </tr>
  );
};
