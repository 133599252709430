import * as React from "react";
import {
  DisplayCampaign,
  CampaignStats,
  fetchBaseCampaigns,
  fetchCampaignCompletionPercentages,
  fetchCampaignCompletionPercentage,
  fetchCampaignStats,
  BaseCampaign,
  startCampaign,
  stopCampaign,
  CampaignCompletionPercentage,
  CampaignSpeed,
  fetchCampaignSpeed,
} from "../api/livemonitoring";
import { CampaignListItem } from "./campaignlistitem";
import { CallCampaignOverview, TextingCampaignOverview } from "./campaignoverview";
import { CampaignSummary } from "./campaignsummary";
import { ContactLists } from "./contactlists";
import { campaignColor } from "./utils";
import { Window } from "../shared/window";

const refreshRateMS = 10000;

export const LiveMonitoring = (props: { clientId: number }) => {
  //================
  // Hooks
  //================
  const [campaigns, setCampaigns] = React.useState<DisplayCampaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = React.useState(0);
  const [selectedCampaignStats, setSelectedCampaignStats] = React.useState<CampaignStats | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [selectedCampaignSpeed, setSelectedCampaignSpeed] = React.useState<CampaignSpeed | null>(null);

  //==================
  // Utility functions
  //==================
  const findCampaign = React.useCallback(
    (campaignId: number) => {
      const campaign = campaigns.find((c: BaseCampaign) => c.campaignId === campaignId);
      return campaign;
    },
    [campaigns]
  );

  // Campaign data should be sorted to preserve campaign list ordering across a data refresh
  const sortCampaigns = (campaigns: DisplayCampaign[]) => {
    return campaigns.sort((a, b) => +b.campaignStatusId - +a.campaignStatusId);
  };

  //====================
  // Retrieval functions
  //====================
  const loadCampaigns = React.useCallback(async () => {
    const data = await fetchBaseCampaigns(props.clientId);

    const camps: number[] = [];
    data.forEach((v1) => {
      camps.push(v1.campaignId);
    });
    const completionPercentages = await fetchCampaignCompletionPercentage(props.clientId, camps);
    const displayData = data.map((v1) => {
      //TODO: What should be done if there's nothing matching here?
      let completionPercentage = completionPercentages.find((v2) => v2.campaignId === v1.campaignId);
      if (completionPercentage === undefined) {
        completionPercentage = { campaignId: v1.campaignId, progress: 0 };
      }
      return { ...v1, percentage: completionPercentage.progress };
    });

    setCampaigns(sortCampaigns(displayData));
    if (data.length > 0 && selectedCampaign === 0) {
      const defaultSelection = data[0].campaignId;
      setSelectedCampaign(defaultSelection);
    }
  }, [props.clientId, selectedCampaign]);

  const loadCampaignStats = React.useCallback(
    async (campaignId: number) => {
      if (campaignId !== 0) {
        const campaign = findCampaign(campaignId);
        if (campaign) {
          const data = await fetchCampaignStats(campaign);
          setSelectedCampaignStats(data);

          // Loading Campaign Speed
          const campaignSpeed = await fetchCampaignSpeed(campaignId, campaign.campaignType, campaign.clientId);
          setSelectedCampaignSpeed(campaignSpeed);
        }
        setIsLoading(false);
      }
    },
    [findCampaign]
  );

  //================
  // Retrieval Hooks
  //================


  React.useEffect(() => {
    loadCampaignStats(selectedCampaign);

    //const refresh = setInterval(() => {
    //  loadCampaignStats(selectedCampaign);
    //}, refreshRateMS);
    //
    //return () => clearInterval(refresh);

  }, [selectedCampaign, loadCampaignStats]);

  React.useEffect(() => {
    loadCampaigns();

    //const refresh = setInterval(() => {
    //  loadCampaigns();
    //}, refreshRateMS);
    //
    //return () => clearInterval(refresh);
    
  }, [selectedCampaign, loadCampaigns]);

  //========================
  // Input handler functions
  //========================
  const handleCampaignClick = (campaignId: number) => {
    setSelectedCampaign(campaignId);
  };

  const handleCampaignButtonClick = () => {
    if (selectedCampaign !== 0) {
      const campaign = findCampaign(selectedCampaign);
      if (campaign) {
        const status = campaign.campaignStatusName;
        switch (status) {
          case "Stopping":
          case "Stopped":
            startCampaign(campaign);
            campaign.campaignStatusName = "Starting";
            break;
          case "Starting":
          case "Running":
            stopCampaign(campaign);
            campaign.campaignStatusName = "Stopping";
            break;
        }
        const updatedCampaigns = campaigns.filter((elem) => elem.campaignId !== campaign.campaignId);
        setCampaigns(sortCampaigns([...updatedCampaigns, campaign]));
      } else {
        throw Error("Non-zero campaign ID had no corresponding campaign!");
      }
    }
  };

  //============
  // UI Elements
  //============

  // selectedCampaign can only be 0 when the client does not have campaigns on their account.
  // In that case, skip rendering the live monitoring page entirely
  if (isLoading) {
    return(
      <div className="h-full w-full flex justify-center items-center">
          <p>Loading ...</p>
      </div>)
  } 
  else if (selectedCampaign && selectedCampaignStats) {
    const campaign = findCampaign(selectedCampaign);
    const campaignListItems = campaigns.map((campaign) => {
      return (
        <CampaignListItem
          campaignId={campaign.campaignId}
          name={campaign.name}
          campaignStatusName={campaign.campaignStatusName}
          campaignTypeName={campaign.campaignTypeName}
          campaignCompletionPercentage={campaign.percentage}
          selected={selectedCampaign === campaign.campaignId}
          onClick={handleCampaignClick}
        />
      );
    });

    let campaignOverviewContent = null;
    switch (selectedCampaignStats.type) {
      case "TextingCampaignStats":
        campaignOverviewContent = <TextingCampaignOverview {...selectedCampaignStats} />;
        break;
      /*
        03/11 - The overview is not gonna be showed anymore until we have the correct values being returned
        04/07 - Changed accordingly to PLA-1280
        */
      case "LinkbackCampaignStats":
        campaignOverviewContent = <CallCampaignOverview {...selectedCampaignStats} />;
        break;
      case "BroadcastCampaignStats":
        campaignOverviewContent = <CallCampaignOverview {...selectedCampaignStats} />;
        break;
    }

    return (
      <div className="py-10 px-12 2xl:px-80 h-full grid gap-5 grid-cols-3 grid-rows-livemonitoring">
        <div className="col-start-1 row-start-1 text-primaryDarkGrey text-3xl font-bold">Live Monitoring</div>
        <div className="col-start-2 col-span-full">{campaignOverviewContent}</div>
        <div className="col-start-1 row-start-2">
          <Window title="Campaign List" padContent={false}>
            <ul className="flex flex-col p-0 m-0">{campaignListItems}</ul>
          </Window>
        </div>
        <div className="col-start-2 row-start-2 col-span-full">
          {!!selectedCampaignStats && !!campaign ? (
            <CampaignSummary
              campaign={campaign}
              campaignButtonClick={handleCampaignButtonClick}
              stats={selectedCampaignStats}
              campaignSpeed={selectedCampaignSpeed!}
            />
          ) : null}
        </div>
        {campaign ? (
          <div className="row-start-3 col-span-full">
            <Window
              padContent={false}
              header={
                <div className="py-3 px-8">
                  {campaign.name} {"Contact Lists "}
                  <span style={{ color: campaignColor(campaign.campaignStatusName) }}>(Only Today's)</span>
                </div>
              }
            >
              <div className="h-full w-full">
                <ContactLists key={campaign.campaignId} campaign={campaign} />
              </div>
            </Window>
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <p>There is no campaign data to display</p>
      </div>
    );
  }
};
