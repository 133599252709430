import { Config } from "../config";
import { Manager } from "../interfaces/manager";
import { pick } from "../utils/Conversion";

let user = JSON.parse(localStorage.getItem("user")!);

export const fetchCurrentManager = async () => {
  let url = new URL(`${Config.platformwebapi_url}/api/manager/${user.id}/current`);

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();
  const manager = pick<Manager>(responseJson, {
    id: Number,
    name: String,
    username: String,
    password: String,
    newPassword: String,
    email: String,
    clientId: Number,
    active: Boolean,
  });

  return manager;
};

export const updateManager = async (managerDetails: Manager) => {
  let url = new URL(`${Config.platformwebapi_url}/api/manager/${user.id}/edit`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(managerDetails),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

export const fetchManagers = async (clientId: number) => {
  let url = new URL(`${Config.platformwebapi_url}/api/manager`);

  let params = new URLSearchParams();
  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();
  let managers: Manager[] = [];
  for (let v of responseJson) {
    const phoneInfo = pick<Manager>(v, {
      id: Number,
      name: String,
      username: String,
      password: String,
      newPassword: String,
      email: String,
      clientId: Number,
      active: Boolean,
    });

    managers.push(phoneInfo);
  }
  return managers;
};

export const deleteManager = async (managerDetails: Manager) => {
  let url = new URL(`${Config.platformwebapi_url}/api/manager/${managerDetails.id}/delete`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(managerDetails),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

export const addManager = async (managerDetails: Manager) => {
  let url = new URL(`${Config.platformwebapi_url}/api/manager/add`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(managerDetails),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};
