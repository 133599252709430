import { HubStatus, MessageStatus, MessageStepType } from "../../enums/messages";

export interface TtsVoice {
  id: number;
  name: string;
}

export class TtsField {
  id: number;
  value: string;

  constructor(id: number = 0, value: string = "") {
    this.id = id;
    this.value = value;
  }
}

export class MessageStep {
  value: string;
  stepOrder: number;
  file: any;
  isDirty: boolean;
  fileName: string;
  id?: number;
  messageId?: number;
  messageStepType?: MessageStepType;
  ttsVoiceId?: number;
  ttsModeId?: number;
  ttsFieldId?: number;
  phoneNumber?: number;
  clientsFileName?: string;

  constructor() {
    this.id = undefined;
    this.messageId = undefined;
    this.stepOrder = 0;
    this.value = "";
    this.ttsVoiceId = undefined;
    this.fileName = "";
    this.ttsModeId = 1;
    this.ttsFieldId = undefined;
    this.file = null;
    this.isDirty = false;
    this.clientsFileName = "";
  }
}

export interface Message {
  id: number;
  name: string;
  clientId: number;
  messageStatusId: MessageStatus;
  messageStatusName: string;
  messageSteps: Array<MessageStep>;
  campaigns: string;
}

export interface StepRecordingResponse {
  clientId: number;
  managerId: number;
  fileName: string;
  saved: boolean;
  stage: string;
  callResult: string;
  callStatus: string;
}
export interface HubData {
  data: StepRecordingResponse | undefined;
  status: HubStatus | HubStatus.Success;
}
