type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

function ConfirmButton(props: ButtonProps) {
  return (
    <button
      className="py-1 px-3 rounded-full 
                 whitespace-nowrap
                 bg-primaryPurple hover:bg-primaryPurpleDark active:bg-primaryPurpleDarkest w-full border border-transparentPurple 
                 disabled:bg-primaryPurpleInactive disabled:border-primaryPurpleInactive 
                 disabled:cursor-not-allowed transition-all text-white shadow-button"
      {...props}
    >
      {props.children}
    </button>
  );
}

export default ConfirmButton;
