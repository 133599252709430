import React from "react";
import * as ReactDOM from "react-dom";

interface PopoutProps extends React.HTMLAttributes<HTMLElement> {
  positioningElement: HTMLElement | null;
  show: boolean;
}

const PopoutMenu: React.FC<PopoutProps> = (props) => {
  const fixedClasses = `absolute bg-spaceGray2 
     border-0 
     shadow-outline text-black 
     transition-opacity duration-250 `;
  let top = 0;
  let left = 0;

  const [classes, setClasses] = React.useState(fixedClasses + " opacity-0");

  React.useEffect(() => {
    setClasses(fixedClasses + " opacity-100");
  }, [fixedClasses]);

  if (props.positioningElement) {
    const bodyRect = document.body.getBoundingClientRect();
    let elemRect = props.positioningElement.getBoundingClientRect() as DOMRect;

    top = elemRect.top - bodyRect.top;
    left = elemRect.left - bodyRect.left + elemRect.width;
  }

  return ReactDOM.createPortal(
    props.show && (
      <div className={classes} style={{ top: top, left: left }}>
        {props.children}
      </div>
    ),
    document.body
  );
};

export default PopoutMenu;
