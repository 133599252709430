import { Config } from "../config";
import { pick } from "../utils/Conversion";
let user = JSON.parse(localStorage.getItem("user")!);
export class AreaCode {
    areaCode: number;
    timeZoneDifference: number;
    extraRestriction: number;
    stateId: number | null;
  
    constructor(areaCode: number, timeZoneDifference: number, extraRestriction: number, stateId: number) {
        this.areaCode = areaCode;
        this.timeZoneDifference = timeZoneDifference;
        this.extraRestriction = extraRestriction;
        this.stateId = stateId;
    }
}

export const fetchAreaCodes = async () => {
    let url = new URL(`${Config.platformwebapi_url}/api/donotcall/areacodes`);
    const response = await fetch(url.toString(), {
      headers: { Authorization: "Bearer " + user.bearerToken },
    });
  
    const responseJson = await response.json();
    let areaCodes: AreaCode[] = [];
    for (let v of responseJson) {
      const state = pick<AreaCode>(v, {
        areaCode: Number,
        timeZoneDifference: Number,
        extraRestriction: Number,
        stateId: Number,
      });
  
      areaCodes.push(state);
    }
    return areaCodes;
  };