/* tslint:disable */
/* eslint-disable */
/**
 * Platform WebAPI
 * API Endpoints used for Arbeit One
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CallListRenewRequest,
    CallListRenewRequestFromJSON,
    CallListRenewRequestToJSON,
    ContactListCreateInfo,
    ContactListCreateInfoFromJSON,
    ContactListCreateInfoToJSON,
    ContactListMapping,
    ContactListMappingFromJSON,
    ContactListMappingToJSON,
    ModelCallList,
    ModelCallListFromJSON,
    ModelCallListToJSON,
    PreviewContactList,
    PreviewContactListFromJSON,
    PreviewContactListToJSON,
} from '../models';

export interface ApiCallListGeneratePostRequest {
    contactListCreateInfo?: ContactListCreateInfo;
}

export interface ApiCallListGetRequest {
    clientId?: number;
}

export interface ApiCallListIdClosePostRequest {
    id: number;
    modelCallList?: ModelCallList;
}

export interface ApiCallListIdRenewPostRequest {
    id: number;
    callListRenewRequest?: CallListRenewRequest;
}

export interface ApiCallListPreviewPostRequest {
    fileSelect?: Blob;
}

export interface ApiCallListPreviewgeneratePostRequest {
    contactListMapping?: ContactListMapping;
}

export interface ApiCallListUploadPostRequest {
    fileSelect?: Blob;
}

/**
 * 
 */
export class CallListApi extends runtime.BaseAPI {

    /**
     * Generates an Account List from permanent accounts based on a filter and a mapping from PermanentAccountSchema to Standard AccountField.
     */
    async apiCallListGeneratePostRaw(requestParameters: ApiCallListGeneratePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/CallList/generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactListCreateInfoToJSON(requestParameters.contactListCreateInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Generates an Account List from permanent accounts based on a filter and a mapping from PermanentAccountSchema to Standard AccountField.
     */
    async apiCallListGeneratePost(requestParameters: ApiCallListGeneratePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiCallListGeneratePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCallListGetRaw(requestParameters: ApiCallListGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/CallList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCallListGet(requestParameters: ApiCallListGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiCallListGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCallListIdClosePostRaw(requestParameters: ApiCallListIdClosePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCallListIdClosePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/CallList/{id}/close`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelCallListToJSON(requestParameters.modelCallList),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCallListIdClosePost(requestParameters: ApiCallListIdClosePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiCallListIdClosePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCallListIdRenewPostRaw(requestParameters: ApiCallListIdRenewPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCallListIdRenewPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/CallList/{id}/renew`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CallListRenewRequestToJSON(requestParameters.callListRenewRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCallListIdRenewPost(requestParameters: ApiCallListIdRenewPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiCallListIdRenewPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCallListPreviewPostRaw(requestParameters: ApiCallListPreviewPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.fileSelect !== undefined) {
            formParams.append('fileSelect', requestParameters.fileSelect as any);
        }

        const response = await this.request({
            path: `/api/CallList/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCallListPreviewPost(requestParameters: ApiCallListPreviewPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiCallListPreviewPostRaw(requestParameters, initOverrides);
    }

    /**
     * Generates a preview of the account list with data from permanent accounts based on a filter and a mapping from PermanentAccountSchema to Standard   AccountField.
     */
    async apiCallListPreviewgeneratePostRaw(requestParameters: ApiCallListPreviewgeneratePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PreviewContactList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/CallList/previewgenerate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactListMappingToJSON(requestParameters.contactListMapping),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewContactListFromJSON(jsonValue));
    }

    /**
     * Generates a preview of the account list with data from permanent accounts based on a filter and a mapping from PermanentAccountSchema to Standard   AccountField.
     */
    async apiCallListPreviewgeneratePost(requestParameters: ApiCallListPreviewgeneratePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PreviewContactList> {
        const response = await this.apiCallListPreviewgeneratePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCallListUploadPostRaw(requestParameters: ApiCallListUploadPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.fileSelect !== undefined) {
            formParams.append('fileSelect', requestParameters.fileSelect as any);
        }

        const response = await this.request({
            path: `/api/CallList/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCallListUploadPost(requestParameters: ApiCallListUploadPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiCallListUploadPostRaw(requestParameters, initOverrides);
    }

}
