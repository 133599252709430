export enum MessageIntentType {
  None = 1,
  OptIn = 2,
  OptOut = 3,
  Help = 4,
}

export type InboundTextMessage = {
  id: string | number;
  phoneNumber: string;
  date: string;
  message: string;
  messageIntent: MessageIntentType;
};
