import { ColumnFilter, ColumnID } from "../interfaces/reports";
import * as Asserts from "../utils/Asserts";
import moment from "moment";
import { pick } from "../utils/Conversion";
import { Config } from "../config";

export type CDRRow = {
  phoneNumber: number;
  startTime: string;
  answerTime: string;
  finishTime: string;
  duration: number;
  billedDuration: number;
  minuteCost: number;
  cost: number;
  callTypeName: string;
  callerId: string;
  callerIdType: string;
  contactList: string;
  campaignName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  ssn: string;
  city: string;
  state: string;
  zipCode: string;
  externalId: string;
  callDisposition: string;
  transferred: string;
};

type CDRRequestBody = {
  numRows: number;
  clientId: number;
  utcOffset?: number;
};

type CDRFilter = {
  [Property in ColumnID]?: any;
};

type CDRRequest = CDRRequestBody & CDRFilter;

export type CDRProgress = {
  reportArchiveId: number;
  clientId: number;
  reportTypeId: number;
  reportStatusId: number;
  created: Date;
  url: string;
  numberOfRecords: number;
  downloadCount: number;
  lastDownload: Date;
  progress: number;
};

let user = JSON.parse(localStorage.getItem("user")!);

export const fetchCDRProgress = async (clientId: number) => {
  let fetchProgress = new URL(`${Config.platformwebapi_url}/api/reports/cdrprogress`);
  let requestBody: number = clientId;

  const response = await fetch(fetchProgress.toString(), {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  const progress: CDRProgress[] = [];
  const responseJson = await response.json();
  for (let x of responseJson) {
    const result = pick<CDRProgress>(x, {
      clientId: Number,
      created: (x) => new Date(x),
      downloadCount: Number,
      lastDownload: (x) => new Date(x),
      numberOfRecords: Number,
      progress: Number,
      reportArchiveId: Number,
      reportTypeId: Number,
      reportStatusId: Number,
      url: String,
    });

    progress.push(result);
  }

  return progress;
};

export async function startExportJob(filters: ColumnFilter[], count: number, clientId: number) {
  const url = new URL(`${Config.platformwebapi_url}/api/reports/export`);
  let requestBody: CDRRequest = { numRows: count, clientId: clientId, utcOffset: new Date().getTimezoneOffset() };

  filters.forEach((filter) => {
    switch (filter.type) {
      case "keyword":
        requestBody[filter.columnId] = filter.keyword;
        break;

      case "constrained-keyword":
        requestBody[filter.columnId] = filter.choice;
        break;

      case "multiple-constrained-keyword":
        const ids: number[] = Array.from(filter.choices.keys())
        requestBody[filter.columnId] = ids;
        break;

      case "multiple-constrained-options":
        const options: number[] = Array.from(filter.choices.keys())
        requestBody[filter.columnId] = options;
        break;

      case "date-range":
        {
          const parsedStartDay = moment(`${filter.startDay} ${filter.startTime}`, "YYYY-MM-DD hh:mm:ss");
          const parsedEndDay = moment(`${filter.endDay} ${filter.endTime}`, "YYYY-MM-DD hh:mm:ss");
          let filterBody = {
            filterName: { type: 1 },
            beginDateTime: parsedStartDay.utc().format(),
            endDateTime: parsedEndDay.utc().format(),
          };
          requestBody[filter.columnId] = filterBody;
        }
        break;

      case "currency-range":
      case "numeric-range":
        {
          let filterBody = { filterName: { type: 2 }, begin: filter.start, end: filter.end };
          requestBody[filter.columnId] = filterBody;
        }
        break;

      default:
        Asserts.assertNever(filter);
    }
  });

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
}

export const fetchCDR = async (filters: ColumnFilter[], count: number, clientId: number) => {
  let fetchRowsURL = new URL(`${Config.platformwebapi_url}/api/reports/cdr`);
  let fetchCountURL = new URL(`${Config.platformwebapi_url}/api/reports/cdrcount`);

  let requestBody: CDRRequest = { numRows: count, clientId: clientId };

  filters.forEach((filter) => {
    switch (filter.type) {
      case "keyword":
        requestBody[filter.columnId] = filter.keyword;
        break;

      case "constrained-keyword":
        requestBody[filter.columnId] = filter.choice;
        break;

      case "multiple-constrained-keyword": 
        const ids: number[] = Array.from(filter.choices.keys())
        requestBody[filter.columnId] = ids;
        break;

      case "multiple-constrained-options": 
        const options: number[] = Array.from(filter.choices.keys())
        requestBody[filter.columnId] = options;
        break;

      case "date-range":
        {
          const parsedStartDay = moment(`${filter.startDay} ${filter.startTime}`, "YYYY-MM-DD hh:mm:ss");
          const parsedEndDay = moment(`${filter.endDay} ${filter.endTime}`, "YYYY-MM-DD hh:mm:ss");
          let filterBody = {
            filterName: { type: 1 },
            beginDateTime: parsedStartDay.utc().format(),
            endDateTime: parsedEndDay.utc().format(),
          };
          requestBody[filter.columnId] = filterBody;
        }
        break;

      case "currency-range":
      case "numeric-range":
        {
          let filterBody = { filterName: { type: 2 }, begin: filter.start, end: filter.end };
          requestBody[filter.columnId] = filterBody;
        }
        break;

      default:
        Asserts.assertNever(filter);
    }
  });

  const rowsResponse = await fetch(fetchRowsURL.toString(), {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  const countResponse = await fetch(fetchCountURL.toString(), {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  const getCallDispositionString = (cr: number) => {
    switch (cr) {
      case 1: 
        return "Answered by Machine";
      case 2: 
        return "Answered by Person";
      case 3:
        return "Not Answered";
      case 4:
        return "Failed";
      default:
        return "";
    }
  }

  const getTransferredString = (cr: number) => {
    switch (cr) {
      case 1: 
        return "Transferred";
      case 2:
        return "Not Transferred";
      default:
        return "";
    }
  }

  const responseText = await countResponse.text();
  const responseJson = await rowsResponse.json();
  let reportRows: CDRRow[] = [];

  for (let v of responseJson) {
    const phoneInfo = pick<CDRRow>(v, {
      phoneNumber: Number,
      startTime: (v) => moment(v).format("YYYY-MM-DD hh:mm:ss A"),
      answerTime: (v) => moment(v).format("YYYY-MM-DD hh:mm:ss A"),
      finishTime: (v) => moment(v).format("YYYY-MM-DD hh:mm:ss A"),
      duration: Number,
      billedDuration: Number,
      minuteCost: Number,
      cost: Number,
      callTypeName: String,
      callerId: String,
      callerIdType: String,
      contactList: String,
      campaignName: String,
      firstName: String,
      lastName: String,
      middleName: String,
      dateOfBirth: String,
      ssn: String,
      city: String,
      state: String,
      zipCode: String,
      externalId: String,
      callDisposition: (v) => getCallDispositionString(v),
      transferred: (v) => getTransferredString(v),
    });

    reportRows.push(phoneInfo);
  }

  return { total: parseInt(responseText), rows: reportRows };
};
