import * as React from "react";
import { MessageStepType } from "../../enums/messages";
import { MessageStep } from "../../interfaces/messages";
import { FileInput } from "../../shared/input";
import closeIcon from "../../images/close_icon.svg";
import ConfirmButton from "../../shared/buttons/confirm";

export const UploadAudio = (props: { data: MessageStep; onSave: Function; onClose: Function }) => {
  const [enableSave, setEnableSave] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | null>(null);
  const [messageStep, setMessageStep] = React.useState<MessageStep>();

  const audioPlayer = React.useRef(new Audio());

  const fillFields = React.useCallback(() => {
    if (props.data) {
      const editedStep = props.data;
      const id = editedStep.messageId?.toString() || "0";
      audioPlayer.current.src = editedStep.fileName;
      audioPlayer.current.id = id;
      audioPlayer.current.play();
    }
  }, [props.data]);

  React.useEffect(() => {
    fillFields();
  }, [props.data, fillFields]);

  const handleFilePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    let customTextObj = new MessageStep();
    const files = e.target.files;

    Array.from(files!).forEach((file) => {
      const objectURL = URL.createObjectURL(files![0]);
      audioPlayer.current.src = objectURL;
      audioPlayer.current.play();
      audioPlayer.current.id = file.name;
      customTextObj.value = file.name;
      customTextObj.file = files![0];
      customTextObj.isDirty = true;
      setMessageStep(customTextObj);
      setFile(files![0]);
      setEnableSave(!!objectURL);
    });
  };

  const handleSave = () => {
    let customTextObj = messageStep!;
    customTextObj.messageStepType = MessageStepType.UploadedSoundFile;
    customTextObj.value = audioPlayer.current.id;
    customTextObj.fileName = audioPlayer.current.src;
    customTextObj.isDirty = true;
    customTextObj.file = file;

    props.onSave(customTextObj);
  };

  const handleClose = () => {
    props.onClose(true);
  };

  return (
    <div
      className="
            mt-4 justify-around
            flex w-full bg-white 
            shadow-container flex-wrap opacity-100 
            h-auto py-8 px-6"
    >
      <div className="w-1/3">
        <FileInput
          title="Upload Audio File"
          name="fileUpload"
          id="fileUpload"
          accept=".mp3,.mp4,.wav,.m4a"
          onChange={handleFilePreview}
        />
      </div>
      <div className="items-start mt-10 mr-4 ml-4">
        <audio controls autoPlay ref={audioPlayer}></audio>
      </div>
      <div className="w-1/4 ml-4  self-center">
        <ConfirmButton disabled={!enableSave} onClick={handleSave}>
          Save
        </ConfirmButton>
      </div>
      <img alt="" className="cursor-pointer relative block -top-16" src={closeIcon} onClick={handleClose} />
    </div>
  );
};
