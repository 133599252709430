import React from "react";
import * as Asserts from "../utils/Asserts";
import {
  deleteMessageObj,
  fetchGetMessageSteps,
  fetchInUseFields,
  fetchMessagesList,
  fetchVoices,
  postMessage,
  fetchPreviewMessage,
} from "../api/messages";
import CancelButton from "../shared/buttons/cancel";
import ConfirmButton from "../shared/buttons/confirm";
import { TextInput } from "../shared/input";
import { formatDate } from "../utils/DateUtils";
import { Modal } from "../shared/modal";
import { Message, MessageStep, TtsField, TtsVoice } from "../interfaces/messages";
import { MessageStatus, MessageStepType } from "../enums/messages";
import { RecordPhoneCall } from "./phonecall";
import { UploadAudio } from "./audio";
import { CustomText } from "./customtext";
import { FieldText } from "./fieldtext";
import { MessageForm } from "../types/messages";
import { ActionCell } from "../shared/entitytable";
import { Popover } from "react-tiny-popover";
import { Cell, ColumnName, ColumnsRow, Row, Table, TitleRow } from "../shared/table";
import { LoaderSpinner } from "../shared/loaderspinner";
import alertIcon from "../images/alert_circle_outline_icon.svg";
import loadingIcon from "../images/loading_audio_icon.svg";
import playIcon from "../images/play_circle_icon.svg";
import stopIcon from "../images/stop_circle_outline_icon.svg";
import { useErrorHandling } from "../apiError";

type MessageAction = "Edit" | "Delete";

function ExistingMessageStep({ index, text, content }: { index: number; text: string; content: JSX.Element }) {
  const [showPopover, setShowPopover] = React.useState<boolean>(false);

  return (
    <Popover
      isOpen={showPopover}
      content={<div onClick={() => setShowPopover(false)}>{content}</div>}
      onClickOutside={() => setShowPopover(false)}
      positions={["bottom"]}
      align="start"
    >
      <div key={index}>
        <span
          key={index}
          id={index.toString()}
          className="select-none border-primaryPurple border-2 border-solid rounded-md inline-block mr-1 p-3 cursor-pointer responsive-width truncate hover:bg-primaryPurple hover:text-white"
          onClick={() => setShowPopover(!showPopover)}
        >
          {text}
        </span>
      </div>
    </Popover>
  );
}

export const Messages = (props: { clientId: number }) => {
  const audioElem = React.useRef(new Audio());
  const triggerError = useErrorHandling();

  //================
  // Hooks
  //================

  // Api
  const [messageList, setMessageList] = React.useState<MessageForm[]>([]);
  const [fieldTextsInUse, setFieldTextsInUse] = React.useState<TtsField[]>([]);
  const [voicesTts, setVoicesTts] = React.useState<TtsVoice[]>([]);

  // Form Fields
  const [messageName, setMessageName] = React.useState<string>("");

  // UI State
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [showMessageStepForm, setShowMessageStepForm] = React.useState<boolean>(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = React.useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState<boolean>(false);
  const [showNewMessageStepPopover, setShowNewMessageStepPopover] = React.useState<boolean>(false);
  const [showErrorMessagePreviewModal, setShowErrorMessagePreviewModal] = React.useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(true);

  const [selectedMessageStepType, setSelectedMessageStepType] = React.useState<MessageStepType | null>(null);
  const [selectedMessageStep, setSelectedMessageStep] = React.useState<MessageStep | null>(null);
  const [selectedMessage, setSelectedMessage] = React.useState<Message | null>(null);

  const [messagePreviewArray, setMessagePreviewArray] = React.useState<MessageStep[]>([]);

  const [audioStatusPlay, setAudioStatusPlay] = React.useState<boolean>(false);
  const [audioToPlay, setAudioToPlay] = React.useState<string>("");

  // Consts
  const messageStepsArray = messagePreviewArray;
  const enableSaving =
    (!!!selectedMessageStep || selectedMessageStep) &&
    !!messageName &&
    messagePreviewArray &&
    messagePreviewArray.length;

  //====================
  // API Functions
  //====================

  const loadMessagesList = React.useCallback(async () => {
    const data: MessageForm[] = await fetchMessagesList(props.clientId);
    let fieldsData = await fetchInUseFields(props.clientId);
    let voiceData = await fetchVoices();

    fieldsData.unshift({ id: 0, value: "Select" });
    voiceData.unshift({ id: 0, name: "Select" });

    setMessageList(data);
    setFieldTextsInUse(fieldsData);
    setVoicesTts(voiceData);
    setShowLoadingSpinner(false);
  }, [props.clientId]);

  React.useEffect(() => {
    loadMessagesList();
  }, [loadMessagesList]);

  //====================
  // User action functions
  //====================

  const editMessageSelected = async (messageId: number, name: string) => {
    const messageSelectedSteps = await fetchGetMessageSteps(messageId);
    const messageForm = messageList.find((item) => item.id === messageId);
    let updatedMessagePreviewArray: MessageStep[] = [];

    setMessagePreviewArray(updatedMessagePreviewArray);

    messageSelectedSteps.forEach((step: MessageStep) => {
      updatedMessagePreviewArray.push(step);
    });

    setSelectedMessage(messageForm!);
    setMessageName(name);
    setMessagePreviewArray(updatedMessagePreviewArray);
    setShowForm(true);
  };

  const prepareMessageToDelete = (messageId: number) => {
    const messageForm = messageList.find((item) => item.id === messageId);

    if (messageForm) {
      const campaigns = messageForm.campaigns;

      if (campaigns && campaigns.length) {
        setShowCannotDeleteModal(true);
      } else {
        setShowConfirmDeleteModal(true);
      }
    }

    setSelectedMessage(messageForm!);
  };

  const editMessageStep = (messageStepType: number, stepIndex: number) => {
    const step = messagePreviewArray[stepIndex];
    setSelectedMessageStep(step);
    openFormFields(messageStepType);
  };

  const deleteMessageStep = (stepIndex: number) => {
    const formerArray = messagePreviewArray;
    const step = formerArray[stepIndex];
    const updatedArray = formerArray.filter((element) => element !== step);

    setMessagePreviewArray(updatedArray);
    setSelectedMessageStep(null);
  };

  //====================
  // Form Functions
  //====================

  const openFormFields = (step: MessageStepType) => {
    setShowMessageStepForm(true);
    setShowNewMessageStepPopover(false);
    setSelectedMessageStepType(step);
  };

  const formatFields = (messageSteps: Message) => {
    return messageSteps.messageSteps.map((step) => {
      return {
        ...step,
        phoneNumber: step.phoneNumber ? step.phoneNumber : undefined,
        ttsFieldId: step.ttsFieldId === 0 ? undefined : step.ttsFieldId,
        ttsVoiceId: step.ttsVoiceId === 0 ? undefined : step.ttsVoiceId,
      };
    });
  };

  //===============
  // Handlers
  //===============

  const handleContainerStatus = () => {
    setShowForm(!showForm);
    setSelectedMessageStep(null);
    setMessagePreviewArray([]);
    setMessageName("");
    setSelectedMessage(null);
  };

  const handleCloseStepEditor = () => {
    setSelectedMessageStepType(0);
    setShowNewMessageStepPopover(false);
    setShowMessageStepForm(false);
  };

  const handleSubmit = async () => {
    let files: Array<File> = new Array<File>();
    let message = selectedMessage
      ? selectedMessage
      : { id: 0, name: "", clientId: 0, messageStatusId: 0, messageStatusName: "", messageSteps: [], campaigns: "" };
    message = {
      ...message,
      name: messageName,
      clientId: props.clientId,
      messageSteps: messageStepsArray,
    };

    message.messageSteps.forEach((step) => {
      if (step.messageStepType === MessageStepType.UploadedSoundFile && step.isDirty) {
        files.push(step.file);
      }
    });

    message.messageSteps = formatFields(message);

    const requisitionStatus = await postMessage(message, files);
    if (requisitionStatus?.ok) {
      loadMessagesList();
      setShowForm(false);
      setSelectedMessageStep(null);
      setSelectedMessage(null);
      setSelectedMessageStepType(0);
    }
  };

  const handleAddInfo = (messageInfo: MessageStep) => {
    let updatedMessagePreview = messagePreviewArray;

    if (selectedMessageStep) {
      const stepEdited = updatedMessagePreview.find((item) => +item.id! === +selectedMessageStep.id!);

      if (stepEdited) {
        const updatedStep: MessageStep = {
          ...stepEdited,
          value: messageInfo.value,
          clientsFileName: messageInfo.value,
          ttsModeId: messageInfo.ttsModeId,
          ttsFieldId: messageInfo.ttsFieldId === 0 ? undefined : messageInfo.ttsFieldId,
          ttsVoiceId: messageInfo.ttsVoiceId === 0 ? undefined : messageInfo.ttsVoiceId,
          phoneNumber: messageInfo.phoneNumber,
        };
        updatedMessagePreview[stepEdited.stepOrder] = updatedStep;
        setSelectedMessageStepType(updatedStep.messageStepType!);
      }
    } else {
      const updatedStep: MessageStep = {
        ...messageInfo,
        stepOrder: 0,
        fileName: (messageInfo.messageStepType === MessageStepType.RecordedWithPhone || messageInfo.messageStepType === MessageStepType.UploadedSoundFile) ? messageInfo.fileName : "",
        messageId: undefined,
        ttsFieldId: messageInfo.ttsFieldId === 0 ? undefined : messageInfo.ttsFieldId,
        ttsVoiceId: messageInfo.ttsVoiceId === 0 ? undefined : messageInfo.ttsVoiceId,
      };
      updatedMessagePreview.push(updatedStep);
    }
    setShowMessageStepForm(false);
  };

  const clickAddMessageStep = () => {
    setShowNewMessageStepPopover(!showNewMessageStepPopover);
    setSelectedMessageStep(null);
    setSelectedMessageStepType(0);
  };

  const clickDeleteMessage = async () => {
    const requisitionStatus = await deleteMessageObj(selectedMessage!, messageList);
    if (requisitionStatus) {
      loadMessagesList();
      setShowConfirmDeleteModal(false);
    }
  };

  //===============
  // UI Elements
  //===============

  const getMessageStepForm = () => {
    switch (selectedMessageStepType) {
      case MessageStepType.RecordedWithPhone:
        return <RecordPhoneCall data={selectedMessageStep!} onSave={handleAddInfo} onClose={handleCloseStepEditor} />;

      case MessageStepType.UploadedSoundFile:
        return <UploadAudio data={selectedMessageStep!} onSave={handleAddInfo} onClose={handleCloseStepEditor} />;

      case MessageStepType.FixedTTS:
        return (
          <CustomText
            data={selectedMessageStep!}
            voiceData={voicesTts}
            onSave={handleAddInfo}
            onClose={handleCloseStepEditor}
          />
        );

      case MessageStepType.FieldTTS:
        return (
          <FieldText
            data={selectedMessageStep!}
            fieldsData={fieldTextsInUse}
            voiceData={voicesTts}
            onSave={handleAddInfo}
            onClose={handleCloseStepEditor}
          />
        );
    }
  };

  const confirmDeleteModal = (
    <Modal
      title={"Are you sure?"}
      content={
        <div className="flex flex-col justify-center items-center text-primaryDarkGrey">
          <p className="px-20 text-lg text-center">You wish to permanently delete this message?</p>
        </div>
      }
      buttons={
        <div className="flex w-full justify-around mt-7 ">
          <div className="w-60">
            <CancelButton onClick={clickDeleteMessage}>Yes, Delete</CancelButton>
          </div>
          <div className="w-60">
            <CancelButton onClick={() => setShowConfirmDeleteModal(false)}>Cancel</CancelButton>
          </div>
        </div>
      }
      buttonClose={() => setShowConfirmDeleteModal(false)}
    />
  );

  const cannotDeleteModal = (
    <Modal
      title={"Error deleting"}
      content={
        <div className="flex flex-col justify-center items-center text-primaryDarkGrey">
          <p className="px-20 mt-8 text-lg text-center">A message belonging to a campaign cannot be deleted.</p>
        </div>
      }
      buttons={
        <div className="flex w-full justify-around mt-7 ">
          <div className="w-60">
            <CancelButton onClick={() => setShowCannotDeleteModal(false)}>Ok</CancelButton>
          </div>
        </div>
      }
      buttonClose={() => setShowCannotDeleteModal(false)}
    />
  );

  const errorMessagePreviewModal = (
    <Modal
      title={"Error on playing"}
      content={
        <div className="flex flex-col justify-center items-center text-primaryDarkGrey">
          <p className="px-20 mt-8 text-lg text-center">The preview for this message was not generated yet.</p>
        </div>
      }
      buttons={
        <div className="flex w-full justify-around mt-7 ">
          <div className="w-60">
            <CancelButton onClick={() => setShowErrorMessagePreviewModal(false)}>Ok</CancelButton>
          </div>
        </div>
      }
      buttonClose={() => setShowErrorMessagePreviewModal(false)}
    />
  );

  const messageItemsArray = messageStepsArray.map((step, index) => {
    if (step.messageStepType === undefined) {
      throw new Error("Attempting to map a message step with an undefined type!");
    }

    const stepType = step.messageStepType;

    const popoverContent = (
      <div className="bg-white bg-origin-padding shadow-window rounded-md">
        <ul className="cursor-pointer">
          <li
            className="rounded-t-md p-3 hover:bg-primaryPurple hover:text-white transition-colors"
            onClick={() => editMessageStep(stepType, index)}
          >
            Edit
          </li>
          <li
            className="rounded-b-md p-3 hover:bg-primaryPurple hover:text-white transition-colors"
            onClick={() => deleteMessageStep(index)}
          >
            Delete
          </li>
        </ul>
      </div>
    );
    return <ExistingMessageStep index={index} text={(stepType === MessageStepType.UploadedSoundFile && step.clientsFileName && step.clientsFileName.length > 0)? step.clientsFileName! : step.value } content={popoverContent} />;
  });

  const messagesForm = () => {
    return (
      <div>
        <span className="mb-4 block font-bold text-darkGrayTitle text-lg">New Message</span>
        <div className="w-1/4">
          <TextInput
            placeholder="Type message name"
            name="messageName"
            title="Message Name"
            onChange={(e) => setMessageName(e.target.value)}
            value={messageName}
          ></TextInput>
        </div>
        <div className="w-full">
          <span className="mb-3 block">Message Preview</span>
          <div className="w-full bg-white shadow-container flex-wrap opacity-100 h-auto py-8 px-6">
            <div className="w-full flex flex-wrap">
              {messageItemsArray}
              <Popover
                padding={5}
                isOpen={showNewMessageStepPopover}
                onClickOutside={() => setShowNewMessageStepPopover(false)}
                positions={["bottom"]}
                align={"start"}
                content={
                  <div className="bg-white bg-origin-padding shadow-window rounded-md">
                    <ul className="cursor-pointer">
                      <li
                        className="rounded-t-md p-3 hover:bg-primaryPurple hover:text-white transition-colors"
                        onClick={() => openFormFields(MessageStepType.RecordedWithPhone)}
                      >
                        Record from Phone call
                      </li>
                      <li
                        className="p-3 hover:bg-primaryPurple hover:text-white transition-colors"
                        onClick={() => openFormFields(MessageStepType.UploadedSoundFile)}
                      >
                        Upload Audio
                      </li>
                      <li
                        className="p-3 hover:bg-primaryPurple hover:text-white transition-colors"
                        onClick={() => openFormFields(MessageStepType.FixedTTS)}
                      >
                        Custom Text-to-Speech
                      </li>
                      <li
                        className="rounded-b-md p-3 hover:bg-primaryPurple hover:text-white transition-colors"
                        onClick={() => openFormFields(MessageStepType.FieldTTS)}
                      >
                        Field Text-to-Speech
                      </li>
                    </ul>
                  </div>
                }
              >
                <div className="w-1/4 self-center">
                  <ConfirmButton onClick={clickAddMessageStep}>Add Message Step</ConfirmButton>
                </div>
              </Popover>
            </div>
          </div>
        </div>
        {!showMessageStepForm ? (
          <div className="flex flex-row mt-8 mb-7">
            <div className="w-2/12 mr-5">
              <ConfirmButton disabled={!enableSaving} onClick={handleSubmit}>
                Save
              </ConfirmButton>
            </div>
            <div className="w-2/12">
              <CancelButton onClick={handleContainerStatus}>Cancel</CancelButton>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const handleAudioSelection = async (message: MessageForm) => {
    if (message.messageStatusId === MessageStatus.Ready) {
      const res = await fetchPreviewMessage(message);
      if (res) {
        if (!res.err) {
          const url: string = res.data[0];
          setSelectedMessage(message);
          setAudioStatusPlay(!audioStatusPlay);
          setAudioToPlay(url);
          audioElem.current.onended = (event) => {
            setAudioStatusPlay(false);
          }
        } else {
          if (res.err.errorInfo.includes("404"))
            setShowErrorMessagePreviewModal(true);
          else  
            triggerError(res.err);
        }
      }
    }
  };

  const getAudioIcon = (message: Message) => {
    if (selectedMessage && selectedMessage.id === message.id) {
      return audioStatusPlay ? stopIcon : playIcon;
    } else {
      return message.messageStatusId === (MessageStatus.Pending || MessageStatus.Processing)
        ? loadingIcon
        : message.messageStatusId === MessageStatus.Ready
        ? playIcon
        : alertIcon;
    }
  };

  const getAudioPlayerIcon = (message: MessageForm, index: number) => {
    let conditionalClass =
      message.messageStatusId === MessageStatus.Pending
        ? "animate-spin"
        : message.messageStatusId !== MessageStatus.Error
        ? "cursor-pointer"
        : "";

    const imageSrc = getAudioIcon(message);

    return (
      <div className="flex justify-center">
        <img
          className={conditionalClass}
          src={imageSrc}
          onClick={() => handleAudioSelection(message)}
          alt="Not Found"
        />
      </div>
    );
  };

  const audioPlayerElement = <audio autoPlay ref={audioElem} src={audioToPlay}></audio>;

  const messagesRow = (message: MessageForm, index: number) => {
    return (
      <Row id={index.toString()}>
        <Cell> {message.name}</Cell>
        <Cell> {message.messageStatusName}</Cell>
        <Cell> {message.campaigns}</Cell>
        <Cell> {formatDate(new Date(message.created))}</Cell>
        <Cell> {formatDate(new Date(message.updated))}</Cell>
        <Cell> {getAudioPlayerIcon(message, index)} </Cell>
        <ActionCell rowNum={index} actionHandler={handleRowAction} actions={["Edit", "Delete"]} />{" "}
      </Row>
    );
  };

  function handleRowAction(action: MessageAction, rowNum: number) {
    switch (action) {
      case "Edit":
        {
          const m = messageList[rowNum];
          if (m) {
            editMessageSelected(m.id, m.name);
          }
        }
        break;

      case "Delete":
        {
          const m = messageList[rowNum];
          if (m) {
            prepareMessageToDelete(m.id);
          }
        }
        break;

      default:
        Asserts.assertNever(action);
    }
  }

  return (
    <div className="h-full flex flex-col py-5 px-24 bg-white">
      {showCannotDeleteModal && cannotDeleteModal}
      {showConfirmDeleteModal && confirmDeleteModal}
      {audioStatusPlay && audioPlayerElement}
      {showErrorMessagePreviewModal && errorMessagePreviewModal}
      <div className="flex flex-col">
        <div className="flex flew-row justify-end">
          <div className="w-full pb-7 font-bold text-3xl text-primaryDarkGrey">Messages List</div>
          <div className="flex justify-end w-40 h-10 whitespace-nowrap">
            {!showForm ? <ConfirmButton onClick={handleContainerStatus}>Add Message</ConfirmButton> : null}
          </div>
        </div>
      </div>
      {showForm ? <div className="flex flex-col mb-5">{messagesForm()}</div> : null}
      {showMessageStepForm ? <div className="flex flex-col mb-5"> {getMessageStepForm()} </div> : null}
      <div className="sm:max-h-[350px] xl:max-h-[500px]">
        <Table>
          <thead>
            <TitleRow colSpan={7}> Messages </TitleRow>
            <ColumnsRow>
              <ColumnName> Name</ColumnName>
              <ColumnName> Status</ColumnName>
              <ColumnName> Campaigns</ColumnName>
              <ColumnName> Created</ColumnName>
              <ColumnName> Updated</ColumnName>
              <ColumnName> Preview</ColumnName>
              <ColumnName></ColumnName>
            </ColumnsRow>
          </thead>
          <tbody>
            {!showLoadingSpinner ? messageList.map(messagesRow) : <LoaderSpinner tableLength={6}></LoaderSpinner>}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
