import { fetchData, getRequestInfo } from "../apifunctions";
import { ErrorCause } from "../errorboundaries";
import { MessageTextErrors, TextMessage } from "../interfaces/textmessages";
import { Config } from "../config";
import { pick } from "../utils/Conversion";

const user = JSON.parse(localStorage.getItem("user")!);

export const saveMessage = async (clientId: Number, message: TextMessage) => {
  const url = new URL(`${Config.platformwebapi_url}/api/messagetext/${message.id}/edit`);
  const config = {
    method: "POST",
    body: JSON.stringify({
      id: message.id,
      name: message.name,
      message: message.message,
      clientId: clientId,
    }),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  };
  const response = await fetchData(url.toString(), config);

  if (!response.ok) {
    return checkErrorType(response);
  }
  return getRequestInfo<[]>([], response);
};

const checkErrorType = (resp: Response) => {
  return resp.json().then((r) => {
    if (r?.statusCode && r?.message) {
      return {
        data: [],
        err: {
          error: new Error(),
          errorInfo: `${r.message}`,
          errorCause: r.statusCode === MessageTextErrors.VALIDATION ? ErrorCause.NONE : ErrorCause.OPERATION,
        },
      };
    } else {
      getRequestInfo<[]>([], resp);
    }
  });
};

export const deleteMessage = async (clientId: number, message: TextMessage) => {
  const url = new URL(`${Config.platformwebapi_url}/api/messagetext/${message.id}/delete`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify({
      id: message.id,
      name: message.name,
      message: message.message,
      clientId: clientId,
    }),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

export const fetchMessages = async (clientId: number) => {
  const url = new URL(`${Config.platformwebapi_url}/api/messagetext`);
  const params = new URLSearchParams();

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();

  let messages: TextMessage[] = [];
  for (let v of responseJson) {
    const value = pick<TextMessage>(v, {
      id: Number,
      name: String,
      clientId: Number,
      message: String,
      created: String,
      updated: String,
      campaigns: String,
    });
    messages.push({
      id: value.id,
      clientId: value.clientId,
      message: value.message,
      created: value.created,
      name: value.name,
      updated: value.updated,
      campaigns: value.campaigns.length !== 0 ? value.campaigns : "--",
    });
  }

  return messages;
};
