import { ColumnData, ColumnID } from "../../interfaces/reports";

// prettier-ignore
export const columns = new Map<ColumnID, ColumnData>([
  ["startTime", { displayName: "Start Time", id: "startTime", filterType: "date-range"}],
  ["finishTime", {displayName: "Finish Time", id: "finishTime", filterType: "date-range" }],
  ["answerTime", {displayName: "Answer Time", id: "answerTime", filterType: "date-range" }],
  ["duration",    {displayName: "Duration", id: "duration", filterType: "numeric-range" }],
  ["firstName", { displayName: "First Name", id: "firstName",  filterType: "keyword"}],
  ["middleName", {displayName: "Middle Name", id: "middleName", filterType: "keyword" }],
  ["lastName", { displayName: "Last Name", id: "lastName", filterType: "keyword" }],
  ["billedDuration", { displayName: "Billed Duration", id: "billedDuration", filterType: "numeric-range" }],
  ["cost", { displayName: "Cost", id: "cost", filterType: "currency-range" }],
  ["minuteCost", { displayName: "Minute Cost", id: "minuteCost", filterType: "currency-range" }],
  ["callTypeName", { displayName: "Call Type", id: "callTypeName", filterType: "constrained-keyword" }],
  ["callerId", { displayName: "Caller ID", id: "callerId", filterType: "keyword" }],
  ["callerIdTypeName", { displayName: "Caller ID Type", id: "callerIdTypeName", filterType: "constrained-keyword" }],
  ["phoneNumber", { displayName: "Phone Number", id: "phoneNumber", filterType: "keyword" }],
  ["state", { displayName: "State", id: "state", filterType: "keyword" }],
  ["city", { displayName: "City", id: "city", filterType: "keyword" }],
  ["zipCode", { displayName: "Zip Code", id: "zipCode", filterType: "keyword" }],
  ["ssn", { displayName: "SSN", id: "ssn", filterType: "keyword" }],
  ["externalId", { displayName: "External ID", id: "externalId", filterType: "keyword" }],
  ["dateOfBirth", {displayName: "Date of Birth", id: "dateOfBirth", filterType: "keyword" }],
  ["campaignName", { displayName: "Campaign", id: "campaignName", filterType: "constrained-keyword" }],
  ["calllistName", { displayName: "Contact List", id: "calllistName", filterType: "constrained-keyword" }],
  ["campaigns", { displayName: "Campaigns", id: "campaigns", filterType: "multiple-constrained-keyword" }],
  ["callDisposition", { displayName: "Call Disposition", id: "callDisposition", filterType: "multiple-constrained-options" }],
  ["transferred", { displayName: "Transferred", id: "transferred", filterType: "multiple-constrained-options" }],
]);

// prettier-ignore
export const externalColumnPositions = new Map<number, ColumnID>([
  [0, "phoneNumber"],
  [1, "startTime"],
  [2, "answerTime"],
  [3, "finishTime"],
  [4, "duration"],
  [5, "billedDuration"],
  [6, "minuteCost"],
  [7, "cost"],
  [8, "callTypeName"],
  [9, "callerId"],
  [10, "callerIdTypeName"],
  [11, "calllistName"],
  [12, "campaignName"],
  [13, "firstName"],
  [14, "lastName"],
  [15, "middleName"],
  [16, "dateOfBirth"],
  [17, "ssn"],
  [18, "city"],
  [19, "state"],
  [20, "zipCode"],
  [21, "externalId"],
  [22, "campaigns"],
  [23, "callDisposition"],
  [24, "transferred"]
]);

// prettier-ignore
export const internalColumnPositions = new Map<ColumnID, number>([
  ["phoneNumber", 0],
  ["startTime", 1],
  ["answerTime", 2],
  ["finishTime", 3],
  ["duration", 4],
  ["billedDuration", 5],
  ["minuteCost",6],
  ["cost", 7],
  ["callTypeName", 8],
  ["callerId", 9],
  ["callerIdTypeName", 10],
  ["calllistName", 11],
  ["campaignName", 12],
  ["firstName", 13],
  ["lastName", 14],
  ["middleName", 15],
  ["dateOfBirth", 16],
  ["ssn", 17],
  ["city", 18],
  ["state", 19],
  ["zipCode", 20],
  ["externalId", 21],
  ["campaigns", 22],
  ["callDisposition", 23],
  ["transferred", 24]
]);

export const CallDispositionData = [
  {
    name: "Answered by Machine",
    id: 1
  },
  {
    name: "Answered by Person",
    id: 2
  },
  {
    name: "Not Answered",
    id: 3
  },
  {
    name: "Failed",
    id: 4
  },
];

export const TransferedData = [
  {
    name: "Transfered",
    id: 1
  },
  {
    name: "Not Transfered",
    id: 2
  }
];
