import * as React from "react";


export const Stepper = (props: { steps: string[]; currentStep: number, onStepChange: (_: number) => void }) => {
    const activeClass = "rounded-xl h-6 mr-3 w-6 whitespace-nowrap text-lightGray2 border-0 bg-primaryPurple";
    const defaultClass = "rounded-xl h-6 mr-3 w-6 whitespace-nowrap text-lightGray2 border-1 border-solid border-lightGray2 bg-white";

    return (
        <div className="flex">
            <div className="flex overflow-hidden outline-0 relative box-content">
                {props.steps &&
                    props.steps.length &&
                    props.steps.map((step, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => props.onStepChange(index)}
                                className={
                                    `items-center flex h-18 w-auto
                                     after:content-['']
                                     after:flex-1
                                     after:ml-2 
                                     after:mr-2
                                     after:w-36
                                     after:h-1
                                     after:text-primaryDarkGrey
                                     after:bg-primaryDarkGrey
                                     last:after:content-none ` + (props.steps.length - 1 === index ? `` : `min-w-200`)
                                }
                            >
                                <span className={index <= props.currentStep ? activeClass : defaultClass}></span>
                                <span className={"cursor-pointer whitespace-nowrap text-lg " + (index === props.currentStep ? "text-primaryPurple" : "text-lightGray2")}>
                                    {step}
                                </span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
