import * as React from "react";
import { getCustomFields, getMappedFields } from "../../../api/upload";
import { CallListStatus } from "../../../enums/contactlist";
import { CustomField } from "../../../interfaces/accountfields";
import { CallListFile, CallListMapItem } from "../../../interfaces/contactlist";
import { CollapsibleComponent } from "../../../shared/collapsible";
import { CheckboxInput, SelectInput } from "../../../shared/input";
import { Cell, ColumnName, ColumnsRow, Row, Table } from "../../../shared/table";

export interface PreviewCallListResponse {
  headers: string[];
  rows: string[][];
}

export class AccountField {
  id: number;
  value: string;
  constructor(id: number = 0, value: string = "") {
    this.id = id;
    this.value = value;
  }
}

export const standardAccountFields: AccountField[] = [
  new AccountField(1, "First Name"),
  new AccountField(2, "Middle Name"),
  new AccountField(3, "Last Name"),
  new AccountField(4, "Date of Birth"),
  new AccountField(5, "SSN"),
  new AccountField(6, "Address"),
  new AccountField(7, "Address 2"),
  new AccountField(8, "City"),
  new AccountField(9, "State"),
  new AccountField(10, "Zip Code"),
  new AccountField(11, "External Id"),
];

export interface CheckboxField {
  id: number;
  label: string;
  value: number;
  isChecked: boolean;
}

export interface MappedConfig {
  ready: boolean;
  callListMapItems: CallListMapItem[];
  callListFiles: CallListFile[];
  phoneNumberSelected: CheckboxField | undefined;
}

export const MapFileComponent = (props: { records: PreviewCallListResponse; clientId: number; mappedOk: Function }) => {
  const [callList, setCallList] = React.useState<CallListFile[]>([]);
  const [callListMapItems, setCallListMapItems] = React.useState<CallListMapItem[]>([]);
  const [checkBoxesValues, setCheckBoxesValues] = React.useState<CheckboxField[]>([]);
  const [selectedCheckBox, setSelectedCheckBox] = React.useState<CheckboxField>();
  const [mappedItems, setMappedItems] = React.useState<MappedConfig>();

  //==============
  // API Callbacks
  //==============

  const loadFields = React.useCallback(async () => {

    // Fetch custom fields for this client
    const customFields = await getCustomFields(props.clientId);

    // Mix custom fields in with standard fields
    if (customFields) {
      customFields.forEach((customField) => {
        // Testing if the field is already included on the list
        const fieldIncluded = standardAccountFields.find((field) => field.id === customField.customIndex + 11);
        if (!!!fieldIncluded) {
          standardAccountFields.push(new AccountField(customField.customIndex + 11, customField.name));
        }
      });
    }

    // Transform the complete list of fields for use in mapping
    const callListItems: CallListMapItem[] = standardAccountFields.map((field): CallListMapItem => {
      return {
        accountField: field,
        selectedCallListField: [],
      };
    });

    // Auto mapping 
    const cl = getCallList(props.records);
    const mapping = await getMappedFields(props.clientId, props.records.headers);
    if (mapping && mapping.length > 0) {
      let my = callListItems.map( m => {
        const s = mapping.find(item => item.accountField.id === m.accountField.id);
        if (s) {
          const ind = s.selectedCallListField[0].fieldColumn;
          m.selectedCallListField = [cl![ind!]];
        }

        return m;
      });
      setCallListMapItems(my);
      // Set mapped checkboxes
      getCheckBoxes(cl!, mapping);
    }
    else {
      setCallListMapItems(callListItems);
      getCheckBoxes(cl!, undefined);
    }
    
  }, [props.clientId]);

  const checkValues = React.useCallback(() => {
    const checkboxChecked = !!checkBoxesValues.find((chk) => chk.isChecked === true);
    let config: MappedConfig = {
      ready: checkboxChecked,
      callListFiles: callList,
      callListMapItems: callListMapItems,
      phoneNumberSelected: selectedCheckBox,
    };
    setMappedItems(config);
  }, [callListMapItems, selectedCheckBox]);

  React.useEffect(() => {
    if (mappedItems) {
      props.mappedOk(JSON.parse(JSON.stringify(mappedItems)));
    }
  }, [mappedItems]);


  React.useEffect(() => {
    loadFields();
  }, [loadFields, props.records]);

  //React.useEffect(() => {
  //  getCheckBoxes(callList);
  //}, [callList]);

  React.useEffect(() => {
    checkValues();
  }, [checkValues]);

  //============
  // Table Items
  //============

  const getCallList = (list: PreviewCallListResponse) => {
    if (list) {
      let callListArray: CallListFile[] = [];
      Object.keys(list.headers).forEach((h) => {
        let cList: CallListFile = {
          fieldColumn: Number(h),
          fieldName: list.headers[+h],
          originalCallListId: 0,
          originalCallListName: "",
          callListStatusId: CallListStatus.New,
          phoneCount: 0,
          accountCount: 0,
          availableCount: 0,
          calledCount: 0,
          notCalledCount: 0,
          dncCount: 0,
          callListId: 0,
          callListStatusName: "",
          campaignId: 0,
          campaignName: "",
          created: new Date(),
          originalFileName: "",
        };

        callListArray.push(cList);
      });

      setCallList(callListArray);

      return callListArray;
    }
  };

  const getCheckBoxes = React.useCallback((callList: CallListFile[], autoMapping: CallListMapItem[] | null | undefined) => {
    if (callList && callList.length) {

      if (autoMapping && autoMapping.length > 0) {
        let checkBoxes = callList.map((item, index) => { 
          const p = autoMapping.find(item => item.accountField.id === 0)?.selectedCallListField.find(i => i.fieldColumn === item.fieldColumn);
          let cked : boolean = false;
          if (p) {
            cked = true;
          }
          return {
            id: index,
            label: item.fieldName,
            value: 0,
            isChecked: cked,
          };
        });
        setCheckBoxesValues(checkBoxes);
        
        let cBoxCked = checkBoxes.find((chk) => chk.isChecked === true);
        checkBoxes.map(c => {
          if (c.isChecked)
            setSelectedCheckBox(c);
        });

        checkValues();
        
      }
      else {
        let checkBoxes = callList.map((item, index) => { 
          return {
            id: index,
            label: item.fieldName,
            value: 0,
            isChecked: false,
          };
        });
        setCheckBoxesValues(checkBoxes);
      }
    }
  }, []);

  //============
  // Table Items - Form Elements
  //============

  const getCheckBoxesValues = (fields: CheckboxField[]) => {
    if (fields && fields.length) {
      let callListFields = fields.map((field, index) => {
        return (
          <CheckboxInput
            id={index.toString()}
            value={field.value}
            onChange={handleCheckboxClick}
            sliderlabel={field.label}
            roundedslider={false}
            checked={field.isChecked}
          />
        );
      });
      return callListFields;
    }
  };

  //============
  // Handlers
  //============

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedChecks: CheckboxField[] = JSON.parse(JSON.stringify(checkBoxesValues));
    updatedChecks[+e.target.id].isChecked = !updatedChecks[+e.target.id].isChecked;
    let selectedPhone = updatedChecks.find((chk) => +chk.id === +e.target.id);

    setCheckBoxesValues(updatedChecks);
    setSelectedCheckBox(selectedPhone);
  };

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let updatedList = JSON.parse(JSON.stringify(callListMapItems));
    updatedList[+e.target.id - 1].selectedCallListField = [callList[+e.target.value]];
    setSelectedCheckBox(undefined);
    setCallListMapItems(updatedList);
  };

  //============
  // UI Elements
  //============

  const getOptions = (list: CallListFile[]) => {
    let array = [new Option("Select...", "-1")];
    list?.forEach((item) => {
      if (item.fieldColumn !== undefined) {
        array.push(new Option(item.fieldName, item.fieldColumn.toString()));
      } else {
        throw new Error("Expected item to have a defined fieldColumn!");
      }
    });
    return array;
  };

  return (
    <div className="h-full w-full sm:h-[275px] 2xl:w-3/4 2xl:my-0 mx-auto self-center overflow-auto ">
      <Table>
        <thead className="w-full">
          <ColumnsRow>
            <ColumnName>Arbeit One Field Label</ColumnName>
            <ColumnName>Uploaded File Field Label</ColumnName>
          </ColumnsRow>
        </thead>
        <tbody>
          {callListMapItems.map((item, index) => {
            return (
              <Row key={index}>
                <Cell>{item.accountField.value}</Cell>
                <Cell>
                  <div className="mr-5 mb-5">
                    <SelectInput
                      id={item.accountField.id.toString()}
                      onChange={handleSelection}
                      value={!!item.selectedCallListField.length ? item.selectedCallListField[0].fieldColumn : "-1"}
                      options={getOptions(callList)}
                    />
                  </div>
                </Cell>
              </Row>
            );
          })}
          <Row>
            <Cell colSpan={2}>
              <div className="mr-5 cursor-default">
                <CollapsibleComponent
                  toggleOpened={true}
                  label={"Phone Numbers"}
                  description={"(Select all that apply)"}
                  data={getCheckBoxesValues(checkBoxesValues)}
                />
              </div>
            </Cell>
          </Row>
        </tbody>
      </Table>
    </div>
  );
};
