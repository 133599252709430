import { MouseEventHandler } from "react";
import ReactDOM from "react-dom";
import CancelButton from "../shared/buttons/cancel";
import { TextAreaInput } from "../shared/input";
import sadFritzIcon from "../images/sad_fritz_logo.svg";

export const modalContent = (message: string) => (
  <div className="h-[560px] w-[600px]  bg-white rounded-lg shadow-window">
    <div className="h-[300px] w-full py-10 px-5">
      <img alt="" src={sadFritzIcon} className="w-20 h-20 block mx-auto my-auto" />
      <div className="pt-10">
        <span className="text-center block">Something went wrong and the page could not be loaded </span>
        <span className="text-center block">
          {" "}
          <a href="https://arbeit.statuspage.io/" className="text-blueChecked underline">
            Click here
          </a>{" "}
          to visit our status page
        </span>
      </div>
    </div>
    <div className="h-[260px] w-full px-5">
      <span className="my-2 text-left font-bold text-lg"> Error Message</span>
      <TextAreaInput value={message} readOnly={true}></TextAreaInput>
    </div>
  </div>
);

export const ErrorModal = (props: { errorMessage: string; onClose: MouseEventHandler }) => {
  return ReactDOM.createPortal(
    <div className="bg-transparentGray absolute top-0 w-full left-[20px] h-full flex justify-center items-center">
      <div className="z-20">
        {modalContent(props.errorMessage)}
        <div className="h-[100px] bg-primaryPurple rounded-b-lg flex w-full justify-around -mt-10">
          <div className="w-1/4 place-self-center">
            <CancelButton onClick={props.onClose}>Close</CancelButton>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};
