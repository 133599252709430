import { MouseEventHandler, ReactElement } from "react";
import * as ReactDOM from "react-dom";
import closeIcon from "../../images/close_icon.svg";

export const Modal = (props: {
  title: String | null;
  content: ReactElement;
  buttons: ReactElement;
  buttonClose: MouseEventHandler;
}) => {
  return ReactDOM.createPortal(
    <div className="bg-transparentGray absolute top-0 w-full h-full flex justify-center items-center z-20">
      <div className="flex flex-col justify-between h-[360px] w-[600px] bg-white rounded-lg shadow-window">
        {!!props.buttonClose ? (
          <img
            alt=""
            className="cursor-pointer flex relative self-end right-3 top-3 w-6 h-6"
            src={closeIcon}
            onClick={props.buttonClose}
          />
        ) : null}
        {!!props.title ? <h1 className="text-center text-2xl">{props.title}</h1> : null}
        {props.content}
        <div className="h-[100px] bg-primaryPurple rounded-b-lg">{props.buttons}</div>
      </div>
    </div>,
    document.body
  );
};
