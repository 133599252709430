import { BrowserRouter, Navigate, useRoutes, RouteObject, useLocation, NavLink } from "react-router-dom";
import { AccountFields } from "./accountfields";
import { DialerCampaigns } from "./campaigns/dialer";
import { TextingCampaignsPage } from "./campaigns/texting";
import { CallList } from "./contactlist";
import { DoNotCallPage } from "./donotcall";
import useAuthInfo, { UserAuthInfo } from "./hooks/useClientInfo";
import { LiveMonitoring } from "./livemonitoring";
import Login from "./login";
import { ManagerPage } from "./managers";
import { Messages } from "./messages";
import { ArchivePage } from "./reports/archive";
import { CDRPage } from "./reports/cdr";
import PageContainer from "./pagecontainer";
import { TextMessages } from "./textmessages";
import { Upload } from "./upload";
import { TextManager } from "./textmanager";
import { DataModelsPage } from "./permanentaccounts";
import { AccountModelPage } from "./permanentaccounts/accountmodel";
import { DebtorModelPage } from "./permanentaccounts/debtormodel";
import { MappingsList } from "./permanentaccounts/mapping/list";
import { createContext } from "react";
import { ImportedAccountsList } from "./permanentaccounts/import";
import { ImportDetails } from "./permanentaccounts/import/details";
import { UserPage } from "./user";
import React from "react";
import { BreadcrumbData, createRoutesFromChildren, getBreadcrumbs, Route } from "use-react-router-breadcrumbs";
import { ImportCreator } from "./permanentaccounts/import/creator";
import { MappingEditorEdit } from "./permanentaccounts/mapping/editor/edit";
import { MappingCreator } from "./permanentaccounts/mapping/creator";
import { FilterListing as FilterList } from "./permanentaccounts/filtering/list";
import { ContactListPreview } from "./permanentaccounts/filtering/contactList/preview";
import { FilterCreator, FilterEditor } from "./permanentaccounts/filtering/creator";
import { enableMapSet } from "immer";
import { isTokenExpired } from "./api/login";
import { UsagePage } from "./reports/usage";

/*
      PLA-1755 - For now, none of the spans of the breadcrum will be clickable
      
      const ChildLink = (props: { text: string; pathName: string, lastItem: boolean }) => {

        return props.lastItem ? (
          <>
            <span key={props.pathName} className="text-base">
              {props.text}
            </span>
          </>
        ) : (
          <span key={props.pathName} className="text-base">
            <NavLink to={props.pathName}>
              <span className="text-purple "> {props.text}</span> &gt;
            </NavLink>
          </span>
        );
        
      };

  */

const DefaultLink = (props: { text: string; lastItem: boolean }) => {
  return (
    <>
      {props.lastItem ? (
        <span className=" text-base"> {props.text}</span>
      ) : (
        <span className=" text-base"> {props.text} &gt; </span>
      )}
    </>
  );
};

export const BreadcrumbTrail = (props: { breadCrumbs: BreadcrumbData[] }) => {
  const rootPage = "/main";
  const breadcrumbSize = props.breadCrumbs.length;
  // The way the system is structured now requires that a page has 3 levels of depht to be considered to have a link
  // Example: Arbeit one (1) > Campaigns (2) > List (3)
  // If, at some point, this changes, we would have to change the condition to be a comparision stablishing the depth as a minimum (versus equal)
  const MinHierarchyDepth = 3;

  return (
    <>
      <div className="min-h-0 bg-white flex flex-col py-5 px-24">
        <div className="w-full font-bold text-3xl text-primaryDarkGrey">
          {props.breadCrumbs.map(({ match, breadcrumb, key }: any, index) => {
            if (key !== "/") {
              const text = key === rootPage ? "Arbeit One" : breadcrumb;
              /*
                PLA-1755 - For now, none of the spans of the breadcrum will be clickable

                    if (index === MinHierarchyDepth && !!match.route.element) {
                      return (
                        <ChildLink
                          text={breadcrumb}
                          pathName={match.pathname}
                          lastItem={index === breadcrumbSize - 1 ? true : false}
                        />
                      );
                    } else {
                      return <DefaultLink text={text} lastItem={index === breadcrumbSize - 1 ? true : false} />;
                    }
               */

              return <DefaultLink text={text} lastItem={index === breadcrumbSize - 1 ? true : false} />;
            }
          })}
        </div>
      </div>
    </>
  );
};

const GenerateAppRoutes = (
  authInfo: UserAuthInfo,
  deleteAuthInfo: () => void,
  breadcrumb: BreadcrumbData<string>[]
) => {
  return (
    <>
      <Route path="*" element={<Navigate replace to="/main/messages/voicemessages" />} />
      <Route
        path="/main"
        element={<PageContainer breadCrumbs={breadcrumb} authInfo={authInfo} deleteAuthInfo={deleteAuthInfo} />}
      >
        <Route path="donotcall" breadcrumb={"Do not Call"} element={<DoNotCallPage clientId={authInfo.clientId} />} />
        <Route
          path="customfields"
          breadcrumb={"Account Fields "}
          element={<AccountFields clientId={authInfo.clientId} />}
        />
        <Route path="calllist" breadcrumb={"Call Lists"} element={<CallList clientId={authInfo.clientId} />} />
        <Route
          path="livemonitoring"
          breadcrumb={"Live Monitoring"}
          element={<LiveMonitoring clientId={authInfo.clientId} />}
        />
        <Route path="manager" breadcrumb={"Managers"} element={<ManagerPage clientId={authInfo.clientId} />} />
        
        {/*Permanent Accounts routes 
        <Route path="mapping" breadcrumb={"Mappings"}>
          <Route path="list" breadcrumb={"List"} element={<MappingsList clientId={authInfo.clientId} />} />
          <Route path="create" breadcrumb={"Create"} element={<MappingCreator clientId={authInfo.clientId} />} />
          <Route path="edit" breadcrumb={"Edit"}>
            <Route path=":id" element={<MappingEditorEdit clientId={authInfo.clientId} />}></Route>
          </Route>
        </Route>
        <Route path="model" breadcrumb={"Models"}>
          <Route path="list" breadcrumb={"List"} element={<DataModelsPage />} />
          <Route path="debtor" breadcrumb={"Debtor"} element={<DebtorModelPage />}>
            <Route path=":id" breadcrumb={"Edit"} element={<DebtorModelPage />} />
          </Route>
          <Route path="account" breadcrumb={"Account"} element={<AccountModelPage />}>
            <Route path=":id" breadcrumb={"Edit"} element={<AccountModelPage />} />
          </Route>
        </Route>
        <Route path="import" breadcrumb={"Imports"}>
          <Route path="list" breadcrumb={"List"} element={<ImportedAccountsList clientId={authInfo.clientId} />} />
          <Route path="details" breadcrumb={"Details"}>
            <Route path=":id" breadcrumb={"Edit"} element={<ImportDetails clientId={authInfo.clientId} />} />
          </Route>
          <Route path="create" element={<ImportCreator clientId={authInfo.clientId} />} />
        </Route>
        <Route path="filter" breadcrumb={"Account Filters"}>
          <Route path="list" breadcrumb={"List"} element={<FilterList clientId={authInfo.clientId} />} />
          <Route
            path="preview/:id"
            breadcrumb={"Filter"}
            element={<ContactListPreview clientId={authInfo.clientId} />}
          />
          <Route path="create" breadcrumb={"Create"} element={<FilterCreator clientId={authInfo.clientId} />} />
          <Route path="edit" breadcrumb={"Edit"}>
            <Route path=":id" element={<FilterEditor clientId={authInfo.clientId} />} />
          </Route>
        </Route>*/}
        <Route path="reports" breadcrumb={"Reports"}>
          <Route path="usage" breadcrumb={"Usage"} element={<UsagePage clientId={authInfo.clientId} />}  />
          <Route path="archive" breadcrumb={"Archives"} element={<ArchivePage clientId={authInfo.clientId} />} />
          <Route path="cdr" breadcrumb={"CDR"} element={<CDRPage clientId={authInfo.clientId} />} />
        </Route>
        <Route path="messages" breadcrumb={"Message"}>
          <Route
            path="textmessages"
            breadcrumb={"Text Messages"}
            element={<TextMessages clientId={authInfo.clientId} />}
          />
          <Route
            path="voicemessages"
            breadcrumb={"Voice Messages"}
            element={<Messages clientId={authInfo.clientId} />}
          />
        </Route>
        <Route path="campaigns" breadcrumb={"Campaigns"}>
          <Route
            path="texting"
            breadcrumb={"Texting Campaign"}
            element={<TextingCampaignsPage clientId={authInfo.clientId} />}
          />
          <Route
            path="dialer"
            breadcrumb={"Dialer Campaign"}
            element={<DialerCampaigns clientId={authInfo.clientId} />}
          />
        </Route>
        <Route path="textmanager" breadcrumb={"Text Manager"} element={<TextManager clientId={authInfo.clientId} />} />
        <Route path="upload" breadcrumb={"Upload"} element={<Upload clientId={authInfo.clientId} />} />
        <Route path="user" breadcrumb={"User"} element={<UserPage clientId={authInfo.clientId} />} />
        <Route path="*" element={<div>404</div>} />
      </Route>
    </>
  );
};

function ArbeitRoutes(props: { appRouteObjects: RouteObject[]; onChange: Function }) {
  const location = useLocation();
  const GeneratedRoutes = useRoutes(props.appRouteObjects);

  React.useEffect(() => {
    if (!!location) {
      const breadCrumbValue = getBreadcrumbs({
        routes: props.appRouteObjects,
        location,
      });
      props.onChange(breadCrumbValue);
    }
  }, [location]);
  return GeneratedRoutes;
}

export const AuthInfoContext = createContext<UserAuthInfo | undefined>(undefined);

function App() {
  const { authInfo, setAuthInfo, deleteAuthInfo } = useAuthInfo();
  const [breadCrumbs, setBreadCrumbs] = React.useState<BreadcrumbData<string>[]>([]);

  const handleRouteChange = (crumbs: BreadcrumbData<string>[]) => {
    setBreadCrumbs(crumbs);
  };

  React.useEffect(() => {
    enableMapSet();
    if (authInfo) {
        isTokenExpired().then(response => {
        if(response)
          deleteAuthInfo();
    })
  }
  }, [authInfo, deleteAuthInfo]);

  if (authInfo === undefined) {
    return <Login setAuthInfo={setAuthInfo} />;
  } 
  else {
    const AppRoutes = GenerateAppRoutes(authInfo!, deleteAuthInfo, breadCrumbs);
    const appRouteObjects = createRoutesFromChildren(AppRoutes);

    return (
      <AuthInfoContext.Provider value={authInfo}>
        <BrowserRouter>
          <ArbeitRoutes appRouteObjects={appRouteObjects} onChange={handleRouteChange} />
        </BrowserRouter>
      </AuthInfoContext.Provider>
    );
  }
}

export default App;
