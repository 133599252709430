import * as React from "react";
import eyeIcon from "../../images/visibility_black_24dp.svg";
import eyeSlash from "../../images/visibility_off_black_24dp.svg";

export interface PasswordInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  showPassword: boolean;
  showHideAction: () => void;
}
export const PasswordInput: React.FC<PasswordInputProps> = ({ showHideAction, showPassword, ...rest }) => {
  const inputType = showPassword ? "text" : "password";
  const inputIcon = showPassword ? eyeSlash : eyeIcon;

  return (
    <div>
      <input
        type={inputType}
        className="py-2 px-3 border border-spaceGray w-full font-arbie text-xl text-primaryPurple tracking-widest [text-shadow:_rgb(127_127_127_/_30%)_1px_1px_1px]"
        {...rest}
      />
      <span className="cursor-pointer float-right relative mr-3" onClick={showHideAction}>
        <img alt="" className="block -mt-8" src={inputIcon} />
      </span>
    </div>
  );
};
