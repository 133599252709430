import { ErrorCause, RequestInfo } from "../errorboundaries";

export const fetchData = async (url: string, config: RequestInit = {}): Promise<any> => {
  return new Promise((resolve) => {
    fetch(url, config)
      .then((result) => {
        if (!result.ok) {
          return Promise.reject(result);
        } else {
          return resolve(result);
        }
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export function getRequestInfo<T>(data: Array<T>, resp: Response): RequestInfo<T> {
  return {
    data: data.length ? data : [],
    err: data.length
      ? undefined
      : !data.length && !resp.ok
      ? {
          error: new Error(),
          errorInfo: `${resp.status} - ${resp.statusText}`,
          errorCause: ErrorCause.LOADING,
        }
      : undefined,
  };
}
