import React from "react";
import { Outlet, useMatch, useResolvedPath } from "react-router";
import { Link, LinkProps } from "react-router-dom";
import { ReactComponent as CustomFieldsIcon } from "../images/library_add_black_24dp.svg";
import { ReactComponent as ContactListIcon } from "../images/list_black_24dp.svg";
import { ReactComponent as MessagesIcon } from "../images/chat_black_24dp.svg";
import { ReactComponent as TextMessagesIcon } from "../images/sms_black_24dp.svg";
import { ReactComponent as LiveMonitoringIcon } from "../images/chart_pie_black_24dp.svg";
import { ReactComponent as RecentActorsIcon } from "../images/recent_actors_black_24dp.svg";
import { ReactComponent as ReportsIcon } from "../images/reports_black_24dp.svg";
import { ReactComponent as ManagerIcon } from "../images/person_plus_outline_24dp.svg";
import { ReactComponent as DoNotCallIcon } from "../images/mobile_off_black_24dp.svg";
import { ReactComponent as PermanentAccountsIcon } from "../images/account_outline_24dp.svg";
import arbeitLogoIcon from "../images/arbeit-one-logo-white.png";
import ToolTip from "../shared/tooltip";
import PopupMenu from "../shared/popoutmenu";
import ErrorBoundary from "../errorboundaries";
import { Header } from "../header";
import { UserAuthInfo } from "../hooks/useClientInfo";
import useMenuInfo from "../hooks/useMenuInfo";
import { BreadcrumbTrail } from "../App";
import { BreadcrumbData } from "use-react-router-breadcrumbs";

interface LinkButtonProps extends LinkProps {
  tooltipLabel: string;
  icon?: React.ReactElement;
  children?: React.ReactElement;
  depth?: number;
  disabled?: boolean;
}

const MenuContext = React.createContext(false);

function LinkButton({ icon, to, tooltipLabel, children, depth, disabled, ...props }: LinkButtonProps) {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const menuIsExpanded = React.useContext(MenuContext);

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  const divClasses =
    "group w-full fill-white flex items-center py-[0.50rem] transition-colors hover:bg-primaryPurple hover:text-white sm:py-[0.40rem] ";

  const svgClasses = "transition-colors group-hover:fill-white ";
  const positionElem = React.useRef<HTMLDivElement>(null);

  let elem: JSX.Element | undefined = undefined;
  if (menuIsExpanded) {
    // Rendering the expanded menu as a 1-level tree view, where each item is a nav item or a nav category

    if (children) {
      // elem is a nav category in the expanded menu
      if (depth === undefined) {
        // elem is a top-level nav category in the expanded menu
        elem = (
          <>
            <div
              ref={positionElem}
              className={
                "group w-full fill-white flex items-center py-[0.50rem] transition-colors sm:py-[0.40rem] px-5 "
              }
            >
              {icon
                ? React.cloneElement(icon, {
                    className: "fill-mediumGray2",
                  })
                : null}
              <span className="ml-3 justify-center self-center text-md sm:text-sm">{tooltipLabel}</span>
            </div>
            {!depth ? <div> {children} </div> : null}
          </>
        );
      } else {
        // elem is a second-level nav category in the expanded menu

        elem = (
          <>
            <Link to={to} {...props} className="flex">
              <div
                ref={positionElem}
                className={divClasses + " px-[2.8rem] " + (match && depth === 1 ? " bg-primaryPurple text-white" : " ")}
              >
                <span className="ml-3 justify-center self-center text-md sm:text-sm">{tooltipLabel}</span>
              </div>
            </Link>
          </>
        );
      }
    } else {
      // elem is a navigation item in the expanded menu
      elem = (
        <Link to={to} {...props} className="flex">
          <div
            ref={positionElem}
            className={
              divClasses +
              (depth ? " px-[2.8rem] " : " pl-5 ") +
              (match ? " bg-primaryPurple text-white" : " bg-spaceGray text-darkGrayLabel")
            }
          >
            {icon
              ? React.cloneElement(icon, {
                  className: match ? svgClasses + "fill-white" : svgClasses + "fill-mediumGray2",
                })
              : null}
            <span className="ml-3 justify-center self-center text-md sm:text-sm">{tooltipLabel}</span>
          </div>
        </Link>
      );
    }
  } else {
    // Rendering the navigation menu collapsed
    if (depth === undefined) {
      // A top level nav item or category in the collapsed menu
      if (children) {
        // A top level nav category in the collapsed menu
        elem = (
          <>
            <div
              ref={positionElem}
              className={
                "group w-full justify-center items-center flex h-10 transition-colors hover:bg-primaryPurple hover:text-white  " +
                (match ? "bg-primaryPurple text-white" : "bg-spaceGray text-darkGrayLabel")
              }
            >
              {icon
                ? React.cloneElement(icon, {
                    className: match ? svgClasses + "fill-white" : svgClasses + "fill-mediumGray2",
                  })
                : null}
              {showTooltip && (
                <PopupMenu positioningElement={positionElem.current} show={showTooltip}>
                  {children}
                </PopupMenu>
              )}
            </div>
          </>
        );
      } else {
        // A top level nav item in the collapsed menu
        elem = (
          <Link to={to} {...props}>
            <div
              ref={positionElem}
              className={
                "group w-full justify-center items-center flex h-10 transition-colors hover:bg-primaryPurple hover:text-white  " +
                (match ? "bg-primaryPurple text-white" : "bg-spaceGray text-darkGrayLabel")
              }
            >
              {icon
                ? React.cloneElement(icon, {
                    className: match ? svgClasses + "fill-white" : svgClasses + "fill-mediumGray2",
                  })
                : null}
              {showTooltip && (
                <ToolTip positioningElement={positionElem.current} label={tooltipLabel} show={showTooltip} />
              )}
            </div>
          </Link>
        );
      }
    } else {
      // A nested nav item or category in the collapsed menu
      if (depth === 1) {
        // A first level nav item or category in the collapsed menu
        if (children) {
          // A first level nav category
          elem = (
            <div
              ref={positionElem}
              className={
                "group w-full h-10 flex items-center px-5 transition-colors hover:bg-primaryPurple hover:text-white  " +
                (match ? "bg-primaryPurple text-white" : "bg-spaceGray text-darkGrayLabel")
              }
            >
              {showTooltip && (
                <PopupMenu positioningElement={positionElem.current} show={showTooltip}>
                  {!!children ? children : <span> {tooltipLabel} </span>}
                </PopupMenu>
              )}
              <div className="select-none text-md sm:text-sm ">{tooltipLabel}</div>
            </div>
          );
        } else {
          // A first level nav item
          elem = (
            <Link to={to} {...props}>
              <div
                ref={positionElem}
                className={
                  "group w-full h-10 flex items-center px-5 transition-colors hover:bg-primaryPurple hover:text-white  " +
                  (match ? "bg-primaryPurple text-white" : "bg-spaceGray text-darkGrayLabel")
                }
              >
                <div className="text-md sm:text-sm ">{tooltipLabel}</div>
              </div>
            </Link>
          );
        }
      } else {
        elem = (
          <Link to={to} {...props}>
            <div
              ref={positionElem}
              className={
                "group w-full h-10 flex items-center px-5 transition-colors hover:bg-primaryPurple hover:text-white  " +
                (match ? "bg-primaryPurple text-white" : "bg-spaceGray text-darkGrayLabel")
              }
            >
              {icon
                ? React.cloneElement(icon, {
                    className: match ? svgClasses + "fill-white" : svgClasses + "fill-mediumGray2",
                  })
                : null}
              {disabled ? (
                <div className="text-md sm:text-sm ">{tooltipLabel}</div>
              ) : (
                <div className="text-md sm:text-sm ">{tooltipLabel}</div>
              )}
            </div>
          </Link>
        );
      }
    }
  }

  return (
    <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      {elem}
    </div>
  );
}

export type PageContainerProps = {
  authInfo: UserAuthInfo;
  deleteAuthInfo: () => void;
  breadCrumbs: BreadcrumbData[];
};

function PageContainer(props: PageContainerProps) {
  const { menuExpanded, setMenuState } = useMenuInfo();
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

  const positionMenuDiv = React.useRef<HTMLDivElement>(null);
  const menuButton = React.useRef<HTMLDivElement>(null);
  const menuImg = React.useRef<HTMLImageElement>(null);

  const menuDivClasses = "flex flex-col overflow-hidden z-10 h-menu bg-spaceGray ";
  const imgClasses = "m-auto pt-3 w-7 ";

  let menuDiv = menuButton.current;
  let menuImage = menuImg.current;

  const changeMenu = () => {
    if (menuDiv)
      menuDiv.className = menuExpanded
        ? menuDivClasses + "animate-decrease "
        : menuDivClasses + "animate-increase w-80";

    if (menuImage)
      menuImage.className = menuExpanded
        ? imgClasses + "animate-rotateAnticlock"
        : imgClasses + "animate-rotateClockwise";
    setMenuState(!menuExpanded);
  };

  return (
    <MenuContext.Provider value={menuExpanded}>
      <div className="h-screen w-full flex">
        <div>
          <div
            className="w-[4.5rem] min-h-[4.5rem] bg-primaryPurple cursor-pointer "
            ref={positionMenuDiv}
            onClick={() => changeMenu()}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <img className={imgClasses} ref={menuImg} src={arbeitLogoIcon} alt="Menu Icon" />
          </div>
          <div ref={menuButton} className={menuDivClasses}>
            {showTooltip && (
              <ToolTip
                positioningElement={positionMenuDiv.current}
                label={menuExpanded ? "Open Menu" : "Close Menu"}
                show={showTooltip}
              />
            )}
            <nav>
              <ul>
                <LinkButton icon={<CustomFieldsIcon />} to="/main/customfields" tooltipLabel="Account Fields" />
                <LinkButton
                  icon={<MessagesIcon />}
                  to={"/main/messages"}
                  tooltipLabel="Messages"
                  children={
                    <>
                      <LinkButton to={"/main/messages/voicemessages"} tooltipLabel={"Voice Messages"} depth={1} />
                      <LinkButton to={"/main/messages/textmessages"} tooltipLabel={"Text Messages"} depth={1} />
                    </>
                  }
                />
                <LinkButton
                  icon={<RecentActorsIcon />}
                  to="/main/campaigns"
                  tooltipLabel="Campaigns"
                  children={
                    <>
                      <LinkButton to={"/main/campaigns/dialer"} tooltipLabel={"Dialer Campaigns"} depth={1} />
                      <LinkButton to={"/main/campaigns/texting"} tooltipLabel={"Texting Campaigns"} depth={1} />
                    </>
                  }
                />
                <LinkButton icon={<ContactListIcon />} to="/main/calllist" tooltipLabel="Contact Lists" />
                <LinkButton icon={<LiveMonitoringIcon />} to="/main/livemonitoring" tooltipLabel="Live Monitoring" />
                <LinkButton icon={<DoNotCallIcon />} to="/main/donotcall" tooltipLabel="Do Not Call" />
                <LinkButton icon={<ManagerIcon />} to="/main/manager" tooltipLabel="Managers" />
                <LinkButton
                  icon={<ReportsIcon />}
                  to="/main/reports"
                  tooltipLabel="Reports"
                  children={
                    <>
                      <LinkButton to="/main/reports/usage" tooltipLabel={"Usage Report"} depth={1} />
                      <LinkButton to="/main/reports/cdr" tooltipLabel={"Call Detail Report"} depth={1} />
                      <LinkButton to="/main/reports/archive" tooltipLabel={"Report Archives"} depth={1} />
                    </>
                  }
                />
                <LinkButton icon={<TextMessagesIcon />} to="/main/textmanager" tooltipLabel="Text Message Manager" />
               
               {/*Permanent Accounts Menu */}
               {/*
                  <LinkButton
                    icon={<PermanentAccountsIcon />}
                    to="/main/accounts"
                    tooltipLabel="Permanent Accounts"
                    children={
                      <>
                        <LinkButton
                          to="/main/import/list"
                          tooltipLabel="Import Accounts"
                          depth={1}
                          children={<LinkButton to={"/main/import/list"} tooltipLabel={"Import Summary"} depth={2} />}
                        />
                        <LinkButton
                          to="/main/model/list"
                          tooltipLabel="Manage Data Model"
                          depth={1}
                          children={
                            <>
                              <LinkButton to="/main/model/list" tooltipLabel="Model Summany" depth={2} />
                              <LinkButton to="/main/model/account" tooltipLabel="Account Model Creation" depth={2} />
                              <LinkButton to="/main/model/debtor" tooltipLabel="Debtor Model Creation" depth={2} />
                            </>
                          }
                        />
                        <LinkButton
                          to="/main/mapping/list"
                          tooltipLabel="Manage Mapping"
                          depth={1}
                          children={
                            <>
                              <LinkButton to="/main/mapping/list" tooltipLabel="Mapping" depth={2} />
                              <LinkButton to="/main/mapping/create" tooltipLabel="Mapping Upload" depth={2} />
                            </>
                          }
                        />
                      </>
                    }
                  />
                  */}
              </ul>
            </nav>
          </div>
        </div>
        <div className="h-full w-full flex flex-col overflow-hidden">
          <div className="h-[4.5rem] shrink-0 w-full bg-white border-b border-spaceGray flex justify-end items-center pr-5">
            <Header authInfo={props.authInfo} deleteAuthInfo={props.deleteAuthInfo} />
          </div>
          <div className="flex flex-col w-full h-full bg-spaceWhite overflow-hidden">
            <BreadcrumbTrail breadCrumbs={props.breadCrumbs} />
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </MenuContext.Provider>
  );
}

export default PageContainer;
