import React, { Component, ErrorInfo, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { ErrorBoundaryContext } from "../apiError";
import { ErrorModal, modalContent } from "../errormodal";

export enum ErrorCause {
  NONE = 0,
  LOADING = 1,
  OPERATION = 2,
}

export type ErrorType = {
  error: Error;
  errorInfo: string;
  errorCause: ErrorCause;
};

export type RequestInfo<T> = {
  data: Array<T>;
  err?: ErrorType;
};

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: string;
  errorCause: ErrorCause;
}

class ErrorBoundaryInternal extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: "",
    errorCause: ErrorCause.NONE,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: _, errorInfo: _.message, errorCause: ErrorCause.NONE };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public triggerError(obj: ErrorType) {
    this.setState({ hasError: true, error: obj.error, errorInfo: obj.errorInfo, errorCause: obj.errorCause });
  }

  resetError = () => this.setState({ hasError: false, error: null, errorInfo: "", errorCause: ErrorCause.NONE });

  public render() {
    return (
      <ErrorBoundaryContext.Provider value={this.triggerError.bind(this)}>
        {this.state.hasError ? (
          this.state.errorCause === ErrorCause.OPERATION ? (
            <ErrorModal errorMessage={this.state.errorInfo} onClose={this.resetError}></ErrorModal>
          ) : (
            <div className="h-full flex justify-center items-center">{modalContent(this.state.errorInfo)}</div>
          )
        ) : (
          this.props.children
        )}
      </ErrorBoundaryContext.Provider>
    );
  }
}

function ErrorBoundary(props: Props) {
  // We need to ensure that error boundary is reset to its default state whenever the
  // browser's 'location' changes. This will ensure the back button works the way the
  // user expects. We do this by using the current page's path as a key for the
  // error boundary. Whenever the path changes, the key changes, and the error boundary
  // is reset to its default state (with hasError === false)
  //
  const location = useLocation();
  return <ErrorBoundaryInternal key={location.pathname} {...props} />;
}

export default ErrorBoundary;
