import { Cell, ColumnName, ColumnsRow, Row, Table } from "../table";

export type ContentTableProps = {
  columns: string[];
  rows: any[];
};

export function ContentTable({ columns, rows }: ContentTableProps) {
  function makeRow(rowData: any[], rowNum: number) {
    let cells = rowData.map((d) => {
      return <Cell>{d}</Cell>;
    });

    return <Row id={rowNum.toString()}>{cells}</Row>;
  }

  return (
    <Table>
      <thead>
        <ColumnsRow>
          {columns.map((c, i) => {
            if (i === columns.length - 1) {
              return <ColumnName colSpan={2}>{c}</ColumnName>;
            } else {
              return <ColumnName>{c}</ColumnName>;
            }
          })}
        </ColumnsRow>
      </thead>
      <tbody>{rows.map(makeRow)}</tbody>
    </Table>
  );
}
