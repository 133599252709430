import * as Asserts from "../../utils/Asserts";

export type ColumnData = {
  id: ColumnID;
  displayName: string;
  filterType: FilterID;
};

export type DateRangeFilter = {
  type: "date-range";
  columnId: ColumnID;
  startDay: string;
  endDay: string;
  startTime: string;
  endTime: string;
};

export type NumericRangeFilter = {
  type: "numeric-range";
  columnId: ColumnID;
  start: number;
  end: number;
};

export type CurrencyRangeFilter = {
  type: "currency-range";
  columnId: ColumnID;
  start: number;
  end: number;
};

export type KeywordFilter = {
  type: "keyword";
  columnId: ColumnID;
  keyword: string;
};

export type ConstrainedKeywordFilter = {
  type: "constrained-keyword";
  columnId: ColumnID;
  options: string[];
  choice: string;
};

export type MultipleConstrainedKeywordFilter = {
  type: "multiple-constrained-keyword";
  columnId: ColumnID;
  options: Map<number, string>;
  choices: Map<number, string>;
};

export type MultipleConstrainedOptionsFilter = {
  type: "multiple-constrained-options";
  columnId: ColumnID;
  options: Map<number, string>;
  choices: Map<number, string>;
};

export function filterIsComplete(filter: Partial<ColumnFilter>): filter is ColumnFilter {
  if (!filter.type || !filter.columnId) {
    return false;
  } else {
    switch (filter.type) {
      case "keyword":
        return !!filter.keyword;

      case "constrained-keyword":
        return !!filter.choice && !!filter.options && !!filter.choice;

      case "multiple-constrained-keyword":
        return !!filter.choices && !!filter.options;

      case "multiple-constrained-options":
      return !!filter.choices && !!filter.options;

      case "date-range":
        return !!filter.startDay && !!filter.endDay && !!filter.startTime && !!filter.endTime;

      case "currency-range":
      case "numeric-range":
        return filter.start !== undefined && filter.end !== undefined;

      default:
        Asserts.assertNever(filter.type);
    }
  }
}

export type FilterID = "keyword" | "constrained-keyword" | "date-range" | "currency-range" | "numeric-range" | "multiple-constrained-keyword" | "multiple-constrained-options";

export type ColumnID =
  | "startTime"
  | "finishTime"
  | "answerTime"
  | "duration"
  | "firstName"
  | "middleName"
  | "lastName"
  | "cost"
  | "minuteCost"
  | "callTypeName"
  | "callerId"
  | "phoneNumber"
  | "state"
  | "city"
  | "zipCode"
  | "ssn"
  | "billedDuration"
  | "externalId"
  | "dateOfBirth"
  | "campaignName"
  | "calllistName"
  | "callerIdTypeName"
  | "campaigns"
  | "callDisposition"
  | "transferred";

export type ColumnFilter =
  | DateRangeFilter
  | NumericRangeFilter
  | CurrencyRangeFilter
  | KeywordFilter
  | ConstrainedKeywordFilter
  | MultipleConstrainedKeywordFilter
  | MultipleConstrainedOptionsFilter;


  export class DullData {
    campaigns: Map<number, string>;
    callDisposition: Map<number, string>;
    transferred: Map<number, string>;
    constructor() {
      this.campaigns = new Map<number, string>();
      this.callDisposition  = new Map<number, string>();
      this.transferred  = new Map<number, string>();
    }
  };

  export type UsageReportfilter = {
    startDay: string;
    endDay: string;
    callLists: number[];
  }
