import * as React from "react";
import { MessageStepType } from "../../enums/messages";
import { MessageStep, TtsVoice } from "../../interfaces/messages";
import ConfirmButton from "../../shared/buttons/confirm";
import closeIcon from "../../images/close_icon.svg";

import { CheckboxInput, SelectInput, TextInput } from "../../shared/input";

export const CustomText = (props: {
  data: MessageStep;
  voiceData: TtsVoice[];
  onSave: Function;
  onClose: Function;
}) => {
  const [newTextToSpeech, setNewTextToSpeech] = React.useState<string>("");
  const [voiceSelected, setVoiceSelected] = React.useState<number>(0);
  const [spellOptionSet, setSpellOption] = React.useState<number>(1);

  const fillFields = React.useCallback(() => {
    if (props.data) {
      const editedStep = props.data;
      setNewTextToSpeech(editedStep.value);
      setVoiceSelected(editedStep.ttsVoiceId!);
      setSpellOption(editedStep.ttsModeId!);
    }
  }, [props.data]);

  React.useEffect(() => {
    fillFields();
  }, [fillFields]);

  const handleSave = () => {
    let customTextObj = new MessageStep();

    customTextObj.messageStepType = MessageStepType.FixedTTS;
    customTextObj.value = newTextToSpeech;
    customTextObj.ttsModeId = spellOptionSet;
    customTextObj.ttsVoiceId = voiceSelected;

    props.onSave(customTextObj);
    props.onClose(true);
    clearFields();
  };

  const handleClose = () => {
    props.onClose(true);
  };

  const handleAddNewTextToSpeech = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTextToSpeech(e.target.value);
  };

  const handleSelectedVoice = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setVoiceSelected(Number(e.target.value));
  };

  const handleSpellSet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const checkedValue = isChecked ? 2 : 1;
    setSpellOption(checkedValue);
  };

  const clearFields = () => {
    setNewTextToSpeech("");
    setSpellOption(1);
    setVoiceSelected(0);
  };

  const enableSave = !newTextToSpeech || !voiceSelected || voiceSelected === 0;

  return (
    <div
      className="items-center justify-around
            flex w-full bg-white 
            shadow-container flex-wrap opacity-100 
            h-auto py-8 px-6 mt-6"
    >
      <div className="w-1/4">
        <TextInput
          title="Text"
          placeholder="Type text to speech"
          name="textToSpeech"
          value={newTextToSpeech}
          onChange={handleAddNewTextToSpeech}
        />
      </div>
      <div className="w-1/4 self-start">
        <SelectInput
          title="Voice"
          value={voiceSelected}
          onChange={handleSelectedVoice}
          options={props.voiceData.map((voice) => {
            return new Option(voice.name, voice.id.toString());
          })}
        />
      </div>
      <div className="w-1/4  self-start">
        <CheckboxInput
          title="Spell"
          roundedslider={true}
          sliderlabel={spellOptionSet === 2 ? "ON" : "OFF"}
          checked={spellOptionSet === 2}
          value={spellOptionSet}
          onChange={handleSpellSet}
        />
      </div>
      <div className="w-1/6 mt-6">
        <ConfirmButton disabled={enableSave} onClick={handleSave}>
          Add
        </ConfirmButton>
      </div>
      <img alt="" className="cursor-pointer relative block -top-16" src={closeIcon} onClick={handleClose} />
    </div>
  );
};
