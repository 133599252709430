import * as React from "react";
import { CallListFile, CallListRenewRequest, Campaign } from "../../interfaces/contactlist";
import CancelButton from "../../shared/buttons/cancel";
import ConfirmButton from "../../shared/buttons/confirm";
import { CheckboxInput, SelectInput, TextInput } from "../../shared/input";

export const CallListRenew = (props: {
  callListSelected: CallListFile;
  callListsCampaigns: Campaign[];
  errorMessage: string;
  onClose: Function;
  onSave: Function;
}) => {
  const [reuseNumbersNotCalled, setReuseNumbersNotCalled] = React.useState<number>(1);
  const [reuseNumbersCalledAnswered, setReuseNumbersCalledAnswered] = React.useState<number>(1);
  const [reuseNumbersCalledNotAnswered, setReuseNumbersCalledNotAnswered] = React.useState<number>(1);
  const [callListCampaign, setCallListCampaign] = React.useState<number>(0);
  const [callListName, setCalltListName] = React.useState<string>("");
  const boxIsChecked = reuseNumbersCalledAnswered || reuseNumbersCalledNotAnswered || reuseNumbersNotCalled;
  const infoMessageCheckboxes = !boxIsChecked ? "Please select at least one option above" : "";
  const enableSave = !!callListName && !!callListCampaign && boxIsChecked;
  const infoMessage = !callListCampaign ? "Campaign selection is required" : "";

  const getOptions = (list: Campaign[]) => {
    let mappedList = [{ value: 0, text: "Select" }];
    list.forEach((campaign) => {
      mappedList.push({
        text: campaign.name,
        value: campaign.campaignId,
      });
    });
    return mappedList;
  };
  //====================
  // Handle functions
  //====================

  const handleNewContactListName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setCalltListName(name);
  };

  const handleChangeContactListCampaign = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const campaign = e.target.value;
    setCallListCampaign(Number(campaign));
  };

  const handleSelectReuseNumbersNotCalled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reuseNumbersNotCalled = e.target.checked;
    const checkedValue = reuseNumbersNotCalled ? 1 : 0;
    setReuseNumbersNotCalled(checkedValue);
  };

  const handleSelectReuseNumbersCalledAnswered = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reuseNumbersCalledAnswered = e.target.checked;
    const checkedValue = reuseNumbersCalledAnswered ? 1 : 0;
    setReuseNumbersCalledAnswered(checkedValue);
  };

  const handleSelectReuseNumbersCalledNotAnswered = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reuseNumbersCalledNotAnswered = e.target.checked;
    const checkedValue = reuseNumbersCalledNotAnswered ? 1 : 0;
    setReuseNumbersCalledNotAnswered(checkedValue);
  };

  const handleSave = () => {
    let callListRequest = new CallListRenewRequest();

    callListRequest = {
      ...callListRequest,
      calllistName: props.callListSelected.originalFileName,
      filterOptionCalledAnswered: reuseNumbersCalledAnswered ? true : false,
      filterOptionCalledNotAnswered: reuseNumbersCalledNotAnswered ? true : false,
      filterOptionNotCalled: reuseNumbersNotCalled ? true : false,
      newCalllistName: callListName,
      campaignId: callListCampaign,
      calllistId: props.callListSelected.callListId,
    };

    props.onSave(callListRequest);
  };

  return props.callListSelected ? (
    <div className="w-full">
      <div className="flex mt-6">
        <div className="w-1/2">
          <div className="w-full">
            <span className="mb-4 block font-bold text-darkGrayTitle text-lg"> Contact List Name </span>
            <span className="text-base ml-3 text-primaryPurple">
              {"(ref. " + props.callListSelected.originalFileName + ")"}
            </span>
            <TextInput
              name="contactListName"
              placeholder="Contact List Name"
              value={callListName}
              onChange={handleNewContactListName}
            />
          </div>

          <div className="w-full">
            <SelectInput
              title="Assign to Campaign"
              id="contactListCampaign"
              name="contactListCampaign"
              value={callListCampaign}
              required={true}
              onChange={handleChangeContactListCampaign}
              options={getOptions(props?.callListsCampaigns)}
            />
          </div>
          <span className="text-xs text-redAlert">{infoMessage}</span>
        </div>
        <div className="w-1/2 ml-10">
          <span className="mb-4 block font-bold text-darkGrayTitle text-lg"> Renew Numbers </span>
          <span className="w-full block"> Select the numbers you would like to reuse in this contact list</span>
          <div>
            <CheckboxInput
              id="notCalled"
              onChange={handleSelectReuseNumbersNotCalled}
              checked={reuseNumbersNotCalled ? true : false}
              sliderlabel="All Numbers not called"
              roundedslider={false}
            />
          </div>
          <div>
            <CheckboxInput
              id="calledAnswered"
              value={reuseNumbersCalledAnswered}
              onChange={handleSelectReuseNumbersCalledAnswered}
              checked={reuseNumbersCalledAnswered ? true : false}
              sliderlabel="All Numbers Called and Answered"
              roundedslider={false}
            />
          </div>
          <div>
            <CheckboxInput
              id="calledNotAnswered"
              value={reuseNumbersCalledNotAnswered}
              onChange={handleSelectReuseNumbersCalledNotAnswered}
              checked={reuseNumbersCalledNotAnswered ? true : false}
              sliderlabel="All Numbers Called and Not answered"
              roundedslider={false}
            />
          </div>
          <span> {infoMessageCheckboxes}</span>
        </div>
      </div>
      <div className="flex my-10 w-full">
        <div className="w-1/4 mr-4">
          <ConfirmButton onClick={handleSave} disabled={!enableSave}>
            Reuse as New
          </ConfirmButton>
        </div>
        <div className="w-1/4">
          <CancelButton onClick={() => props.onClose(true)}>Cancel</CancelButton>
        </div>
      </div>
    </div>
  ) : null;
};
