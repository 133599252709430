import React from "react";
import { performLogin } from "../api/login";
import { UserAuthInfo } from "../hooks/useClientInfo";
import logo from "../images/fritz_login_logo.svg";
import { PasswordInput } from "../shared/passwordinput";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const TextInput: React.FC<InputProps> = ({ ...rest }: InputProps) => {
  return (
    <input
      className="h-14 py-2 px-3 rounded-[4px] 
                 font-sans placeholder:text-lightGray
                 shadow-inner border border-gray focus:outline-none"
      {...rest}
      type="text"
    ></input>
  );
};

function Login({ setAuthInfo }: { setAuthInfo: (x: UserAuthInfo) => void }) {
  const [code, setCode] = React.useState<string>("");
  const [user, setUser] = React.useState<string>("");
  const [pass, setPass] = React.useState<string>("");

  const [loginError, setLoginError] = React.useState(false);

  const [showUserPassword, setShowUserPassword] = React.useState(false);

  const [interactedWithCode, setInteractedWithCode] = React.useState(false);
  const [interactedWithUser, setInteractedWithUser] = React.useState(false);
  const [interactedWithPass, setInteractedWithPass] = React.useState(false);

  const codeIsValid = code.length > 0;
  const userIsValid = user.length > 0;
  const passIsValid = pass.length > 0;

  async function handleLogin(e: React.FormEvent) {
    e.preventDefault();
    if (codeIsValid && userIsValid && passIsValid) {
      const authInfo = await performLogin({ code: code, username: user, password: pass });
      if (authInfo) {
        setAuthInfo(authInfo);
        window.location.replace("/main/messages/voicemessages");
      } else {
        setLoginError(true);
      }
    } else {
      setInteractedWithCode(true);
      setInteractedWithUser(true);
      setInteractedWithPass(true);
    }
  }

  return (
    <div className="bg-primaryPurple h-full w-full flex justify-center">
      <div className="flex flex-col w-full p-5 xl:w-2/5 2xl:w-1/4">
        <img className="self-center" alt="Login Logo" src={logo} />

        <form className="flex flex-col" onSubmit={handleLogin}>
          <div className="flex flex-col mb-5">
            {loginError && (
              <div
                className="self-center mt-5 mb-5 rounded-[4px]
                           flex flex-col justify-center items-center 
                           bg-redAlert w-full h-20 text-white"
              >
                The username or password you entered is incorrect
              </div>
            )}
            <label className="mb-1 font-sans text-white">Code</label>
            <TextInput
              value={code}
              onChange={(e) => setCode(e.target.value)}
              onBlur={(e) => {
                setInteractedWithCode(true);
              }}
              placeholder="Type your client code here"
            />
            {!codeIsValid && interactedWithCode && <p className="text-redAlert text-xl">Code is required!</p>}
          </div>

          <div className="flex flex-col mb-5">
            <label className="mb-1 font-sans text-white">Username</label>
            <TextInput
              value={user}
              onChange={(e) => setUser(e.target.value)}
              onBlur={(e) => setInteractedWithUser(true)}
              placeholder="ex: LeroyJenkins"
            />
            {!userIsValid && interactedWithUser && <p className="text-redAlert text-xl">Username is required!</p>}
          </div>

          <div className="flex flex-col mb-5">
            <label className="mb-1 font-sans text-white">Password</label>
            <PasswordInput
              showPassword={showUserPassword}
              showHideAction={() => setShowUserPassword(!showUserPassword)}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              onBlur={(e) => setInteractedWithPass(true)}
              placeholder="ex: Sw0rdOf1000Truths"
            />
            {!passIsValid && interactedWithPass && <p className="text-redAlert text-xl">Password is required!</p>}
          </div>

          <button
            className="mt-5 h-14 bg-primaryPurple rounded-md 
                       transition-shadow duration-75
                       shadow-[0_6px_20px_0_rgba(0,0,0,19%)] active:shadow-[0_4px_8px_0_rgba(0,0,0,20%)]
                       text-white font-sans 
                       hover:cursor-pointer"
            onClick={handleLogin}
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
