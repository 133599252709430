export enum stepStatus {
  default = 0,
  active = 1,
  disabled = 2,
  done = 3,
  error = 4,
}

export enum UploadValidationStatus {
  NotStarted = 0,
  Success = 1,
  Partial = 2,
}

export enum UploadRestrictionsEnum {
  NoRestriction = 1,
  UniqueCalllist = 2,
  UniqueTimespan = 3,
}
