import * as React from "react";
import { TextInput } from "../shared/input";
import { CampaignSpeed, CampaignSpeedBroadcast, updateCampaignSpeedBroadcast } from "../api/livemonitoring";
import ConfirmButton from "../shared/buttons/confirm";

export type CallsPerMinuteProps = {
    campaignSpeed: CampaignSpeed;
    callBack: () => void;
};

export const CallsPerMinute: React.FC<CallsPerMinuteProps> = (props) => {
  let speed = props.campaignSpeed as CampaignSpeedBroadcast;
  const [callsPerMinute, setCallsPerMinute] = React.useState<string>(speed.callsPerMinute.toString());
  const buttonEnabled = callsPerMinute.length > 0;

  const handletextChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    let numberInserted = e.target.value.toString().replace(/\D/g, '');
    setCallsPerMinute(numberInserted);
  };

  const handleAddNewPhoneNumber = async () => {
    speed.callsPerMinute = Number.parseInt(callsPerMinute);
    const result = updateCampaignSpeedBroadcast(speed);
    props.callBack();
    //result.then(r => alert('OK'));

   // const requisitionStatus = await postNewPhoneNumber(props.clientId, phoneMask(newPhoneNumber, false));
   // if (requisitionStatus) {
   //   setNewPhoneNumber("");
   //   loadRestrictions();
   // }
  };

  return (
  
  <div className="border border-lightGray p-2">
    <TextInput title="Calls Per Minute" value={callsPerMinute} className="p-2 basis-5/12 border border-lightGray focus:border-mediumGray transition-colors rounded-md h-10"  maxLength={3} onChange={handletextChanges}></TextInput>
    <div className="w-32 pt-5 ">
      <ConfirmButton onClick={handleAddNewPhoneNumber} disabled={!buttonEnabled}>
                  OK
      </ConfirmButton>
    </div>
  </div>
  
  );
};