import React from "react";
import { Popover } from "react-tiny-popover";
import { ReactComponent as ProfileIcon } from "../images/account_circle_black_36dp.svg";
import { ReactComponent as DownloadIcon } from "../images/file_download_black_36dp.svg";
import { ReactComponent as SmallDownloadIcon } from "../images/file_download_black_24dp.svg";
import { UserAuthInfo } from "../hooks/useClientInfo";
import { CDRProgress, fetchCDRProgress } from "../api/cdr";
import { downloadReport } from "../api/archive";
import { useNavigate } from "react-router";

export type HeaderProps = {
  authInfo: UserAuthInfo;
  deleteAuthInfo: () => void;
};

export function Header(props: HeaderProps) {
  const [showProfilePopover, setShowProfillePopover] = React.useState<boolean>(false);
  const [showDownloadPopover, setShowDownloadPopover] = React.useState<boolean>(false);
  const [cdrs, setCDRs] = React.useState<CDRProgress[]>([]);

  const nav = useNavigate();
  const loadCDRs = React.useCallback(async () => {
    if (props.authInfo !== undefined) {
      const cdrs = await fetchCDRProgress(props.authInfo.clientId);
      setCDRs(cdrs);
    }
  }, [props.authInfo]);

  React.useEffect(() => {
    loadCDRs();

    const refresh = setInterval(() => {
      loadCDRs();
    }, 15000);

    return () => clearInterval(refresh);
  }, [loadCDRs]);

  function handleLogout() {
    props.deleteAuthInfo();
  }

  function statusLabel(report: CDRProgress) {
    switch (report.reportStatusId) {
      case 1:
        return "STARTING";
      case 2:
        return "IN PROGRESS";
      case 3:
        return "READY";
      case 4:
        return "ERROR";
      case 5:
        return "CANCELLED";
      default:
        throw new Error("Invalid status for report!");
    }
  }

  function reportStatusElement(progress: CDRProgress) {
    switch (progress.reportStatusId) {
      case 2:
        return (
          <>
            <div className="cursor-not-allowed">
              {React.cloneElement(<SmallDownloadIcon />, { className: "fill-lightGray" })}
            </div>
            <span>{statusLabel(progress)}</span>
            {progress.reportStatusId === 2 ? <span>{progress.progress}%</span> : null}
          </>
        );
      case 3:
        return (
          <>
            <div className="cursor-pointer" onClick={() => downloadReport(progress)}>
              {React.cloneElement(<SmallDownloadIcon />, { className: "fill-lightGray2" })}
            </div>
            <span>{statusLabel(progress)}</span>
          </>
        );
      default:
        return (
          <>
            <div className="cursor-not-allowed">
              {React.cloneElement(<SmallDownloadIcon />, { className: "fill-lightGray" })}
            </div>
            <span>{statusLabel(progress)}</span>
          </>
        );
    }
  }

  return (
    <div className="flex gap-3">
      <div className="relative">
        <Popover
          isOpen={showDownloadPopover && cdrs?.length !== 0}
          align="end"
          positions={["bottom"]}
          onClickOutside={() => setShowDownloadPopover(false)}
          content={
            <div className="bg-white shadow-window rounded-md">
              {cdrs?.map((progress) => (
                <ul className="p-0 group select-none">
                  <li className="py-3 px-5 group-first:rounded-t-md group-last:rounded-b-md flex gap-3 hover:bg-lightPurple transition-colors">
                    {reportStatusElement(progress)}
                  </li>
                </ul>
              ))}
            </div>
          }
        >
          <div className="fill-lightGray cursor-pointer" onClick={() => setShowDownloadPopover(!showDownloadPopover)}>
            {React.cloneElement(<DownloadIcon />, { className: "fill-transparentGray" })}
          </div>
        </Popover>
        {cdrs.length !== 0 && (
          <div className="absolute top-[-5px] left-[25px] inline-flex rounded-full bg-primaryPurple w-[18px] h-[18px]">
            <label className="text-white text-xs m-auto select-none">{cdrs.length}</label>
          </div>
        )}
      </div>
      <div>
        <Popover
          isOpen={showProfilePopover}
          align="end"
          positions={["bottom"]}
          onClickOutside={() => setShowProfillePopover(false)}
          content={
            <div className="bg-white shadow-window rounded-md">
              <ul className="p-0 rounded-md">
                <li
                  className="py-5 px-5 select-none hover:bg-lightPurple transition-colors cursor-pointer"
                  onClick={() => nav("/main/user/")}
                >
                  {props.authInfo?.userName}
                </li>
                <li
                  className="py-3 px-5 select-none rounded-b-md hover:bg-lightPurple transition-colors cursor-pointer"
                  onClick={handleLogout}
                >
                  LOGOUT
                </li>
              </ul>
            </div>
          }
        >
          <div className="fill-lightGray cursor-pointer" onClick={() => setShowProfillePopover(!showProfilePopover)}>
            {React.cloneElement(<ProfileIcon />, { className: "fill-transparentGray" })}
          </div>
        </Popover>
      </div>
    </div>
  );
}
