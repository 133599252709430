import * as React from "react";

export const PauseButton: React.FC = () => {
  return (
    <svg width="45.214" height="45.215" viewBox="0 0 45.214 40.215">
      <defs>
        <filter
          id="Path_439"
          x="0"
          y="0"
          width="45.214"
          height="45.215"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-color="#7e59f2" flood-opacity="0.302" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Pause_Button" data-name="Pause Button" transform="translate(9 6)">
        <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Path_439)">
          <g
            id="Path_439-2"
            data-name="Path 439"
            transform="translate(9 6)"
            fill="#fff"
          >
            <path
              d="M 13.60726737976074 26.71453666687012 C 10.10618782043457 26.71453666687012 6.814667224884033 25.35114669799805 4.339027404785156 22.87550735473633 C 1.8633873462677 20.39986801147461 0.4999973773956299 17.10834693908691 0.4999973773956299 13.60726737976074 C 0.4999973773956299 10.10618782043457 1.8633873462677 6.814667224884033 4.339027404785156 4.339027404785156 C 6.814667224884033 1.8633873462677 10.10618782043457 0.4999973773956299 13.60726737976074 0.4999973773956299 C 17.10834693908691 0.4999973773956299 20.39986801147461 1.8633873462677 22.87550735473633 4.339027404785156 C 25.35114669799805 6.814667224884033 26.71453666687012 10.10618782043457 26.71453666687012 13.60726737976074 C 26.71453666687012 17.10834693908691 25.35114669799805 20.39986801147461 22.87550735473633 22.87550735473633 C 20.39986801147461 25.35114669799805 17.10834693908691 26.71453666687012 13.60726737976074 26.71453666687012 Z"
              stroke="none"
            />
            <path
              d="M 13.60726737976074 0.9999980926513672 C 10.23974800109863 0.9999980926513672 7.07377815246582 2.311376571655273 4.692577362060547 4.692577362060547 C 2.311376571655273 7.07377815246582 0.9999980926513672 10.23974800109863 0.9999980926513672 13.60726737976074 C 0.9999980926513672 16.97478866577148 2.311376571655273 20.1407470703125 4.692577362060547 22.52194786071777 C 7.07377815246582 24.90314674377441 10.23974800109863 26.21453666687012 13.60726737976074 26.21453666687012 C 16.97478866577148 26.21453666687012 20.1407470703125 24.90314674377441 22.52194786071777 22.52194786071777 C 24.90314674377441 20.1407470703125 26.21453666687012 16.97478866577148 26.21453666687012 13.60726737976074 C 26.21453666687012 10.23974800109863 24.90314674377441 7.07377815246582 22.52194786071777 4.692577362060547 C 20.1407470703125 2.311376571655273 16.97478866577148 0.9999980926513672 13.60726737976074 0.9999980926513672 M 13.60726737976074 -1.9073486328125e-06 C 21.12234687805176 -1.9073486328125e-06 27.21453666687012 6.09217643737793 27.21453666687012 13.60726737976074 C 27.21453666687012 21.12234687805176 21.12234687805176 27.21453666687012 13.60726737976074 27.21453666687012 C 6.09217643737793 27.21453666687012 -1.9073486328125e-06 21.12234687805176 -1.9073486328125e-06 13.60726737976074 C -1.9073486328125e-06 6.09217643737793 6.09217643737793 -1.9073486328125e-06 13.60726737976074 -1.9073486328125e-06 Z"
              stroke="none"
              fill="#e6e6e6"
            />
          </g>
        </g>
        <g
          id="Group_64"
          data-name="Group 64"
          transform="translate(-0.622 -1.364)"
        >
          <path
            id="Path_3557"
            data-name="Path 3557"
            d="M11.622,8.471v7"
            transform="translate(0 3)"
            fill="none"
            stroke="#7e59f2"
            stroke-linecap="round"
            stroke-width="3"
          />
          <path
            id="Path_3558"
            data-name="Path 3558"
            d="M11.622,8.471v7"
            transform="translate(5.216 3)"
            fill="none"
            stroke="#7e59f2"
            stroke-linecap="round"
            stroke-width="3"
          />
        </g>
      </g>
    </svg>
  );
};
