import { CallListMapItem } from "../../../interfaces/contactlist";
import { Cell, ColumnName, Row, Table } from "../../../shared/table";
import { PreviewCallListResponse } from "../map";

export const PreviewFileComponent = (props: { callListMap: CallListMapItem[]; records: PreviewCallListResponse }) => {
  return (
    <div className="w-full sm:h-[250px]">
      <Table>
        <thead>
          <Row>
            {props.callListMap
              ? props.callListMap.map((item, i) => {
                  return <ColumnName key={i}>{item.accountField.value}</ColumnName>;
                })
              : null}
          </Row>
        </thead>
        <tbody>
          {Object.values(props.records.rows).map((row, i) => {
            return (
              <Row key={i}>
                {props.callListMap.map((callListField, j) => {
                  if (callListField.selectedCallListField) {
                    return (
                      <Cell key={j}>
                        {callListField.selectedCallListField.map((field, k) => {
                          if (field.fieldColumn !== null && field.fieldColumn !== undefined) {
                            return (
                              <span key={k} className="block my-1">
                                {" "}
                                {row[field.fieldColumn]}
                              </span>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Cell>
                    );
                  } else {
                    return <Cell key={j}> -- </Cell>;
                  }
                })}
              </Row>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
