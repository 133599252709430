export const green = "#65DE99";
export const orange = "#FF7474";
export const grey = "#A1A0AE";
export const purple = "#7E59F2";

export const getPercentage = (value: number, totalValue: number, returnedValue: number = 0) => {
  let calc = ((value / totalValue) * 100).toFixed(1);
  if (!Number(calc)) calc = returnedValue.toString();
  return calc + "%";
};

export const renderPlainLegendText = (value: string, entry: any) => {
  return <span style={{ color: "#43425D", fontSize: "16px" }}>{value}</span>;
};

export function campaignColor(campaignStatusName: string) {
  let color = grey;
  switch (campaignStatusName) {
    case "Completed":
      color = green;
      break;
    case "Stopping":
    case "Stopped":
      color = orange;
      break;
    case "Starting":
    case "Running":
      color = purple;
      break;
  }

  return color;
}
