export enum TABLEDATATYPE {
  String = 1,
  Date = 2,
  Weekdays = 3,
  Time = 4,
}
export type TableContentType = {
  label: string;
  format: TABLEDATATYPE;
};

export type TableRow = {
  content: TableContentType[];
};

export interface TableStyleSettings {
  height: String;
  width: String;
  marginLeft: String;
  marginRight: String;
  top: String;
}

export const transformValue = (value: string | any, format: TABLEDATATYPE) => {
  switch (format) {
    case TABLEDATATYPE.String:
      return value;
    case TABLEDATATYPE.Date:
      return getDate(value.toString());
    case TABLEDATATYPE.Weekdays:
      return getWeekDay(value.toString());
    case TABLEDATATYPE.Time:
      return getTime(value.toString());
    default:
      return value;
  }
};

export const getTime = (data: string) => {
  if (data) {
    const separator = " ";
    const splittedDate = new Date(data).toLocaleString([], { hour12: true }).split(separator);
    return `${splittedDate[1]}${separator}${splittedDate[2]}`;
  }
};
export const getDate = (data: string) => {
  if (data) {
    const separator = "-";
    const splittDateAndTime = data.split("T");
    const splittedDate = splittDateAndTime[0].split(separator);
    const year = splittedDate[0];
    const month = splittedDate[1];
    const day = splittedDate[2];

    return `${year}${separator}${day}${separator}${month}`;
  }
};

export const getWeekDay = (data: string) => {
  const weekDaysArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  let returnValue = "";

  if (data.length === 7) {
    const mappedData = (data + "").split("").map((i) => Number(i));
    mappedData.forEach((n, index) => {
      if (+n === 1) {
        returnValue = returnValue + weekDaysArray[index] + ", ";
      }
    });
  }
  return returnValue.slice(0, -2);
};
