import React from "react";
import * as Asserts from "../utils/Asserts";
import { deleteMessage, fetchMessages, saveMessage } from "../api/textmessages";
import { TextMessage } from "../interfaces/textmessages";
import messagePigeon from "../images/message_pigeon_img.png";
import { formatDate } from "../utils/DateUtils";
import ConfirmButton from "../shared/buttons/confirm";
import { SelectInput, TextAreaInput, TextInput } from "../shared/input";
import CancelButton from "../shared/buttons/cancel";
import { EntityTable, stringToCell } from "../shared/entitytable";
import { useErrorHandling } from "../apiError";
import { ErrorCause } from "../errorboundaries";

type TextMessageAction = "Edit" | "Delete";

export const TextMessages = (props: { clientId: number }) => {
  const triggerError = useErrorHandling();

  //====================
  // Hooks
  //====================

  let textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  // Api
  const [messages, setMessages] = React.useState<TextMessage[]>([]);

  // UI State
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = React.useState<TextMessage | null>(null);
  const [hasAccountField, setHasAccountField] = React.useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(true);

  // Inserted Values
  const [messageTitle, setMessageTitle] = React.useState<string>("");
  const [messageText, setMessageText] = React.useState<string>("");
  const [accountFieldSelected, setAccountFieldSelected] = React.useState<string>("");
  const [invalidFields, setInvalidFields] = React.useState<string[]>([]);

  const loadMessages = React.useCallback(async () => {
    const messages = await fetchMessages(props.clientId);
    setMessages(messages);
    setShowLoadingSpinner(false);
  }, [props.clientId]);

  React.useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  //====================
  // API Functions
  //====================

  const saveTextMessage = async () => {
    let messageId = 0;
    let messageCampaigns = "";

    if (selectedMessage) {
      messageId = selectedMessage.id;
      messageCampaigns = selectedMessage.campaigns;
    }

    const messageObj: TextMessage = {
      clientId: props.clientId,
      id: messageId,
      message: messageText,
      name: messageTitle,
      campaigns: messageCampaigns,
      created: "",
      updated: "",
    };

    setInvalidFields([]);
    const dt = await saveMessage(props.clientId, messageObj);

    if (dt) {
      if (!dt.err) {
        setShowForm(false);
        loadMessages();
        setInvalidFields([]);
      } else {
        if (+dt.err.errorCause === ErrorCause.NONE) {
          setInvalidFields([dt.err.errorInfo.toString().split(":")[1]]);
        } else {
          triggerError(dt.err);
        }
      }
    }
  };

  //===============
  // Event handlers
  //===============

  const handleMessageTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessageTitle(e.target.value);
  };

  const handleMessageTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const tagRegex = /(\[\])|(\[.*?\])|(\[.*?\s)|(\[.*?\s)|\[\w*|\w*\]|\[\B!|!\b\w*/gm;
    const taggedAccFields = e.target.value.match(tagRegex);

    setMessageText(e.target.value);

    if (taggedAccFields && taggedAccFields.length) {
      setHasAccountField(true);
      //TODO: Uncomment it when front-end need to check for errors checkForErrors(taggedAccFields);
    } else if (!taggedAccFields || !e.target.value) {
      setInvalidFields([]);
      setHasAccountField(false);
    }
  };

  const handleAccountFieldSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const accountFieldSelected = e.target.value;

    if (accountFieldSelected !== "0" && textAreaRef.current) {
      setAccountFieldSelected(accountFieldSelected);
      setFieldOnPosition(textAreaRef.current, accountFieldSelected);
      setHasAccountField(true);
    }

    setAccountFieldSelected("0");
  };

  /* 
 // --- This function is the frontend side of the errors checking. If there is a wrong tag, it will be responsible to find it ---//
    const checkForErrors = (taggedAccFields: string[]) => {
    const tagRegexIncorrect = /(\[((?!first_name(?=\]))(?!last_name(?=\]))(?!file_number(?=\]))).*?\])/gm;

    let arrayTags: string[] = [];
    taggedAccFields.map((field) => {
      if (field.match(tagRegexIncorrect)) {
        arrayTags.push(field);
      }
    });
    setInvalidFields(arrayTags);
  };

  */
  const setFieldOnPosition = (currentTextArea: HTMLTextAreaElement, accField: string) => {
    if (currentTextArea) {
      const previousMessageText = currentTextArea.value;
      const messageSize = currentTextArea.value.length;
      const startPosition = currentTextArea.selectionStart;
      const endPosition = currentTextArea.selectionEnd;

      if (startPosition === endPosition) {
        if ((!startPosition && !endPosition) || endPosition === messageSize) {
          //#1 USECASE - Inserting at the end
          const messageAccFieldEnd = previousMessageText ? previousMessageText.trim() + " " + accField : accField;

          setMessageText(messageAccFieldEnd);
        } else {
          // #2 USECASE - Inserting in the middle of the text
          const messageAccFieldMiddle = previousMessageText
            ? previousMessageText.slice(0, startPosition).trim() +
              " " +
              accField +
              " " +
              previousMessageText.slice(startPosition, messageSize).trim()
            : "";
          setMessageText(messageAccFieldMiddle);
        }
      } else {
        // #3 USECASE - SWITCHING ENTIRE WORLD/SENTENCE FOR A ACC FIELD
        const messageAccFieldSwitch = previousMessageText
          ? previousMessageText.slice(0, startPosition).trim() +
            " " +
            accField +
            " " +
            previousMessageText.slice(endPosition, messageSize).trim()
          : "";

        setMessageText(messageAccFieldSwitch);
      }
    }
  };

  //===============
  // Edition Functions
  //===============

  const editTextMessage = (textMessage: TextMessage) => {
    setShowForm(true);
    setSelectedMessage(textMessage);
    setMessageText(textMessage.message);
    setMessageTitle(textMessage.name);
  };

  const deleteTextMessage = async (textMessage: TextMessage) => {
    await deleteMessage(props.clientId, textMessage);

    loadMessages();
  };

  const inserTextMessage = async () => {
    setMessageText("");
    setMessageTitle("");
    setShowForm(true);
  };

  const closeForm = () => {
    setSelectedMessage(null);
    setMessageTitle("");
    setMessageText("");
    setShowForm(false);
  };

  //===============
  // UI Elements
  //===============

  const selectTemplateOptions = [
    { value: "0", text: "Insert account field" },
    { value: "[first_name]", text: "First Name" },
    { value: "[last_name]", text: "Last Name" },
    { value: "[file_number]", text: "File Number" },
  ];
  const maxSize = Object.values(selectTemplateOptions).sort((a, b) => b.value.length - a.value.length)[0].value.length;

  const editForm = () => {
    return (
      <div className=" h-full bg-white rounded-lg shadow-window">
        <div className="font-bold pl-6 py-2 bg-secondaryOffWhite border rounded-tl-lg rounded-tr-lg border-spaceGray  text-left text-lg h-12">
          Configuration
        </div>
        <div className="bg-white px-7 py-5">
          <div>
            <TextInput value={messageTitle} onChange={handleMessageTitleChange} title="Message Name" required={true} />
          </div>
          <div className="w-1/4">
            <SelectInput
              title="Account Field"
              onChange={handleAccountFieldSelection}
              value={accountFieldSelected}
              options={selectTemplateOptions.map((o) => {
                return { value: o.value, text: o.text };
              })}
              disabled={messageText.length + maxSize > 500 ? true : false}
            ></SelectInput>
          </div>
          <div>
            <TextAreaInput
              componentRef={textAreaRef}
              value={messageText}
              onChange={handleMessageTextChange}
              title="Message"
              required={true}
              maxLength={500}
            />
            <span className="py-2 block"> {`${messageText.length}/500`}</span>
            {hasAccountField ? (
              <span className="py-2 block italic"> Approximate count for messages containing an account field </span>
            ) : null}
            {!!invalidFields && !!invalidFields.length ? (
              <span className="py-2 block italic text-redAlert w-96 overflow-hidden whitespace-nowrap text-ellipsis">
                Invalid account fields{" "}
                {invalidFields.map((field, i) => (i === invalidFields.length - 1 ? field : `${field}, `))}
              </span>
            ) : null}
          </div>
          <div className="flex mt-20">
            <div className="w-40 mr-5">
              <ConfirmButton
                disabled={!messageText || !messageTitle || !!invalidFields.length}
                onClick={() => {
                  saveTextMessage();
                }}
              >
                Save
              </ConfirmButton>
            </div>
            <div className="w-40">
              <CancelButton onClick={closeForm}>Cancel</CancelButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function handleRowAction(action: TextMessageAction, rowNum: number) {
    switch (action) {
      case "Edit":
        {
          const m = messages[rowNum];
          if (m) {
            editTextMessage(m);
          }
        }
        break;

      case "Delete":
        {
          const m = messages[rowNum];
          if (m) {
            deleteTextMessage(m);
          }
        }
        break;

      default:
        Asserts.assertNever(action);
    }
  }
  return (
    <div className="min-h-0 h-full flex flex-col py-5 px-24 bg-white">
      <div className="flex flex-col p-5">
        <div className="flex flew-row justify-end">
          <div className="w-full pb-7 font-bold text-3xl text-primaryDarkGrey">Text Messages</div>
          <div className="flex justify-end w-40 h-10 whitespace-nowrap">
            {!showForm ? <ConfirmButton onClick={() => inserTextMessage()}>Add New</ConfirmButton> : null}
          </div>
        </div>

        <div className="flex gap-5">
          <div className="flex-1 overflow-x-visible">
            <div className="sm:max-h-[450px] xl:max-h-[680px] h-[420px]">
              <EntityTable<TextMessage, TextMessageAction>
                title="Text Messages"
                sortableColumns={["name", "updated", "campaigns"]}
                columns={["Name", "Updated", "Campaigns"]}
                entities={messages}
                rowFn={(f) => stringToCell([f.name, formatDate(new Date(f.created)), f.campaigns])}
                actionFn={() => ["Edit", "Delete"]}
                actionHandler={handleRowAction}
                loading={showLoadingSpinner}
              />
            </div>
          </div>
          <div className="flex-1">
            {showForm ? (
              editForm()
            ) : (
              <img alt="message_pigeon_image" style={{ width: "98%" }} className="rounded-lg" src={messagePigeon} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
