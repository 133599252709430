import React from "react";
import * as Asserts from "../../utils/Asserts";
import { fetchCampaigns, fetchTextingNumbers, saveCampaign } from "../../api/textingcampaign";
import { fetchMessages } from "../../api/textmessages";
import { TextingCampaign, TextingNumber } from "../../interfaces/textingcampaigns";
import { TextMessage } from "../../interfaces/textmessages";
import CancelButton from "../../shared/buttons/cancel";
import ConfirmButton from "../../shared/buttons/confirm";
import { Window } from "../../shared/window";
import { NumberInput, SelectInput, TextInput } from "../../shared/input";
import { EntityTable, stringToCell } from "../../shared/entitytable";
import logoImageLocation from "../../images/arbeit-logo.svg";
import birdsImageLocation from "../../images/3birds.png";
import clockImageLocation from "../../images/clock_illustration.svg";

type TextingCampaignAction = "Edit";

type CampaignEditorState = {
  mode: "create" | "edit" | "inactive";
  name: string;
  campaignId: number;
  chosenNumberIndex: number;
  originalSendersNumberId: number;
  chosenMessageIndex: number;
  rate: number;
};

type State = {
  messages: TextMessage[];
  numbers: TextingNumber[];
  editor: CampaignEditorState;
};

type DisplayData = {
  id: string;
  name: string;
  status: string;
  updated: string;
};

const defaultEditorState: CampaignEditorState = {
  mode: "inactive",
  name: "",
  chosenNumberIndex: -1,
  originalSendersNumberId: 0,
  chosenMessageIndex: -1,
  rate: 0,
  campaignId: 0,
};

const defaultState = {
  numbers: [],
  messages: [],
  editor: defaultEditorState,
};

export const TextingCampaignsPage = (props: { clientId: number }) => {
  const [campaigns, setCampaigns] = React.useState<TextingCampaign[]>([]);
  const [state, setState] = React.useState<State>(defaultState);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState<boolean>(true);

  const pageTitle = "Text Campaign Configuration";
  const onlyWhitespace = /^\s*$/;
  const phoneNumber = /^1(\d{3})(\d{3})(\d{4})$/;

  const loadCampaigns = React.useCallback(async () => {
    const campaigns = await fetchCampaigns(props.clientId);
    setCampaigns(campaigns);
  }, [props.clientId]);

  const loadCampaignData = React.useCallback(async () => {
    const messages = await fetchMessages(props.clientId);
    const numbers = await fetchTextingNumbers(props.clientId);
    setState((prevState) => {
      return { ...prevState, editor: { ...prevState.editor, mode: "inactive" }, numbers: numbers, messages: messages };
    });
    setShowLoadingSpinner(false);
  }, [props.clientId]);

  const loadInitialData = React.useCallback(async () => {
    await loadCampaigns();
    await loadCampaignData();
  }, [loadCampaigns, loadCampaignData]);

  React.useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  // =========================
  // Row delete/edit functions
  // =========================
  const editRow = (row: number) => {
    let editing: TextingCampaign = campaigns[row];

    const msgIdx = state.messages.findIndex((msg) => msg.id === editing.messageTextId);
    const numIdx = state.numbers.findIndex((number) => number.textingPhoneNumberId === editing.sendersNumberId);

    setState({
      ...state,
      editor: {
        ...state.editor,
        campaignId: editing.campaignId,
        mode: "edit",
        name: editing.name,
        rate: editing.textPerMinute,
        chosenMessageIndex: msgIdx,
        chosenNumberIndex: numIdx,
        originalSendersNumberId: editing.sendersNumberId,
      },
    });
  };

  //================
  //Editor functions
  //================
  const handleNewCampaign = () => {
    setState({ ...state, editor: { ...defaultEditorState, mode: "create" } });
  };

  const handleSaveClicked = async () => {
    await saveCampaign({
      name: state.editor.name,
      clientId: props.clientId,
      campaignId: state.editor.campaignId,
      textPerMinute: state.editor.rate,
      messageTextId: state.messages[state.editor.chosenMessageIndex].id,
      originalSendersNumberId: state.editor.originalSendersNumberId,
      sendersNumberId: state.numbers[state.editor.chosenNumberIndex].textingPhoneNumberId,
      startAutomatically: false,
      stopAutomatically: false,
      startTime: new Date(),
      stopTime: new Date(),
      startWeekdays: "00000",
      stopWeekdays: "00000",
    });
    loadCampaigns();
    loadCampaignData();
  };

  const handleCancelClicked = () => {
    setState({ ...state, editor: defaultEditorState });
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, editor: { ...state.editor, name: e.target.value } });
  };

  const handleChangeNumber = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState({
      ...state,
      editor: { ...state.editor, chosenNumberIndex: Number(e.target.value) },
    });
  };

  const handleChangeRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const minRate = 0;
    const newRate = Number(e.target.value);
    const rate = Math.max(newRate, minRate);
    setState({
      ...state,
      editor: { ...state.editor, rate: rate },
    });
  };

  const handleChangeMessage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState({
      ...state,
      editor: { ...state.editor, chosenMessageIndex: Number(e.target.value) },
    });
  };

  //=================================
  //Data display/validation functions
  //=================================
  const formatDate = (date: Date): string => {
    return date.toLocaleDateString("en-US");
  };

  const displayData: DisplayData[] = campaigns.map((c) => {
    return {
      id: c.campaignId.toString(),
      name: c.name,
      status: c.campaignStatusName,
      updated: formatDate(new Date(c.updated)),
    };
  });

  let names = campaigns.map((c) => c.name);
  if (state.editor.mode === "edit") {
    const existingCampaign = campaigns.find((c) => c.campaignId === state.editor.campaignId);

    if (existingCampaign === undefined) {
      throw Error("Attempting to edit a campaign without any associated data!");
    }

    names = names.filter((n) => n !== existingCampaign.name);
  }
  const nameIsUnique =
    names.findIndex((name) => {
      return state.editor.name === name;
    }) === -1;

  const validName = nameIsUnique && state.editor.name.length > 0 && state.editor.name.match(onlyWhitespace) === null;

  let messageOptions = state.messages.map((msg, idx) => {
    return { value: idx, text: msg.name };
  });

  let numberOptions = state.numbers.map((number, idx) => {
    let matches = phoneNumber.exec(number.phoneNumber);
    if (matches !== null) {
      const formattedNumber = `${matches[1]}-${matches[2]}-${matches[3]}`;
      return { value: idx, text: formattedNumber };
    } else {
      throw Error("Invalid phone number in use on a texting campaign!");
    }
  });

  const saveEnabled = validName && state.editor.chosenNumberIndex !== -1 && state.editor.chosenMessageIndex !== -1;

  const buttons =
    state.editor.mode === "inactive" ? (
      <div className="flex">
        <ConfirmButton onClick={handleNewCampaign}>New Campaign</ConfirmButton>
      </div>
    ) : (
      <div className="flex-1 flex gap-5 justify-end">
        <div className="w-1/4">
          <CancelButton onClick={handleCancelClicked}>Cancel</CancelButton>
        </div>
        <div className="w-1/4">
          <ConfirmButton disabled={!saveEnabled} onClick={handleSaveClicked}>
            Save
          </ConfirmButton>
        </div>
      </div>
    );

  function handleRowAction(action: TextingCampaignAction, rowNum: number) {
    switch (action) {
      case "Edit":
        editRow(rowNum);
        break;

      default:
        Asserts.assertNever(action);
    }
  }

  return (
    <div className="h-full bg-white flex flex-col pt-5 py-24 px-24">
      <div className="flex mb-8 border-b-0">
        <label className="flex-[2] text-3xl text-primaryDarkGrey font-bold">{pageTitle}</label>
        {buttons}
      </div>
      <div className="w-full h-full grid grid-rows-1 grid-cols-2 gap-5">
        <div className="sm:max-h-[350px] xl:max-h-[680px]">
          <EntityTable<DisplayData, TextingCampaignAction>
            sortableColumns={["name", "status", "updated"]}
            title="Text Campaigns List"
            columns={["Name", "Status", "Updated"]}
            entities={displayData}
            rowFn={(c: DisplayData) => stringToCell([c.name, c.status, c.updated])}
            actionFn={(c: DisplayData) => ["Edit"]}
            actionHandler={handleRowAction}
            loading={showLoadingSpinner}
          />
        </div>

        {state.editor.mode === "inactive" ? (
          <div className="w-full h-full relative flex items-center justify-center bg-primaryPurple rounded-lg overflow-hidden">
            <div className="w-full h-full" style={{ backgroundImage: `url(${birdsImageLocation})` }} />
            <div className="absolute flex bottom-0 right-0 text-white font-bold mb-8 mr-11">
              <img alt="" src={logoImageLocation} />
              <div className="flex flex-col justify-center">
                <p>ALL NEW</p>
                <p>TEXTING CAMPAIGNS</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-rows-2 gap-5">
            <div className="grid grid-cols-2 gap-5">
              <Window title="Campaign Details">
                <div className="flex flex-col gap-5 content-evenly">
                  <TextInput title="Campaign Name" value={state.editor.name} onChange={handleChangeName} />
                  <SelectInput
                    title="Sender Number"
                    value={state.editor.chosenNumberIndex}
                    onChange={handleChangeNumber}
                    options={numberOptions}
                  />
                  <NumberInput
                    title="Texts per minute"
                    value={String(state.editor.rate)}
                    onChange={handleChangeRate}
                  ></NumberInput>
                </div>
              </Window>
              <Window title="Auto Start & Stop">
                <div className="flex items-center justify-center">
                  <img alt="" src={clockImageLocation} />
                </div>
              </Window>
            </div>
            <Window title="Message Selection & Preview" padContent={false}>
              <div className="h-full flex flex-col rounded-lg">
                <div className="p-5">
                  <SelectInput
                    title="Message Used"
                    value={state.editor.chosenMessageIndex}
                    onChange={handleChangeMessage}
                    options={messageOptions}
                  />
                </div>
                <div className="flex p-5 h-full flex-1 items-center justify-center bg-lightGray text-primaryDarkGrey text-xl">
                  <p>
                    {state.messages.length > 0 &&
                      state.editor.chosenMessageIndex >= 0 &&
                      state.messages[state.editor.chosenMessageIndex].message}
                  </p>
                </div>
              </div>
            </Window>
          </div>
        )}
      </div>
    </div>
  );
};
