import * as React from "react";

export interface WindowProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  header?: JSX.Element;
  padContent?: boolean;
}

export const Window: React.FC<WindowProps> = ({ title, header, padContent = true, ...rest }: WindowProps) => {
  let headerElement = (
    <div className="py-3 px-5 bg-secondaryOffWhite border-b border-spaceGray text-primaryDarkGrey font-bold">
      {title}
    </div>
  );

  if (header !== undefined) {
    headerElement = (
      <div className="bg-secondaryOffWhite border-b border-spaceGray text-primaryDarkGrey font-bold">{header}</div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full bg-white border border-spaceGray rounded-lg shadow-window">
      {headerElement}
      <div className={padContent ? "p-5" : "p-0 h-full overflow-auto overflow-x-hidden"}>{rest.children}</div>
    </div>
  );
};
