import { Config } from "../config";
import { pick } from "../utils/Conversion";
import { phoneFormatted } from "../utils/PhoneUtils";

let user = JSON.parse(localStorage.getItem("user")!);
let params = new URLSearchParams();

export enum DoNotCallType {
  PhoneNumber = 1,
  State = 2,
  AreaCode = 3
}

export class DoNotCall {
  id: number;
  clientId: number;
  phoneRestriction: string;
  stateRestriction: number;
  areaCodeRestriction: number;
  doNotCallTypeId: DoNotCallType;

  constructor() {
    this.id = -1;
    this.clientId = -1;
    this.phoneRestriction = "";
    this.stateRestriction = 0;
    this.areaCodeRestriction = 0;
    this.doNotCallTypeId = DoNotCallType.PhoneNumber;
  }
}

export enum ModalDoNotCallType {
  ConfirmInserted = "1",
  ConfirmDeleteAction = "2",
  ConfirmDeleted = "3",
}

export const postNewPhoneNumber = async (clientId: number, phoneNumber: string) => {
  const doNotCallObject: DoNotCall = {
    clientId,
    id: -1,
    doNotCallTypeId: DoNotCallType.PhoneNumber,
    phoneRestriction: phoneFormatted(phoneNumber, true),
    stateRestriction: 0,
    areaCodeRestriction: 0,
  };
  let url = new URL(`${Config.platformwebapi_url}/api/donotcall/${clientId}/edit`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(doNotCallObject),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

export const postNewState = async (clientId: number, state: number) => {
  const doNotCallObject: DoNotCall = {
    clientId,
    id: -1,
    doNotCallTypeId: DoNotCallType.State,
    phoneRestriction: "",
    stateRestriction: state,
    areaCodeRestriction: 0,
  };
  let url = new URL(`${Config.platformwebapi_url}/api/donotcall/${clientId}/edit`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(doNotCallObject),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

export const postNewAreaCode = async (clientId: number, areaCode: number) => {
  const doNotCallObject: DoNotCall = {
    clientId,
    id: -1,
    doNotCallTypeId: DoNotCallType.AreaCode,
    phoneRestriction: "",
    stateRestriction: 0,
    areaCodeRestriction: areaCode,
  };
  let url = new URL(`${Config.platformwebapi_url}/api/donotcall/${clientId}/edit`);

  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(doNotCallObject),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });

  return response.ok;
};

export const deletePhoneNumber = async (phoneObject: DoNotCall, listRestrictions: DoNotCall[]) => {
  let url = new URL(`${Config.platformwebapi_url}/api/donotcall/${phoneObject.clientId}/delete`);
  const response = await fetch(url.toString(), {
    method: "POST",
    body: JSON.stringify(phoneObject),
    headers: {
      Authorization: "Bearer " + user.bearerToken,
      "Content-Type": "application/json",
    },
  });
  return response.ok;
  
};

export const fetchDoNotCallRestrictions = async (clientId: number) => {
  let url = new URL(`${Config.platformwebapi_url}/api/donotcall`);

  params.append("clientId", String(clientId));
  url.search = params.toString();

  const response = await fetch(url.toString(), {
    headers: { Authorization: "Bearer " + user.bearerToken },
  });

  const responseJson = await response.json();
  let listRestrictions: DoNotCall[] = [];
  for (let v of responseJson) {
    const phoneInfo = pick<DoNotCall>(v, {
      id: Number,
      clientId: Number,
      phoneRestriction: String,
      doNotCallTypeId: Number,
      stateRestriction: Number,
      areaCodeRestriction: Number,
    });

    listRestrictions.push(phoneInfo);
  }
  return listRestrictions;
};

export const postDoNotCallList = async (clientId: number, data: FormData) => {
  const url = new URL(`${Config.platformwebapi_url}/api/donotcall/upload`);
  let result;
  let params = new URLSearchParams();
  params.append("clientId", String(clientId));

  url.search = params.toString();

  await fetch(url.toString(), {
    method: "POST",
    body: data,
    headers: {
      Authorization: "Bearer " + user.bearerToken,
    },
  })
    .then((resp) => resp.json())
    .then((res) => (result = res));
  return result;
};


export async function downloadCSVList(clientId: number) {
  const url = new URL(`${Config.platformwebapi_url}/api/donotcall/${clientId}/export`);

  const response = await fetch(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.bearerToken,
    }
  });

  if (response.ok) {
    //TODO: This is really a hideous solution. Isn't there a better way to do this, or should the backend be changed somehow to avoid this entirely?
    const wrappedBlob = new Blob([await response.blob()], { type: "text/csv" });
    const blobURL = window.URL.createObjectURL(wrappedBlob);

    let link = document.createElement("a");
    link.href = blobURL;
    link.download = 'DoNotCallList.csv';
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(blobURL);
    }, 250);
  }
}